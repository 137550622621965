/** Экспорт в эксель запроса для поставщика **/
import {Button, Header, Modal, Segment} from "semantic-ui-react";
import React, {useState} from "react";
import Excel from "exceljs";
import * as FileSaver from  'file-saver'

const CELL_COLOR = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFC0CB' }
}

const CELL_BORDER = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' }
}

const CELL_STYLE = {
    border: CELL_BORDER,
    // fill: CELL_COLOR
}

export const ExcelFromSuppliers = ({docId, data}) => {
    const [open, setOpen] = useState(false)
    const close = () => setOpen(false)

    const exportFile = async () => await ExportExcelFile(docId, data)

    return <Modal open={open} trigger={<Button content="Экспорт для поставщика" onClick={() => setOpen(true)}/>}
        closeOnDimmerClick={false} onClose={close}>
        <Modal.Header><Header as="h4">Выгрузка запроса для поставщика</Header></Modal.Header>
        <Modal.Content scrolling>
            <Segment padded textAlign={'center'}>
                <Button content='Выгрузить в Excel' onClick={exportFile} />
            </Segment>
        </Modal.Content>
        <Modal.Actions>
            <Button negative onClick={close}>
                Закрыть
            </Button>
        </Modal.Actions>
    </Modal>
}

const ExportExcelFile = async (docId, list) => {
    const workbook = new Excel.Workbook()
    const columns = [
        { header: '№', key: 'rowId', width: null, hidden: true },
        { header: 'Номер строки запроса предложения', key: 'requestId', width: 4, hidden: true },
        { header: 'Производитель', key: 'brand', width: null, hidden: false },
        { header: 'Пустая колонка1', key: 'empty1', width: null, hidden: true },
        { header: 'Номер запчасти', key: 'article', width: null, hidden: false },
        { header: 'Пустая колонка2', key: 'empty2', width: null, hidden: true },
        { header: 'Наименование', key: 'description', width: null, hidden: false },
        { header: 'Количество', key: 'quant', width: null, hidden: true },
        { header: 'Единица измерения', key: 'unit', width: 4, hidden: true },
        { header: 'Цена/ед.', key: 'price2', width: null, hidden: false },
        { header: 'Пустая колонка3', key: 'empty3', width: null, hidden: true },
        { header: 'Пустая колонка4', key: 'empty4', width: null, hidden: true },
        { header: 'Склад', key: 'stock', width: 11, hidden: true },
        { header: 'Вес', key: 'weight', width: null, hidden: false },
        { header: 'Пустая колонка5', key: 'empty5', width: null, hidden: true },
        { header: 'Пустая колонка6', key: 'empty6', width: null, hidden: true },
        { header: 'Действительно до', key: 'validity', width: null, hidden: true }
    ]

    const cells = ({ rowId, requestId, brand, article, description, weight }) => ({
        rowId,
        requestId,
        brand,
        empty1: '',
        article,
        empty2: '',
        description,
        quant: 1,
        unit: 'шт.',
        price2: '',
        empty3: '',
        empty4: '',
        stock: '',
        weight,
        empty5: '',
        empty6: '',
        validity: ''
    })


        // creating one worksheet in workbook
        const worksheet = workbook.addWorksheet(docId)

        worksheet.columns = columns

        // updated the font for first row.
        worksheet.getRow(1).font = { bold: true };

        worksheet.columns.map(column => {
            column.width = column.width || column.header.length + 2
        })

        // loop through data and add each one to worksheet
        let rowIndex = 0

        list.map((el) => worksheet.addRow(cells({ ...el})))

        worksheet.eachRow({ includeEmpty: false }, ({_cells}) =>
            _cells.map(({_address}) => Object.assign(worksheet.getCell(_address), CELL_STYLE)))

        const buffer = await workbook.xlsx.writeBuffer()
        workbook.removeWorksheet(docId)
        FileSaver.saveAs(new Blob([buffer]), `RT_${docId}.xlsx`)

}
