import React from 'react'
import {Link, NavLink} from 'react-router-dom'
import {Icon, Label, Popup} from 'semantic-ui-react'
import AppRoutes from 'routes/app.routes'
export const Layout = () => {
    return <div className="grid-container">
        <header className="header__panel">
                <div className="header__search"></div>
                <div className="header__avatar">
                    <NavLink to="/logout">
                        <Label as='a' content='Выход' icon='user' size='large' />
                    </NavLink>
                </div>
            </header>

            <aside className="sidenav">
                <div className="sidenav__brand">
                    <a className="sidenav__brand-link" href="#">Admin</a>
                </div>
                <ul className="sidenav__list">
                    <Popup
                        trigger={<Link to="/clients/"><li className="sidenav__list-item"><Icon name='user' size='large' /></li></Link>}
                        content='Запросы и Заказы клиентов'
                        position='left center'
                        inverted
                    />
                    <Popup
                        trigger={<Link to="/management/"><li className="sidenav__list-item"><Icon name='indent' color='green' size='large' inverted/></li></Link>}
                        content='Обработка запросов'
                        position='left center'
                        inverted
                    />
                    <Popup
                        trigger={<Link to="/answers/"><li className="sidenav__list-item"><Icon name='retweet' size='large' /></li></Link>}
                        content='Ответы'
                        position='left center'
                        inverted
                    />
                    <Popup
                        trigger={<Link to="/catalog/"><li className="sidenav__list-item"><Icon name='database' size='large' /></li></Link>}
                        content='DBParts'
                        position='left center'
                        inverted
                    />
                    <Popup
                        trigger={<Link to="/payments/"><li className="sidenav__list-item"><Icon name='file alternate' size='large' /></li></Link>}
                        content='Счета'
                        position='left center'
                        inverted
                    />
                    <Popup
                        trigger={<Link to="/logistic/"><li className="sidenav__list-item"><Icon name='shipping' size='large' /></li></Link>}
                        content='КЖС'
                        position='left center'
                        inverted
                    />
                    <Popup
                        trigger={<Link to="/invoice/"><li className="sidenav__list-item"><Icon name='cubes' size='large' color={'olive'}/></li></Link>}
                        content='Заказы поставщику'
                        position='left center'
                        inverted
                    />
{/*                    <NavLink to="/clients/"><li className="sidenav__list-item">Запросы / Заказы клиентов</li></NavLink>
                                        <NavLink to="/requests/"><li className="sidenav__list-item">Запросы клиентов</li></NavLink>
                    <NavLink to="/management/"><li className="sidenav__list-item">Запросы поставщику</li></NavLink>
                    <NavLink to="/answers/"><li className="sidenav__list-item">Ответы</li></NavLink>
                    <NavLink to="/orders/clients"><li className="sidenav__list-item">Заказы клиентов</li></NavLink>
                    <NavLink to="/orders/supplier"><li className="sidenav__list-item">Заказы поставщику</li></NavLink>
                    <NavLink to="/"><li className="sidenav__list-item">Создать запрос</li></NavLink>
                    <NavLink to="/catalog/"><li className="sidenav__list-item">DBParts</li></NavLink>
                    <NavLink to="/payments/"><li className="sidenav__list-item">Счета</li></NavLink>*/}
                </ul>
            </aside>
            <main className="main">
                <AppRoutes />
{/*                <div className="main-header">
                    <div className="main-header__heading">Hello User</div>
                    <div className="main-header__updates">Recent Items</div>
                </div>

                <div className="main-overview">
                    <div className="overviewcard">
                        <div className="overviewcard__icon">Overview</div>
                        <div className="overviewcard__info">Card</div>
                    </div>
                    <div className="overviewcard">
                        <div className="overviewcard__icon">Overview</div>
                        <div className="overviewcard__info">Card</div>
                    </div>
                    <div className="overviewcard">
                        <div className="overviewcard__icon">Overview</div>
                        <div className="overviewcard__info">Card</div>
                    </div>
                    <div className="overviewcard">
                        <div className="overviewcard__icon">Overview</div>
                        <div className="overviewcard__info">Card</div>
                    </div>
                </div>

                <div className="main-cards">
                    <div className="card">Card</div>
                    <div className="card">Card</div>
                    <div className="card">Card</div>
                </div>*/}
            </main>

            <footer className="footer">
                <div className="footer__copyright">{''}</div>
                <div className="footer__signature">{''}</div>
            </footer>
        </div>
}