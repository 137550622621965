import {useDispatch, useSelector} from "react-redux"
import {
    Button, Checkbox, Grid, Icon, Input, Label, Modal, Popup, Segment,
    Tab, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TabPane
} from "semantic-ui-react"
import { tenderActions } from "../../../_store";
import React, {useState} from "react";
import {useMutation, useQuery} from "react-query";
import {Api} from "../../../_helpers";
import dayjs from "dayjs";
import noticeError from "../../../hooks/useError";

const DIALOG_STYLE = { width: '85vw', minWidth: 1200 }
const DIALOG_STYLE_CONTENT = { minHeight: '70vh' }
const TABS_STYLE_NOT_BORDER = { border: 'none', marginTop: 5 }

const SUPPLIER_LIST = ['TVHA', 'TREX', 'CINA', 'FAB']

export const TenderComponent = () => {

    const [data, setData] = useState([])
    const [tabIndex, setTabIndex] = useState(0)

    const [modalCreateOrder, setModalCreateOrder] = useState(false)

    const dispatch = useDispatch()
    const open  = useSelector(({ tender }) => tender.open)
    const items = useSelector(({ tender }) => tender.items) || []

    const close = () => dispatch(tenderActions.open(false))

    const handlerTabChange = (_, { activeIndex }) => setTabIndex(activeIndex)
    const handlerCreate = ({order}) => {
        create({
            order,
            supplier: SUPPLIER_LIST.at(tabIndex - 1),
            data: data.filter(({suppliers}) =>
                suppliers.filter(({ pkey, winner }) => pkey === SUPPLIER_LIST.at(tabIndex - 1) && Boolean(winner)).length > 0
        )})
    }
    const update = ({checked}, { id, pkey }) => setData(prev => prev.map(el => el.id === id ? ({
        ...el, suppliers: el.suppliers.map(sp => sp.pkey === pkey ? ({ ...sp, winner: checked }) : ({ ...sp, winner: false }))
    }) : ({ ...el})))

    const remove = (id) => {
        dispatch(tenderActions.deleted(id))
        setData(prev => prev.filter((el) => el.id !== id))
    }

    useQuery(['logistic.info'], () => Api().post('/logistic/tender', { items }), {
        enabled: open && items.length > 0,
        onSuccess: ({ data }) => setData(data)
    })

    const { mutate: create } = useMutation(data => Api().post('/logistic/tender/create', data), {
        onSuccess: () => setModalCreateOrder(false),
        onError: (err) => noticeError({ message: err.response.data.message })
    })

    return <>
        <Modal style={DIALOG_STYLE} open={open} onClose={close} trigger={<ButtonComponent />} closeOnDimmerClick={false}>
            {/*<Modal.Header>Еуые</Modal.Header>*/}
            <Modal.Content scrolling style={DIALOG_STYLE_CONTENT}>
                <Segment padded>
                    <DialogContent close={close} data={data} tabs={SUPPLIER_LIST} upd={update} remove={remove} tabIndex={tabIndex} tabChange={handlerTabChange} />
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                {tabIndex > 0 && <Button color={'blue'} onClick={() => setModalCreateOrder(true)}>Создать заказ</Button>}
                <Button negative onClick={close}>
                    Закрыть
                </Button>
            </Modal.Actions>
        </Modal>
        <ModalCreateOrder open={modalCreateOrder} close={() => setModalCreateOrder(false)} create={handlerCreate} />
    </>
}

const ButtonComponent = () => {
    const dispatch = useDispatch()
    return <Button fluid content="Тендер" onClick={() => dispatch(tenderActions.open(true))}/>
}

const DialogContent = ({data, tabs, upd, remove, tabIndex = 0, tabChange}) => {
    const panes = [{ menuItem: 'Матрица', render: () => <TabPane style={TABS_STYLE_NOT_BORDER}><TenderMatrix data={data} upd={upd} remove={remove} /></TabPane> },]
    const panels = [...panes, ...tabs.map(name => ({ menuItem: name, render: () => <TabPane style={TABS_STYLE_NOT_BORDER}><TenderSupplier data={data} filterId={name} upd={upd}/></TabPane> }))]
    return <Tab panes={panels} activeIndex={tabIndex} onTabChange={tabChange}/>
}

const TenderMatrix = ({ data: list, upd, remove }) => {
    return <Segment><Table celled>
        <TableHeader>
            <TableRow>
                <TableHeaderCell>Поставщик</TableHeaderCell>
                <TableHeaderCell>Артикул</TableHeaderCell>
                <TableHeaderCell>Наименование</TableHeaderCell>
                <TableHeaderCell textAlign="right">Цена</TableHeaderCell>
                <Table.HeaderCell width={7} textAlign='right' style={{ minWidth: '290px', padding: 0 }} >
                    <Table basic='very' fixed>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={4} textAlign="center" style={{ padding: 5 }}>Поставщик</Table.Cell>
                                <Table.Cell width={4} textAlign="right">Цена</Table.Cell>
                                <Table.Cell width={2} textAlign="right">%</Table.Cell>
                                <Table.Cell width={4} textAlign="center">Дата</Table.Cell>
                                <Table.Cell width={1} />
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Table.HeaderCell>
                <TableHeaderCell textAlign={"center"}>Статус</TableHeaderCell>
                <TableHeaderCell textAlign={"center"}></TableHeaderCell>
            </TableRow>
        </TableHeader>
        <TableBody>
            {list.map(({ id, brand, article, description, price, suppliers }, i) => <TableRow key={i}>
                <TableCell>{brand}</TableCell>
                <TableCell>{article}</TableCell>
                <TableCell>{description}</TableCell>
                <TableCell textAlign="right">{price}</TableCell>
                <Table.Cell style={{ padding: 0 }}>
                    <Table basic='very' fixed selectable compact>
                        <Table.Body>
                            {suppliers.map((el, ind) => <Table.Row key={id+ind} negative={el.winner}>
                                    <Table.Cell width={4} textAlign="center" style={{ padding: 5 }}>
                                        <Popup content={el.name || 'Нет предложений'} position='top center' trigger={<Label size={'mini'} color={el.color} content={el.pkey} /> } />
                                    </Table.Cell>
                                    <Table.Cell width={4} textAlign="right" >{el.price || '-'}</Table.Cell>
                                    <Table.Cell width={2} textAlign="right">{el.rate || '-'}</Table.Cell>
                                    <Table.Cell width={4} textAlign="center">
                                        {el.ctime ? <Popup content={el.stock || null } position='top center' trigger={
                                            <div>{dayjs(el.ctime).format('DD.MM.YYYY')}</div>
                                        } /> : '-' }
                                    </Table.Cell>
                                    <Table.Cell width={1} textAlign={'center'} style={{ padding: 5 }}>
                                        {<Checkbox onClick={(_, val) => upd(val, { id, pkey: el.pkey })} checked={el.winner} />}
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </Table.Cell>
                <TableCell textAlign={'center'}>
{/*                    <Popup content={'Товар заказан'} position='top center' trigger={
                        <Icon color='green' name='checkmark' size='large' />
                    } />*/}
                </TableCell>
                <TableCell textAlign={'center'}>
                    <Icon link name='close' color={'red'} size={'large'} onClick={() => remove(id)} />
                </TableCell>
            </TableRow>)}
        </TableBody>
    </Table></Segment>
}

const TenderSupplier = ({ data: list, filterId, upd }) => {

    const xlist = list.filter(({ suppliers }) => suppliers.filter(({pkey, winner}) => pkey === filterId && Boolean(winner)).length > 0)

    return <Segment><Table celled>
        <TableHeader>
            <TableRow>
                <TableHeaderCell width={1}>id</TableHeaderCell>
                <TableHeaderCell>Поставщик</TableHeaderCell>
                <TableHeaderCell>Артикул</TableHeaderCell>
                <TableHeaderCell>Наименование</TableHeaderCell>
                <Table.HeaderCell width={7} textAlign='right' style={{ minWidth: '290px', padding: 0 }} >
                    <Table basic='very' fixed>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={4} textAlign="center" style={{ padding: 5 }}>Поставщик</Table.Cell>
                                <Table.Cell width={4} textAlign="right">Цена</Table.Cell>
                                <Table.Cell width={2} textAlign="right">%</Table.Cell>
                                <Table.Cell width={4} textAlign="center">Дата</Table.Cell>
                                <Table.Cell width={2} />
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Table.HeaderCell>
            </TableRow>
        </TableHeader>
        <TableBody>
            {xlist.map(({ id, brand, article, description, suppliers }, i) => <TableRow key={i}>
                <TableCell>{id}</TableCell>
                <TableCell>{brand}</TableCell>
                <TableCell>{article}</TableCell>
                <TableCell>{description}</TableCell>
                <Table.Cell style={{ padding: 0 }}>
                    <Table basic='very' fixed selectable compact>
                        <Table.Body>
                            {suppliers.filter(({ pkey }) => pkey === filterId ).map((el, ind) => <Table.Row key={id+ind} negative={el.winner}>
                                    <Table.Cell width={4} textAlign="center" style={{ padding: 5 }}>
                                        <Popup content={el.name || 'Нет предложений'} position='top center' trigger={<Label size={'mini'} color={el.color} content={el.pkey} /> } />
                                    </Table.Cell>
                                    <Table.Cell width={4} textAlign="right" >{el.price || '-'}</Table.Cell>
                                    <Table.Cell width={2} textAlign="right">{el.rate || '-'}</Table.Cell>
                                    <Table.Cell width={4} textAlign="center">
                                        {el.ctime ? <Popup content={el.stock || null } position='top center' trigger={
                                            <div>{dayjs(el.ctime).format('DD.MM.YYYY')}</div>
                                        } /> : '-' }
                                    </Table.Cell>
                                    <Table.Cell width={2} textAlign={'center'} style={{ padding: 5 }}>
                                        <Checkbox onClick={(_, val) => upd(val, { id, pkey: el.pkey })} checked={el.winner}/>
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </Table.Cell>
            </TableRow>)}
        </TableBody>
    </Table></Segment>
}

const ModalCreateOrder = ({ open = true, close, create }) => {
    const [order, setOrder] = useState('')
    const isOrderValid = order.replace(/[^0-9a-zA-Z]/gi, "").length === 0

    return <Modal open={open}>
        <Modal.Content scrolling>
            <Segment padded>
                <Grid divided stackable columns={'equal'}>
                    <Grid.Row>
                        <Grid.Column>
                            <label>Номер заказа</label>
                            <Input fluid value={order} onChange={(_, { value }) => setOrder(value)} />
                        </Grid.Column>
{/*                        <Grid.Column>
                            <label>Номер заказа</label>
                        </Grid.Column>*/}
                    </Grid.Row>
                </Grid>
            </Segment>
        </Modal.Content>
        <Modal.Actions>
            <Button color={'blue'} onClick={() => create({ order })} disabled={isOrderValid}>
                Создать
            </Button>
            <Button negative onClick={() => close()}>
                Отмена
            </Button>
        </Modal.Actions>
    </Modal>
}