const STATUS = [
    { value: 0, key: 'created' },
    { value: 1, key: 'adopted' },
    { value: 2, key: 'working' },
    { value: 3, key: 'success' }
]

const STATUS_ORDER_SUPPLIER = [
    { value: 0, key: 'created' },
    { value: 1, key: 'success' },
    { value: 2, key: 'cancels' }
]

export const STATUS_PROCESS_SUCCESS = 2
const STATUS_REQUEST_PROCESS = [
    { value: 0, key: 'created' },
    { value: 1, key: 'working' },
    { value: STATUS_PROCESS_SUCCESS, key: 'success' }
]

const STATUS_LABEL = [
    { text: 'Создан', label: { color: 'red', empty: true, circular: true } },
    { text: 'Принят', label: { color: 'green', empty: true, circular: true } },
    { text: 'В обработке', label: { color: 'olive', empty: true, circular: true } },
    { text: 'Обработан', label: { color: 'violet', empty: true, circular: true } },
]

const STATUS_LABEL_ORDER_SUPPLIER = [
    { text: 'Создан', label: { color: 'yellow', empty: true, circular: true } },
    { text: 'Принят', label: { color: 'green', empty: true, circular: true } },
    { text: 'Отменен', label: { color: 'red', empty: true, circular: true } },
]

const STATUS_LABEL_REQUEST_PROCESS = [
    { text: 'Создан', label: { color: 'gray', empty: true, circular: true } },
    { text: 'В работе', label: { color: 'yellow', empty: true, circular: true } },
    { text: 'Обработан', label: { color: 'green', empty: true, circular: true } },
]

export const STATUS_OPTIONS = STATUS.map(el => ({...el, ...STATUS_LABEL[el.value] }))


export const STATUS_OPTIONS_PROCESS = STATUS_REQUEST_PROCESS.map(el => ({...el, ...STATUS_LABEL_REQUEST_PROCESS[el.value] }))

export const STATUS_OPTIONS_SUPPLIER = STATUS_ORDER_SUPPLIER.map(el => ({...el, ...STATUS_LABEL_ORDER_SUPPLIER[el.value] }))

export const STATUS_OPTIONS_PAYMENTS = STATUS_ORDER_SUPPLIER.map(el => ({...el, ...STATUS_LABEL_ORDER_SUPPLIER[el.value] }))

export const COLOR_LIST = [
    'red',
    'orange',
    'yellow',
    'olive',
    'green',
    'teal',
    'blue',
    'violet',
    'purple',
    'pink',
    'brown',
    'grey',
    'black',
]

const CONDITIONS_SUCCESS = ['склад', 'stock']
const CONDITIONS_CANSEL  = ['lead time not available', 'нет', 'нет в наличии']

const CONDITIONS_DEFAULT  = ['недел', 'дней', 'дня', 'месяц']

const CONDITIONS_SUCCESS_EXT = [null, '', 'в наличии', 'В наличии']

export function checkStockAvailability(value, price) {
    if ((CONDITIONS_SUCCESS_EXT.includes(value) && price > 0) || CONDITIONS_SUCCESS.some(el => value?.toLowerCase().includes(el))) {
        return [1, 'В наличии']
    }
    if (CONDITIONS_CANSEL.some(el => value?.toLowerCase().includes(el)) || price === 0) {
        return [0, 'Нет в наличии']
    }
    if (CONDITIONS_DEFAULT.some(el => value?.toLowerCase().includes(el))) {
        return [1, value]
    }
    return [-1, 'Требуется уточнение']
}