import { Tab, Container  } from "semantic-ui-react";
import { Requests } from "../Requests/Requests"
import { Orders } from '../Orders/Orders'
import { ClientList } from './component/ClientsList'

export const Clients = () => {
    const panes = [
        { menuItem: 'Запросы', render: () => <Requests /> },
        { menuItem: 'Заказы', render: () => <Orders /> },
        { menuItem: 'Список клиентов', render: () => <ClientList/> }
    ]

    return <div className="main-header">
        <Container fluid>
            <Tab menu={{ pointing: true }} panes={panes} />
        </Container>
    </div>
}