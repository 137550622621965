import React, { useState } from "react";
import {useMutation, useQuery} from "react-query";
import {Api} from "../../../_helpers";
import {
    Button,
    Form, FormField,
    FormGroup,
    FormInput,
    Header,
    Icon, Input,
    Label,
    Loader,
    Modal, ModalActions, ModalContent, ModalHeader,
    Segment,
    Table
} from "semantic-ui-react";
import dayjs from "dayjs";
import noticeError from "../../../hooks/useError";

const defaultData = {
    name: null,
    email: null,
    rate: 0, nbr: 0,
    uuid: null,
    pass: ''
}

export const ClientList = () => {
    const [requestList, setRequestList] = useState([])

    const [open, setOpen] = useState(false)
    const [data, setData] = useState(defaultData)

    const { isFetching } = useQuery(['clients.list'], () => Api().get('/clients'), {
        onSuccess: ({data}) => setRequestList(data)
    })

    const handler = (data) => {
        setData(data)
        setOpen(true)
    }

    if (isFetching) return <Loader active inline='centered' />

    return <>
        <Segment>
            <Form autoComplete="off">
                <Form.Group>
                    <Form.Field width={16}>
                        <Button floated="right" positive onClick={() => handler(defaultData)}>
                            <Icon name='user plus'/> Новый клиент
                        </Button>
                    </Form.Field>
                </Form.Group>
            </Form>
        </Segment>
        <Table selectable celled striped compact sortable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell textAlign="right">№</Table.HeaderCell>
                    <Table.HeaderCell>Клиент</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">email</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Заказов</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Код</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Last</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Nbr</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">Коэф.</Table.HeaderCell>
                    <Table.HeaderCell style={{ maxWidth: '10px' }} />
                    <Table.HeaderCell/>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {requestList.map((el, i) => {
                    const { name, code, uuid, rate, email, count, ltime, nbr, ishash } = el || {}
                    return <Table.Row key={uuid}>
                        <Table.Cell width={1} textAlign="right">{++i}</Table.Cell>
                        <Table.Cell width={4}>
                            <Header as='h4'>{name}</Header>
                        </Table.Cell>
                        <Table.Cell width={3}>
                            {email || '-'}
                        </Table.Cell>
                        <Table.Cell width={1} textAlign="center">
                            <Label style={{ width: '60px' }} size="large" color={count > 0 ? 'violet' : 'red'}>{count || 0}</Label>
                        </Table.Cell>
                        <Table.Cell width={1} textAlign="center">{code}</Table.Cell>
                        <Table.Cell width={2} textAlign="center">{ltime ? dayjs(ltime).format('DD.MM.YYYY') : '-'}</Table.Cell>
                        <Table.Cell width={1} textAlign="center">{nbr}</Table.Cell>
                        <Table.Cell width={1} textAlign="right">{rate}</Table.Cell>
                        <Table.Cell textAlign="center">
                            <Icon name='key' circular inverted disabled {...(ishash && { color: 'green' })}/>
                        </Table.Cell>
                        <Table.Cell width={1} textAlign="center">
                            <Button icon="edit" size="small" onClick={() => handler(el)}/>
                        </Table.Cell>
                    </Table.Row>
                })}
            </Table.Body>
        </Table>
        <Modal open={open} size="small" onClose={() => setOpen(false)} closeOnDimmerClick={false}>
            <DialogContent data={data} close={() => setOpen(false)} update={setRequestList}/>
        </Modal>
    </>
}

const DialogContent = ({data = {}, close, update: updateList }) => {
    const [form, setForm] = useState(data)
    const { name, email, rate, code, uuid, nbr, count, ishash, pass = '' } = form || {}
    const updateForm = (key, value) => setForm(prev => ({ ...prev, [key]: value} ))

    const { mutate: create, isLoading: isLoadingCreate } = useMutation(data => Api().post('/client/create', data), {
        onSuccess: ({data}) => {
            updateList(prev => [...prev, data].sort((a, b) => a.name < b.name ? -1 : 0))
            close()
        },
        onError: (err) => noticeError({message: err.response.statusText || err.response.data.message})
    })

    const { mutate: update, isLoading: isLoadingUpdate } = useMutation(data => Api().post('/client/update', data), {
        onSuccess: ({data}) => {
            updateList(prev => prev.map(el => el.uuid === data.uuid ? data : el))
            close()
        },
        onError: (err) => noticeError({message: err.response.statusText || err.response.data.message})
    })

    const { mutate: remove, isLoading: isLoadingDelete } = useMutation(data => Api().post('/client/delete', data), {
        onSuccess: ({data}) => {
            updateList(prev => prev.filter(el => el.uuid !== data))
            close()
        },
        onError: (err) => noticeError({message: err.response.statusText || err.response.data.message})
    })

    return <>
            <ModalHeader><Header as="h5">{ uuid ? 'Редактирование' : 'Создание нового'} клиента</Header></ModalHeader>
            <ModalContent>
                <Segment padded loading={isLoadingCreate || isLoadingUpdate || isLoadingDelete}>
                    <Form autoComplete="off">
                    <FormGroup widths='equal'>
                        <FormInput
                            required error={!name}
                            fluid label='Имя клиента' placeholder='OpenAI' maxLength="32"
                            value={name}
                            onChange={(e) => updateForm('name', e.target.value)}
                        />
                        <FormInput
                            fluid label='Email' placeholder='test@mail.com' maxLength="32"
                            value={email}
                            onChange={(e) => updateForm('email', e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup widths='equal'>
                        <FormInput
                            required error={!code}
                            fluid label='Код клиента (4 символа)' placeholder='4 символа' maxLength="4"
                            value={code}
                            onChange={(e) => updateForm('code', e.target.value.toUpperCase().replace(/[^A-Z0-9-]/gi, ''))}
                        />
                        <FormField error={rate === null} required>
                            <label>Коэффициент</label>
                            <Input
                                value={rate}
                                min="0" max="10" step="0.01"
                                onChange={(e) => updateForm('rate',
                                    e.target.value !== '' ? Math.max(0, Math.min(10, Number(e.target.value))) : null
                                )}
                                type="number"
                            />
                        </FormField>
                        <FormField error={nbr === null}>
                            <label>Нумерация к запросам</label>
                            <Input
                                value={nbr}
                                min="0" max="9999" step="1"
                                onChange={(e) => updateForm('nbr',
                                    e.target.value !== '' ? Math.max(0, Math.min(9999, Number(e.target.value))) : null
                                )}
                                type="number"
                            />
                        </FormField>
                    </FormGroup>
                    <FormGroup widths='equal'>
                        <FormField width={3}>
                                <label>Пароль</label>
                                <Input value={pass}
                                       {...(ishash && { placeholder: '***********' })}
                                       reaonly
                                       icon={<Icon name='key'
                                                inverted circular link
                                                onClick={() => updateForm('pass', gen_password(10))}
                                                {...(ishash && { color: 'green'})}
                                       />} />
                        </FormField>
                    </FormGroup>
                </Form>
                </Segment>
            </ModalContent>
            <ModalActions>
                {(uuid && !count) && <Button content="Удалить" floated='left' onClick={() => remove(form)} negative />}
                {uuid ? <Button content="Обновить" onClick={() => update(form)} negative /> :
                <Button content="Создать" onClick={() => create(form)} positive />}
                <Button color='black' content="Отмена" onClick={close} />
            </ModalActions>
        </>
}


function gen_password(len) {
    let password = ""
    let  symbols = "abcdefghijklmnopqrstuvwxyz"
    for (let i = 0; i < len; i++) {
        password += symbols.charAt(Math.floor(Math.random() * symbols.length))
    }
    return password
}