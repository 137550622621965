/**** компонент Поставщики ****/
import React, {useMemo, useState} from "react";
import {
    Button,
    Checkbox,
    Form, FormField, FormGroup,
    Header,
    Input, Label,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader,
    Segment, Select,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow
} from "semantic-ui-react"
import { useMutation, useQuery } from "react-query"
import { Api, arraysEqual } from "_helpers"
import noticeError from "hooks/useError"
import dayjs from "dayjs"
import {COLOR_LIST} from "../../../constant";

export const Suppliers = () => {
    const [open, setOpen] = useState(false)
    return <Modal
        style={{ width: '50%', minWidth: 1200 }}
        open={open} onClose={() => setOpen(false)}
        trigger={<Button fluid content="Поставщики" onClick={() => setOpen(true)}/>}
        closeOnDimmerClick={false}>
        <DialogContent close={() => setOpen(false)}/>
    </Modal>
}

const DEFAULT_FORM = {
    name: null,
    code: null,
    pkey: null,
    rate: 0,
    sort: 3,
    color: null,
    error: false
}

const DialogContent = ({ close }) => {
    const [data, setData] = useState([])
    const [init, setInit] = useState([])

    const updateState = (data) => setData(prev => [...prev, data])

    useQuery(['control.suppliers'], () => Api().get('/control/suppliers'), {
        onSuccess: ({data}) => {
            setData(data)
            setInit(data)
        }
    })

    const { mutate: update, isFetching: isFetchUpdate } = useMutation(data => Api().post('/control/suppliers/update', data), {
        onSuccess: () => setInit(data),
        onError: (err) => noticeError({ message: err.response.data.message })
    })

    const handler = (uuid, key, value) => setData(prev =>
        prev.map((el) => el.uuid === uuid ? ({ ...el, [key]: value }) : el ))


    const handlerUpdate = () => update(data.filter(el =>
        !init.some(elem => elem.uuid === el.uuid
            && (elem.rate === el.rate && elem.active === el.active && elem.fast === el.fast && elem.show === el.show && elem.slow === el.slow))
    ))

    const isDisabledButton = useMemo(() => arraysEqual(init, data), [init, data])

    return <>
        <ModalHeader><Header as="h5">Поставщики</Header></ModalHeader>
        <ModalContent scrolling>
            <Segment padded>
                <AppendSuppliersForm update={updateState} />
                <Table celled selectable size="small" unstackable>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>Поставщик</TableHeaderCell>
                            <TableHeaderCell textAlign="center">Код</TableHeaderCell>
                            <TableHeaderCell textAlign="center">Ключ</TableHeaderCell>
                            <TableHeaderCell textAlign="right">Ответов</TableHeaderCell>
                            <TableHeaderCell textAlign="center">Последний ответ</TableHeaderCell>
                            <TableHeaderCell textAlign="center">Цвет</TableHeaderCell>
                            <TableHeaderCell textAlign="right">Sort</TableHeaderCell>
                            <TableHeaderCell textAlign="right">Наценка</TableHeaderCell>
                            <TableHeaderCell textAlign="center">Быстрая</TableHeaderCell>
                            <TableHeaderCell textAlign="center">Медленная</TableHeaderCell>
                            <TableHeaderCell textAlign="center">Показ</TableHeaderCell>
                            <TableHeaderCell textAlign="center">Статус</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {data.map(({name, code, pkey, count, uptime, color, sort, rate, active, fast, slow, show, uuid}) => <TableRow key={uuid} negative={!active}>
                            <TableCell>
                                <Header as={'h5'}>{name}</Header>
                            </TableCell>
                            <TableCell textAlign="center">{code}</TableCell>
                            <TableCell textAlign="center">{pkey}</TableCell>
                            <TableCell textAlign="right">{count}</TableCell>
                            <TableCell textAlign="center">{dayjs(uptime).format('DD.MM.YYYY HH:mm')}</TableCell>
                            <TableCell textAlign="center">{color}</TableCell>
                            <TableCell textAlign="right">{sort}</TableCell>
                            <TableCell textAlign="right">
                                <Input
                                    fluid
                                    error={rate == null}
                                    value={rate}
                                    className="inputRight"
                                    min="0" max="10" step="0.01"
                                    onChange={(e) => handler(uuid, 'rate',
                                        e.target.value !== '' ? Math.max(0, Math.min(100, Number(e.target.value))) : null
                                    )}
                                    type="number"
                                />
                            </TableCell>
                            <TableCell textAlign="center">
                                <Checkbox toggle onClick={(_) => handler(uuid, 'fast', !fast)} checked={fast} />
                            </TableCell>
                            <TableCell textAlign="center">
                                <Checkbox toggle onClick={(_) => handler(uuid, 'slow', !slow)} checked={slow} />
                            </TableCell>
                            <TableCell textAlign="center">
                                <Checkbox toggle onClick={(_) => handler(uuid, 'show', !show)} checked={show} />
                            </TableCell>
                            <TableCell textAlign="center">
                                <Checkbox toggle onClick={(_) => handler(uuid, 'active', !active)} checked={active} />
                            </TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </Segment>
        </ModalContent>
        <ModalActions>
            <Button content="Обновить" onClick={handlerUpdate} positive disabled={isDisabledButton} loading={isFetchUpdate} />
            <Button color='black' content="Закрыть" onClick={close} />
        </ModalActions>
    </>
}

const AppendSuppliersForm = ({ update }) => {

    const [form, setForm] = useState(DEFAULT_FORM)
    const handlerForm = (key, val) => setForm(prev => ({ ...prev, [key]: val }))

    const { mutate: create , isFetching: isFetchAppend } = useMutation(data => Api().post('/control/suppliers/create', data), {
        onSuccess: ({data}) => {
            update(data)
            setForm(DEFAULT_FORM)
        },
        onError: (err) => noticeError({ message: err.response.data.message })
    })

    const append = () => {
        const { name, sort, code, pkey, color, rate } = form
        if (!name || !sort || !code || !pkey || !color || !Number.isFinite(rate)) {
            handlerForm('error', true)
            noticeError({ message: 'Нельзя добавить запись с пустым значением' })
            return
        }
        create(form)
    }

    return <Form>
        <FormGroup>
            <FormField label={'Поставщик'}
                       control={Input}
                       size={'mini'}
                       error={!form.name && form.error}
                       value={form.name}
                       onChange={(e) => handlerForm('name', e.target.value)}
                       placeholder='GeneralElectric' />
            <FormField width={3} error={!form.code && form.error}>
                <label>Код</label>
                <Input size={'mini'}
                       value={form.code}
                       onChange={(e) => handlerForm('code', e.target.value)}
                       placeholder='xxxx' />
            </FormField>
            <FormField width={3} error={!form.keys && form.error}>
                <label>Ключ</label>
                <Input size={'mini'}
                       value={form.pkey}
                       onChange={(e) => handlerForm('pkey', e.target.value)}
                       placeholder='xxx' />
            </FormField>
            <FormField width={4} error={!form.color && form.error}>
                <label>Цвет</label>
                <Select
                    style={{minWidth: "55px", fontSize: 11, padding: 5}}
                    options={COLOR_LIST.map(el => (
                        {key: el, text: <Label style={{ width: '60px' }} color={el} size={'mini'} content={el} key={el}/>, value: el}
                    ))}
                    clearable
                    onChange={(_, opt) => handlerForm('color', opt.value)}
                />
            </FormField>
            <FormField width={2} error={!form.sort && form.error}>
                <label>Sort</label>
                <Input size={'mini'}
                       value={form.sort}
                       placeholder='Сортировка'
                       onChange={(e) => handlerForm('sort', e.target.value)}
                />
            </FormField>
            <FormField width={3} error={form.rate === null && form.error}>
                <label>Наценка</label>
                <Input
                    fluid
                    defaultValue={0}
                    size={'mini'}
                    min="0" max="10" step="0.01"
                    onChange={(e) => handlerForm('rate',
                        e.target.value !== '' ? Math.max(0, Math.min(100, Number(e.target.value))) : null
                    )}
                    type="number"
                />
            </FormField>
            <FormField width={3}>
                <label>&nbsp;</label>
                <Button fluid content="Добавить" disabled={isFetchAppend} onClick={append} compact size={'small'} />
            </FormField>
        </FormGroup>
    </Form>
}