import {
    Accordion,
    Button, Checkbox, Divider,
    Dropdown,
    Form, Grid, Icon,
    Label, List, Message,
    Modal,
    Popup, Progress,
    Segment,
    Table
} from "semantic-ui-react";
import NumberInput from 'semantic-ui-react-numberinput';
import React, {useMemo, useState} from "react";
import { useQuery } from "react-query";
import {Api} from "../../_helpers";
import { useDebounce } from 'use-debounce';
import {STATUS_OPTIONS} from "../../constant";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import { OrderExportExcelFile } from './OrderExportExcelFile'
import { BasketModal } from './Basket'
import { cartActions } from '_store';
import {ModalLogisticExport} from "./ModalLogisticExport";

const StatusLabel = ({status}) => {
    const { text = 'Не определен', label: { color = 'brown' } = {} } = STATUS_OPTIONS.find(({ value }) => value === status) || {}
    return <Popup content={text} position='top center' trigger={<Label circular color={color} empty key={1}  />} />
}

export const Orders = () => {
    const [data, setData] = useState({ total: 0, list: [] })
    const [activePage, setActivePage] = useState(1)

    const { isFetching, refetch } = useQuery(['orders.list', [activePage]],
        () => Api().get('/orders', { params: { page: activePage - 1 }}), {
            onSuccess: ({ data }) => setData(data)
        })

    const handlerCloseCreate = () => {}

    return <>
        <CreateOrder close={handlerCloseCreate} />
        <Divider />
        <OrdersList list={data.list} isLoading={isFetching} />
    </>
}

const CreateOrder = ({ close }) => {
    const dispatch = useDispatch()
    const cartItem = useSelector(({cart}) => cart.item)
    const { value: uuid } = useSelector(({cart}) => cart.client) || {}

    const [open, setOpen] = useState(false)
    const [item, setItem] = useState('')
    const [resultList, setResultList] = useState([])
    const closed = () => {
        setOpen(false)
        close()
    }

    const handlerArticle = ({target: { value }}) => setItem(value.toUpperCase().replace(/[^0-9a-z-.]/gi, ""))

    const [ inputArticle ] = useDebounce(item, 800)

    const { error, isFetching } = useQuery(
        ['brands', [inputArticle]], () => Api().get('/requests/find.item', { params: { search: inputArticle }} ), {
            enabled: !!inputArticle && inputArticle.length >= 1,
            onSuccess: ({ data }) => setResultList(data.map( el => ({ ...el, quant2: 1 })))
        })

    return <>
            <Button content='Создать заказ' icon='plus' labelPosition='left' color="purple" onClick={() => setOpen(true)} />
            <Modal
                style={{ width: '90%', minWidth: 1300 }}
                open={open}
                onClose={() => setOpen(false)}
            >
                <Modal.Header>Создать заказ от клиента [ORDER]</Modal.Header>
                <Modal.Content scrolling>
                    <Segment padded secondary>
                        <Form autoComplete="off" size="big">
                            <Form.Group>
                                <Form.Field width={3}>
                                    <label>Клиент</label>
                                    <DropDownClientList
                                        value={uuid}
                                        set={(val) => dispatch(cartActions.setClient(val))}
                                    />
                                </Form.Field>
                                <Form.Input
                                    icon={item.length > 0 ? { name: 'close', link: true, onClick: () => setItem('') } : 'search'}
                                    width={10}
                                    value={item}
                                    label='Артикул'
                                    onChange={handlerArticle}
                                />
                                <Form.Field width={3}>
                                    <label>{'\u00A0'}</label>
                                    <Button
                                        fluid
                                        content={`[${cartItem.length}]`}
                                        onClick={() => dispatch(cartActions.open(true))}
                                        size='big'
                                        icon='shop'
                                    />
                                </Form.Field>
                           </Form.Group>
                        </Form>
                    </Segment>
                    <Segment loading={isFetching} style={{ minHeight: '50vh' }}>
                        {!uuid || resultList.length === 0 ? <MessageEmptyClient /> : <FindResultTable list={resultList} cartItem={cartItem} />}
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={closed}>Закрыть</Button>
                </Modal.Actions>
                <BasketModal close={closed} />
            </Modal>
        </>
}

const DropDownClientList = ({ value, set }) => {
    const { isFetching, data: { data = [] } = {} } = useQuery(['clients.list'], () => Api().get('/clients'))
    const list = useMemo(() => data.map(item => ({
        key: item.id,
        text: item.name,
        value: item.uuid
    })), [data])

    return <Dropdown
        // disabled
        error={!value}
        options={list}
        loading={isFetching}
        selection
        search
        clearable
        fluid
        // allowAdditions
        value={value}
        onChange={(_, { value }) => set(list.find((el) => el.value === value))}
    />
}

const OrdersList = ({ list, isLoading }) => {
    const [active, setActive] = useState([])

    const [exportData, setExportData] = useState([])
    const [exportLogistic, setExportLogistic] = useState({})
    const [openExport, setOpenExport] = useState(false)
    const [openLogisticExport, setOpenLogisticExport] = useState(false)

    const excelExport = (data = []) => {
        setExportData(data)
        setOpenExport(true)
    }

    const logisticExport = (data) => {
        setExportLogistic(data)
        setOpenLogisticExport(true)
    }

    const panels = list.map(({id, ctime, docid, name, uuid, count, label}, i) => ({
        key: 'panel' + i,
        // onTitleClick: (i) => i.onClick(),
        onTitleClick: () => setActive(prev => prev.includes(i) ? prev.filter(el => el !== i) : [...prev, i]),
        title: {
            style: { padding: 0 },
            icon: false,
            content: <List selection size='large'>
                <List.Item>
                    <List.Content>
                        <Grid verticalAlign='middle' divided stackable columns={6}>
                            <Grid.Row>
                                <Grid.Column style={{ maxWidth: '160px' }} textAlign="center">
                                    {dayjs(ctime).format('DD.MM.YYYY HH:mm')}
                                </Grid.Column>
                                <Grid.Column textAlign="left">
                                    <Label style={{ width: '180px' }} size="large" content={name} icon='user' />
                                </Grid.Column>
                                <Grid.Column textAlign="left">
                                    <Label size="large">№</Label>
                                    <Label style={{ width: '140px', textAlign: 'center' }} size="large">{label || '-'}</Label>
                                </Grid.Column>
                                <Grid.Column textAlign="left">
                                    <Label size="large">Количество:</Label>
                                    <Label size="large" color="violet" style={{ width: '60px', textAlign: 'right' }}>{count}</Label>
                                </Grid.Column>
                                <Grid.Column>
                                    <Progress style={{ margin: 0 }} percent={0} progress disabled />
                                </Grid.Column>
                                <Grid.Column style={{ maxWidth: '160px' }} floated='right' textAlign={'right'}>
                                    <div><Icon name={"angle" + (active.includes(i) ? " up" : " down")} circular/></div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </List.Content>
                </List.Item>
            </List>
        },
        content: { content: active.includes(i) && <TableRequest id={docid} docId={label || docid} excelExport={excelExport} logisticExport={(data) => logisticExport({ uuid, data })} /> }
    }))

    return <Segment loading={isLoading}>
        <Accordion exclusive={false} fluid panels={panels} activeIndex={active} />
        <OrderExportExcelFile open={openExport} close={() => setOpenExport(false)} data={exportData} />
        <ModalLogisticExport open={openLogisticExport} close={() => setOpenLogisticExport(false)} data={exportLogistic} />
    </Segment>
}

const TableRequest = ({id, excelExport, docId, logisticExport }) => {
    const [list, setList] = useState([])
    const [selected, setSelected] = useState([])
    const { isFetching } = useQuery(['order.info', [id]], () => Api().get('/orders/info', { params: { id }}), {
        onSuccess: ({data}) => setList(prev => [...prev, ...data])
    })

    const handlerChecked = (_, { checked, value }) => setSelected(prev => checked ? [...prev, value] : prev.filter(el => el !== value))
    const handlerCheckedAll = ({ checked }) => {
        const ids = list?.map(({ guid }) => guid )
        setSelected(prev => checked ? [...prev, ...ids] : prev.filter(el => !ids.includes(el)))
    }

    return <Table celled compact selectable>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell style={{ width: '40px' }} textAlign="center">
                    <Checkbox onClick={(_, data) => handlerCheckedAll(data)} />
                </Table.HeaderCell>
                <Table.HeaderCell style={{ width: '200px' }}>Производитель</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '180px' }}>Артикул</Table.HeaderCell>
                <Table.HeaderCell>Наименование</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '80px' }} textAlign="right">Количество</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '100px' }} textAlign="right">Цена</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '100px' }} textAlign="right">Сумма</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '154px' }} textAlign="center">Статус</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {list.map(({ article, brand, description, quant, price, status = 0, guid}) => {
                const { label: { color = 'white' }, text } = STATUS_OPTIONS[status]
                return <Table.Row key={guid}>
                    <Table.Cell textAlign="center">
                        <Checkbox checked={selected.some(el => el === guid)} value={guid} onClick={handlerChecked} />
                    </Table.Cell>
                    <Table.Cell>{brand}</Table.Cell>
                    <Table.Cell>{article}</Table.Cell>
                    <Table.Cell>{description}</Table.Cell>
                    <Table.Cell textAlign="right">{quant}</Table.Cell>
                    <Table.Cell textAlign="right">{(price)?.toFixed(2) || '-'}</Table.Cell>
                    <Table.Cell textAlign="right">{(quant * price).toFixed(2) || '-'}</Table.Cell>
                    <Table.Cell textAlign="center">
                        <Label color={color} content={text} />
                    </Table.Cell>
                </Table.Row>
            })}
        </Table.Body>
        <Table.Footer fullWidth>
            <Table.Row>
                <Table.HeaderCell colSpan='5'>
                    <Button color='green' onClick={() => logisticExport(list.filter(({ guid }) => selected.includes(guid)))} disabled={selected.length === 0}>
                        <Icon name='random' /> В КЖС
                    </Button>
                    <Button color='pink' onClick={() => excelExport({ docId, list })}>
                        <Icon name='file excel' /> Export to Excel
                    </Button>
                </Table.HeaderCell>
                <Table.HeaderCell textAlign={'right'}>
                    Итого:
                </Table.HeaderCell>
                <Table.HeaderCell textAlign={'right'}>
                    -
                </Table.HeaderCell>
                <Table.HeaderCell />
            </Table.Row>
        </Table.Footer>
    </Table>
}


const FindResultTable = ({ list, cartItem }) => {
    const dispatch = useDispatch()
    const handlerQuant = (id, val) => dispatch(cartActions.add({ guid: id, quant: val }))
    const handlerAdd = (val) => dispatch(cartActions.add(val))

    return <Table celled striped selectable compact unstackable size="small">
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell style={{ width: '120px' }} textAlign="center">Дата</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '180px' }}>Производитель</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '120px' }} textAlign="right">Артикул</Table.HeaderCell>
                <Table.HeaderCell>Наименование</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '70px' }} textAlign="right">Кол-во</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '120px' }} textAlign="right">Наличие</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '70px' }} textAlign="right">Цена</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={1}>Статус</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '70px' }} textAlign="center">Заказ</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '90px' }} />
                <Table.HeaderCell style={{ width: '70px' }} />
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {list.map(({ guid, cdate, name, article, description, quant, stock, price2, status, label, client }) => {
                const { label: { color = 'white' }, text } = STATUS_OPTIONS[status]
                const { quant: q = 1 } = cartItem.find(el => el.guid === guid) || {}
                const isExistCart = cartItem.some(el => el.guid === guid)
                return <Table.Row key={guid}>
                    <Table.Cell textAlign="center">
                        {dayjs(cdate).format('DD.MM.YYYY HH:mm')}
                    </Table.Cell>
                    <Table.Cell>{name}</Table.Cell>
                    <Table.Cell textAlign="right">{article}</Table.Cell>
                    <Table.Cell>{description}</Table.Cell>
                    <Table.Cell textAlign="right">{quant}</Table.Cell>
                    <Table.Cell textAlign="right">{stock}</Table.Cell>
                    <Table.Cell textAlign="right">{price2}</Table.Cell>
                    <Table.Cell textAlign="center">
                        <Label color={color} content={text} />
                    </Table.Cell>
                    <Table.Cell>
                        <Popup content={client} position='top center' trigger={
                            <Label style={{width: '110px', textAlign: 'center'}}
                                   size="large">{label || '-'}</Label>
                        }/>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        <NumberInput
                            buttonPlacement="right"
                            allowEmptyValue
                            placeholder=""
                            minValue={1}
                            maxValue={100}
                            showError={Boolean(!q)}
                            value={String(q)}
                            onChange={(val) => handlerQuant(guid, val)}
                            className="inputRight"
                        />
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        <Button
                            disabled={Boolean(!q)} fluid
                            onClick={() => isExistCart ? dispatch(cartActions.open(true)): handlerAdd({quant: +q, guid})} icon='cart'
                            positive={isExistCart}
                        />
                    </Table.Cell>
                </Table.Row>
            })}
        </Table.Body>
    </Table>
}

const MessageEmptyClient = () => {
    return <Segment padded>
            <Message warning header='Для поиска выберите клиента и введите номер артикула детали'
            content='Ничего не найдено'/>
    </Segment>
}