import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import { useQuery, useMutation } from 'react-query'
import * as FileSaver from  'file-saver'
import XLSX from 'sheetjs-style'
import {
    Button,
    Modal,
    Table,
    Checkbox,
    Grid,
    Segment,
    Dimmer,
    Loader,
    Message,
    Accordion,
    List,
    Label,
    Icon,
    Divider, Pagination, Progress
} from "semantic-ui-react";
import useStore from "../../hooks/useStore";
import { Api } from "../../_helpers";
import {Home} from "../Home/Home";

import { STATUS_OPTIONS } from "../../constant"

export const Requests = () => {
    const { requestBuffer: buffer, addRequestBuffer, removeRequestBuffer, removeRequestBufferAll } = useStore(store => store);

    const [requestList, setRequestList] = useState([])
    const [openDelete, setOpenDelete] = useState(false)
    const [selectDeleteId, setSelectDeleteId] = useState(false)
    const [checkedId, setCheckedId] = useState([])
    const [openBuffer, setOpenBuffer] = useState(false)

    const [activePage, setActivePage] = useState(1)

    const { error, isFetching, refetch } = useQuery(['requests.list', [activePage]],
        () => Api().get('/requests', { params: { page: activePage - 1 }}), {
        onSuccess: ({ data }) => setRequestList(data)
    })

    const { data: { data } = {} } = useQuery(['requests.total.pages', []], () => Api().get('/requests/clients.total.pages'))
    const { total = 0 } = data || {}

    const { mutate: rowDelete, isSuccess } = useMutation(id => Api().delete('/delete', { data: {id} }), {
        onSuccess: ({data}) => {
            setRequestList(prevState => prevState.filter(({id}) => id !== data.id))
            setSelectDeleteId(false)
            setOpenDelete(false)
        }
    })

    const callbackSuccess = ({data}) => {
        setRequestList(prev => prev.map(item =>
            ({...item, request: item.request.map(v => data.includes(v.guid) ? {...v, status: 1, request: true } : v)})
        ))
        removeRequestBufferAll()
        refetch().then(() => setOpenBuffer(false))
    }

    const { mutate: createRequest, isLoading: isLoadingCreate, isError } = useMutation(data => Api().put('/requests/create', data), {
        onSuccess: callbackSuccess
    })

    const handlerDelete  = id => setSelectDeleteId(id)

    const handlerAcceptDelete = id => rowDelete(id)

    const closeBuffer = () => setOpenBuffer(false)

    if(isError) console.log('ОСЛыосвло')

    useEffect(() => {
        if (!selectDeleteId) return
        setOpenDelete(true)
        return () => setSelectDeleteId(false)
    }, [selectDeleteId])

    const isChecked = id => buffer.some(el => el.id === id)

    const appendBuffer = () => {
        console.log(requestList)
        // console.log(requestList.map(({request}) => request.filter(({id}) => checkedId.includes(id))).filter(v => !!v.length).flat())
        // addRequestBuffer(requestList.map(({request}) => request.filter(({id}) => checkedId.includes(id))).filter(v => !!v.length).flat())
        // setCheckedId([])
    }
    const addBuffer = ({data, id}) => {
        if (data.checked) {
            addRequestBuffer(requestList.map(({request}) => request.filter(el => el.id === id)).filter(v => !!v.length).flat())
        } else {
            removeRequestBuffer(id)
        }
    }

    const createRequestLoad = () => createRequest(buffer.map(({ guid }) => guid ))

    const handlerChecked = data => addBuffer(data)
    const handlerCheckedAll = (data, list) => list.map(({ id }) => addBuffer({ data, id }))

    const panels = requestList.map(({ctime, docid, name, count, label, request}, i) => ({
        key: 'panel' + i,
        // onTitleClick: (i) => i.onClick(),
        title: {
            style: { padding: 0 },
            icon: false,
            loaded: true,
            content: <List selection size='large'>
                <List.Item>
                    <List.Content>
                        <Grid verticalAlign='middle' divided stackable columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column style={{ maxWidth: '160px' }} textAlign="center">
                                    {dayjs(ctime).format('DD.MM.YYYY HH:mm')}
                                </Grid.Column>
                                <Grid.Column textAlign="left">
                                    <Label style={{ width: '180px' }} size="large" content={name} icon='user' />
                                </Grid.Column>
                                <Grid.Column textAlign="left">
                                    <Label size="large">№</Label>
                                    <Label style={{ width: '140px', textAlign: 'center' }} size="large">{label || '-'}</Label>
                                </Grid.Column>
                                <Grid.Column textAlign="left">
                                    <Label size="large">Количество:</Label>
                                    <Label size="large" color="violet" style={{ width: '60px', textAlign: 'right' }}>{count}</Label>
                                </Grid.Column>
                                <Grid.Column>
                                    <Progress style={{ margin: 0 }} percent={0} progress disabled />
                                </Grid.Column>
                                <Grid.Column style={{ maxWidth: '160px' }} floated='right'>
                                    <Icon name='angle down' circular />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </List.Content>
                </List.Item>
            </List>
        },
        content: { content: <TableRequest list={request}
                                          handlerChecked={handlerChecked}
                                          isChecked={isChecked}
                                          handlerCheckedAll={(_, data) => handlerCheckedAll(data, request)}
                                          handlerDelete={handlerDelete}
                                          /> }
    }))

    return <div>
        <ExportRequest close={refetch} />
        <Button
            onClick={() => setOpenBuffer(true)}
            content='Буфер'
            floated="right"
            label={{ pointing: 'left', content: buffer.length }}
        />
        <Divider />
        <Segment loading={isFetching} padded={isFetching}>
            <Accordion exclusive={false} fluid panels={panels} />
        </Segment>
        <Divider />
        <Segment textAlign="center">
            <Pagination defaultActivePage={activePage} totalPages={total} siblingRange={10} onPageChange={(_, { activePage }) => setActivePage(+activePage)} />
        </Segment>
        <Buffer open={openBuffer} close={closeBuffer}
                buffer={buffer} create={createRequestLoad}
                load={isLoadingCreate} remove={removeRequestBuffer} />
        <Modal
            size='mini'
            dimmer='blurring'
            closeOnDimmerClick={false}
            open={openDelete}
            onClose={() => setOpenDelete(prev => !prev)}
        >
            <Modal.Header>Удалить эту запись</Modal.Header>
            <Modal.Content>
                <Segment padded>
                    Удалить эту запись
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button positive disabled onClick={() => handlerAcceptDelete(selectDeleteId)}>Да</Button>
                <Button negative onClick={() => setOpenDelete(false)}>Нет</Button>
            </Modal.Actions>
        </Modal>
    </div>
}

const Buffer = ({open, close, buffer, create, load, remove}) => {
    const isBuffer = !!buffer.length
    return <Modal
        size='large'
        dimmer='blurring'
        closeOnDimmerClick={false}
        open={open}
        onClose={close}
    >
        <Modal.Header>Буфер запросов</Modal.Header>
        <Modal.Content scrolling>
            <Segment basic padded>
                {isBuffer
                    ? <Dimmer.Dimmable as={Segment} dimmed={load}>
                        <Dimmer active={load} inverted>
                            <Loader>Загрузка...</Loader>
                        </Dimmer>
                        <Table selectable celled striped compact size='small'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Date</Table.HeaderCell>
                                    <Table.HeaderCell>Артикул</Table.HeaderCell>
                                    <Table.HeaderCell>Производитель</Table.HeaderCell>
                                    <Table.HeaderCell>Наименование</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="right">Количество</Table.HeaderCell>
                                    <Table.HeaderCell>Статус</Table.HeaderCell>
                                    <Table.HeaderCell>{''}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {buffer.map(({id, cdate, article, brand, description, quant, status}, i) => {
                                    return <Table.Row key={id}>
                                        <Table.Cell>{dayjs(cdate).format('DD.MM.YYYY HH:mm')}</Table.Cell>
                                        <Table.Cell>{article}</Table.Cell>
                                        <Table.Cell>{brand}</Table.Cell>
                                        <Table.Cell>{description}</Table.Cell>
                                        <Table.Cell textAlign="right">{quant}</Table.Cell>
                                        <Table.Cell>{status}</Table.Cell>
                                        <Table.Cell textAlign="center">
                                            <Button icon='trash' size='mini' onClick={() => remove(id)}/>
                                        </Table.Cell>
                                    </Table.Row>
                                })}
                            </Table.Body>
                        </Table>
                        </Dimmer.Dimmable>
                    : (<Message><p>В буфере ничего нет</p></Message>)}
            </Segment>
        </Modal.Content>
        <Modal.Actions>
            {isBuffer && <Button positive disabled={load} onClick={create} content="Создать запрос" />}
            <Button negative disabled={load} onClick={() => close()} content="Закрыть"/>
        </Modal.Actions>
    </Modal>
}

const TableRequest = ({list = [], handlerChecked, isChecked, handlerCheckedAll, handlerDelete}) => {
    return <Table celled striped singleLine selectable>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell textAlign="center" style={{ width: '80px' }}>
                    <Checkbox toggle onClick={handlerCheckedAll} />
                </Table.HeaderCell>
                <Table.HeaderCell style={{ width: '180px' }}>Производитель</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '180px' }}>Артикул</Table.HeaderCell>
                <Table.HeaderCell>Наименование</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '80px' }} textAlign="right">Количество</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '80px' }} textAlign="right">Наличие</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '100px' }} textAlign="right">Цена</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={1}>Статус</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={1} style={{ width: '80px' }}>
                    <ExportExcelFile data={list} />
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>

        <Table.Body>
            {!!list.length && list.map(({id, article, brand, description, quant, quant2, price, status, guid}) => {
                const { label: { color = 'white' }, text } = STATUS_OPTIONS[status]
                return <Table.Row key={id}>
                    <Table.Cell textAlign="center">
                        <Checkbox toggle
                                  disabled={status}
                                  onClick={(_, data) => handlerChecked({data, id})}
                                  checked={isChecked(id)}
                        />
                    </Table.Cell>
                    <Table.Cell>{brand}</Table.Cell>
                    <Table.Cell>{article}</Table.Cell>
                    <Table.Cell>{description}</Table.Cell>
                    <Table.Cell textAlign="right">{quant}</Table.Cell>
                    <Table.Cell textAlign="right">{quant2 || '-'}</Table.Cell>
                    <Table.Cell textAlign="right">{price || '-'}</Table.Cell>
                    <Table.Cell textAlign="center">
                        <Label color={color} content={text} />
                    </Table.Cell>
                    <Table.Cell textAlign="center" >
                        <Button icon='trash' size='mini' onClick={() => handlerDelete(guid)} />
                    </Table.Cell>
                </Table.Row>
            })}
        </Table.Body>
    </Table>
}

const ExportRequest = ({ close }) => {
    const [open, setOpen] = useState(false)
    const closed = () => {
        setOpen(false)
        setTimeout(() => close(), 1000)
    }
    return <>
            <Button content='Создать запрос' icon='plus' labelPosition='left' color="blue" onClick={() => setOpen(true)} />
            <Modal
                size='large'
                // dimmer='blurring'
                closeOnDimmerClick={false}
                open={open}
                onClose={() => setOpen(false)}
            >
                <Modal.Header>
                    Создать запрос от клента [REQUEST]</Modal.Header>
                <Modal.Content>
                    <Segment>
                        <Home close={closed}/>
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    {/*<Button positive disabled>Да</Button>*/}
                    <Button negative onClick={closed}>Закрыть</Button>
                </Modal.Actions>
            </Modal>
        </>
}

const ExportExcelFile = ({ data: excelData }) => {

    const [open, setOpen] = useState(false)

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'

    const exportToExcel = async (excelData, fileName = 'test') => {
        const ws = XLSX.utils.json_to_sheet(excelData.map(({ brand, article, quant, quant2, price }, i) => ({
            '№': i + 1,
            'Номер строки запроса предложения': null,
            'Бренд': brand,
            'Номер запчасти': article,
            'Количество': quant,
            'Единица измерения': quant2,
            'Цена/ед.': 'шт.',
            'Склад': '',
            'Вес': '',
            'Действительно до': ''
        })))
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: "array" })
        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, fileName + fileExtension)
    }

    return <>
        <Button color="pink" icon='file excel' size='mini' onClick={() => setOpen(true)} />
        <Modal
            size='large'
            dimmer='blurring'
            closeOnDimmerClick={false}
            open={open}
            onClose={() => setOpen(false)}
        >
            <Modal.Header>Export Excel</Modal.Header>
            <Modal.Content>
                <Segment padded textAlign="center">
                    <Button content="Сохранить в Excel" onClick={() => exportToExcel(excelData)} />
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                {/*<Button positive disabled>Да</Button>*/}
                <Button negative onClick={() => setOpen(false)}>Закрыть</Button>
            </Modal.Actions>
        </Modal>
    </>
}