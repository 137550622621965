import React, { useState } from "react";
import { useQuery } from "react-query";
import { Api } from "_helpers";
import { Pagination, Segment, Table, Form, Icon, Label, Button, GridRow, Grid, GridColumn } from "semantic-ui-react";
import useDebounced from "../../hooks/useDebouncedFunction";
import { Brands } from "../../components/Dropdown/Brands";
import { Dictionary } from "./component/Dictionary";
import { PartsHistory } from "./component/PartsHistory";
import { PartsInfo } from "./component/PartsInfo";

export const Catalog = () => {
    const [activePage, setActivePage] = useState(0)
    const [activeRow, setActiveRow] = useState([])

    const [findBrands, setFindBrands] = useState(null)
    const [findArticle, setFindArticle] = useState('')

    const [parts, setParts] = useState({})
    const [openParts, setOpenParts] = useState(false)

    const [data, setData] = useState({})

    const { isFetching} = useQuery(['catalog.list', [activePage, findArticle, findBrands]],
        () => Api().post('/catalog', {
            page: activePage,
            ...(findArticle && { article: findArticle }),
            ...(findBrands && { brand: findBrands })
        }), {
            onSuccess: ({ data }) => setData(data)
        })

    const { list = [], total = 0 } = data || {}

    const handlerOpenInfo = (id) => setActiveRow(prev => prev.includes(id) ? prev.filter(el => el !== id) : [...prev, id])

    const edit = (data) => {
        setParts(data)
        setOpenParts(true)
    }

    const close = () => setOpenParts(false)

    const updateState = (val) => setData(prev => ({
        ...prev,
        list: prev.list.map(el => el.nss === val.nss ? {
            ...el, description: val.description, weight: val.weight
        } : el)
    }))

    const inputFindArticle = useDebounced((val) => {
        setFindArticle(val)
        setActivePage(0)
    }, 800)

    return <Segment padded>
        <Grid divided='vertically' verticalAlign={'middle'} columns='equal' stretched stackable style={{ lineHeight: 0.8 }}>
            <GridRow>
                <GridColumn width={6}>
                    <Form>
                        <Form.Group>
                            <Form.Field width={8}>
                                <label>Производитель</label>
                                <Brands value={findBrands} handler={(value) => setFindBrands(value)} />
                            </Form.Field>
                            <Form.Input
                                fluid label='Артикул'
                                onChange={(_, { value }) => inputFindArticle(value)}
                                placeholder='' width={8}
                                icon={findArticle.length ? { name: 'close', link: true, onClick: () => inputFindArticle('') } : false}
                            />
                        </Form.Group>
                    </Form>
                </GridColumn>
                <GridColumn width={2} floated='right'>
                    <Dictionary content={'Словарь'} floated="right" />
                </GridColumn>
            </GridRow>
        </Grid>
        <Segment loading={isFetching}>
            <Table selectable celled striped compact>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="right" style={{ width: '60px' }}>№</Table.HeaderCell>
                        <Table.HeaderCell style={{ width: '200px' }}>Производитель</Table.HeaderCell>
                        <Table.HeaderCell style={{ width: '200px' }}>Артикул</Table.HeaderCell>
                        <Table.HeaderCell>Наименование</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right" style={{ width: '80px' }}>Предложений</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right" style={{ width: '100px' }}>Цена</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right" style={{ width: '150px' }}>Наличие</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right" style={{ width: '80px' }}>Вес</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" style={{ width: '80px' }}>Аналоги</Table.HeaderCell>
                        <Table.HeaderCell style={{ width: '60px' }} />
                        <Table.HeaderCell style={{ width: '60px' }} />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {list?.map(({ nss, brand, article, description, weight, isanalog, prices }, i) => {
                       let n = (activePage) * 100 + 1 + i
                       const row = prices?.find(Boolean) || {}
                       const { stock, price } = row || {}
                       return <React.Fragment key={i}>
                            <Table.Row positive={parts && parts.nss === nss}>
                                <Table.Cell textAlign="right">{n}</Table.Cell>
                                <Table.Cell>{brand}</Table.Cell>
                                <Table.Cell>{article}</Table.Cell>
                                <Table.Cell>{description}</Table.Cell>
                                <Table.Cell textAlign="right">
                                    <Label size="large" color={!prices?.length ? 'red' : 'green'}>{prices?.length || 0}</Label>
                                </Table.Cell>
                                <Table.Cell textAlign="right" negative={price === 0}>{price}</Table.Cell>
                                <Table.Cell textAlign="right" negative={stock === 'нет в наличии'}>{stock}</Table.Cell>
                                <Table.Cell textAlign="right">{weight}</Table.Cell>
                                <Table.Cell textAlign="center">
                                    <Label size="large" color={isanalog > 0 ? 'violet' : 'red'}>{isanalog || 0}</Label>
                                </Table.Cell>
                                <Table.Cell textAlign="right">
                                    <Button circular icon='edit' onClick={() => edit({nss})} />
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    <Icon name='angle down' circular onClick={() => handlerOpenInfo(i)}/>
                                </Table.Cell>
                            </Table.Row>
                            {activeRow.includes(i) && <Table.Row>
                               <Table.Cell colSpan={11} >
                                    <PartsHistory id={nss}></PartsHistory>
                               </Table.Cell>
                            </Table.Row>}
                       </React.Fragment>
                    })}
                </Table.Body>
            </Table>
        </Segment>
        <Segment padded textAlign="center">
            {Boolean(total) && <Pagination defaultActivePage={activePage} totalPages={total + 1} siblingRange={8} onPageChange={(_, { activePage }) => setActivePage(activePage - 1)} />}
        </Segment>
        <PartsInfo open={openParts} close={close} parts={parts} updateState={updateState} />
    </Segment>
}