import React, {useRef, useState, useMemo } from "react"
import {useMutation, useQuery} from "react-query";
import {Api} from "../../../_helpers";
import { ExcelRenderer } from "react-excel-renderer";
import noticeError from 'hooks/useError'
import { Button, Dropdown, Form, Grid, Message, Modal, Segment, Select, Table, Input } from "semantic-ui-react";

const checkingSequence = (data, row) => {
    if (!data) return
    let IntRange = []
    data?.map(item => {
        const value = item[row]
        const parentKey = String(value).includes('.0')
        if (value) {
            IntRange = [...IntRange, ({
                value: Math.trunc(+value),
                check: parentKey ? parentKey : IntRange.some(({ value: val, check }) => val === Math.trunc(+value) && check)
            })]
        }
    })
    return IntRange.filter((obj, idx, arr) => idx === arr.findIndex((t) => t.value === obj.value) && !obj.check)
}

export const ExcelExport = ({open, close}) => {
    const [file, setFile] = useState(null)
    const [openSupplier, setOpenSupplier] = useState(false)

    const [supplierList, setSupplierList] = useState([])
    const [supplierUid, setSupplierUid] = useState(null)

    const [reading, setReading] = useState([
        {name: 'B', key: 1, id: 0}, // Номер строк
        {name: 'C', key: 2, id: 1}, // Производитель
        {name: 'E', key: 4, id: 2}, // Артикул
        {name: 'G', key: 6, id: 3}, // Наименование
        {name: 'H', key: 7, id: 4}, // Кол-во
        {name: 'I', key: 8, id: 5}, // Ед изм.
        {name: 'J', key: 9, id: 6}, // Цена
        {name: 'M', key: 12, id: 7},
        {name: 'N', key: 13, id: 8},
        {name: 'Q', key: 16, id: 9}
    ])
    const [readRow, setReadRow] = useState(1)

    const inputRef = useRef()

    const { mutate: create } = useMutation(data => Api().put('/answers/create', data), {
        onSuccess: () => close(),
        onError: (err) => noticeError({ message: err.response.data.message })
    })

    const { isFetching } = useQuery(['supplier.list'], () => Api().get('/suppliers'), {
        enabled: openSupplier,
        onSuccess: ({data}) => setSupplierList(data.map(item => ({
            key: item.id,
            text: item.name,
            value: item.uuid
        })))
    })

    const onChangeReadRow = (e, option) => setReadRow(+option.value)
    const onChangeSelect = (e, option) => {
        const { value, id } = option
        const key = file?.cols.find(({ name }) => name === value)
        setReading(prev => !!prev?.[id] ? prev.map(
            (item, i) => i === id ? { ...key, id } : item
        ) : [...prev, ({...key, id})] )
    }

    const fileHandler = (event) => {
        let fileObj = event.target.files[0];
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) console.log(err);
            else {
                setFile({ cols: resp.cols, rows: resp.rows })
            }
        });
    }

    const tableColumn = [
        { title: '№ стр. зап.', width: '90px', align: 'right' },
        { title: 'Производитель', width: 'auto', align: 'left' },
        { title: 'Артикул', width: 'auto', align: 'left' },
        { title: 'Наименование', width: 'auto', align: 'left' },
        { title: 'Кол-во', width: '60px', align: 'right' },
        { title: 'Ед.изм.', width: '60px', align: 'right' },
        { title: 'Цена', width: '60px', align: 'right' },
        { title: 'Склад', width: '100px', align: 'right' },
        { title: 'Вес', width: '60px', align: 'right' },
        { title: 'Действ.до', width: 'auto', align: 'right' }
    ]

    const shortTable = file?.rows?.filter(item => item.length)?.slice(readRow)

    const requestData = (data, isParsCross = false, index = 0) => {
        let parent = null
        return data?.slice(readRow).map(data => {
            const value = isParsCross ? data[reading[0]?.key] : false
            const parentKey = String(value).includes('.0')
            index = parentKey ? +value : (index + 0.1)
            parent = parentKey && isParsCross ? value : parent
            return ({
                parent,
                twin: isParsCross ? String(index.toFixed(1)) : null,
                brand: String(data[reading[1]?.key]),
                article: String(data[reading[2]?.key]),
                description: String(data[reading[3]?.key]),
                quant: data[reading[4]?.key],
                unit: data[reading[5]?.key],
                price: data[reading[6]?.key],
                stock: data[reading[7]?.key],
                weight: data[reading[8]?.key],
                validity: data[reading[9]?.key],
            })
        })
    }

    const LoadExcel = () => create({
        uuid: supplierUid,
        data: requestData(file?.rows?.filter(item => item.length), isCheckCross),
        pars: isCheckCross // Флаг добавления кросс номеров в БД
    })

    let index = 0
    const func = x => x?.toString()?.includes('.') ? x?.toString()?.split('.')?.pop().length : 0
    const isCheckCross = useMemo(() => {
        if (!shortTable) return false
        return shortTable?.some(item => isFinite(item[reading[0]?.key]))
            && shortTable?.some(item => String(item[reading[0]?.key]).includes('.0'))
            && shortTable?.some(item => func(item[reading[0]?.key]) === 1)
    }, [reading, shortTable])

    const isReadCross = isCheckCross && Boolean(reading[0]?.name)

    const IntRange = useMemo(() => checkingSequence(shortTable, reading[0]?.key), [shortTable])

    const IsCheckAnalog = useMemo(() => file && Boolean(reading[0]?.name), [file, reading])

    return <Modal style={{ width: '90%', minWidth: 1300 }} closeOnDimmerClick={false} open={open} onClose={close}>
        <Modal.Header>Импорт из Excel</Modal.Header>
        <Modal.Content scrolling style={{ minHeight: '80vh' }}>
            <Segment padded secondary>
                <Form>
                    <Form.Group>
                        <Form.Field width={3}>
                            <label>Поставщик</label>
                            <Dropdown
                                options={supplierList}
                                loading={isFetching}
                                selection
                                search
                                clearable
                                fluid
                                error={!supplierUid}
                                onOpen={() => setOpenSupplier(true)}
                                // allowAdditions
                                value={supplierUid}
                                onChange={(_, {value}) => setSupplierUid(value)}
                            />
                        </Form.Field>
                        <Form.Field width={13}>
                            <label>Загрузка файла EXCEL</label>
                            <Button content="Выбрать файл" disabled={!supplierUid} labelPosition="left" icon="file"
                                    onClick={() => inputRef.current.click()}/>
                            <input ref={inputRef} type="file" hidden onChange={fileHandler}/>
                            <Button disabled={!supplierUid || !file} positive floated='right' onClick={LoadExcel}>Загрузить</Button>
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Segment>
            <Segment padded>
                {IsCheckAnalog && <>
                    <Message positive={isCheckCross} negative={!isCheckCross}>
                        {isCheckCross ? 'Установлено поле сопоставления аналогов' : 'Ошибка сопоставления аналогов'}
                    </Message>
                    {IntRange.length > 0 && <Message negative>
                        Обратите внимание! Среди загружаемых товаров, нет товара(-ов) прямого аналога.
                    </Message>}
                </>}
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={13}>
                            <Segment style={{ height: '40vh', overflowX: 'hidden', overflowY: 'auto', paddingRight: 10 }}>
                                <Table size='small' celled selectable compact="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell textAlign="right" style={{ width: '30px' }}>#</Table.HeaderCell>
                                            {tableColumn.map(({ title, width, align }, i) => {
                                                return <Table.HeaderCell key={i} style={{ width, textAlign: align }}>{title}</Table.HeaderCell>
                                            })}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {shortTable?.map((item, i) => {
                                            const value = item[reading[0]?.key]
                                            const parentKey = String(value).includes('.0')
                                            index = parentKey ? +value : (index + 0.1)
                                            return <Table.Row key={i} negative={IntRange.some(({ value: val }) => val === Math.trunc(+value))}>
                                                <Table.Cell textAlign="right">{i + 1}</Table.Cell>
                                                <Table.Cell textAlign="right">{isReadCross ? value : '-'}</Table.Cell>
                                                <Table.Cell>{item[reading[1]?.key]}</Table.Cell>
                                                <Table.Cell>{item[reading[2]?.key]}</Table.Cell>
                                                <Table.Cell>{item[reading[3]?.key]}</Table.Cell>
                                                <Table.Cell textAlign="right">{item[reading[4]?.key]}</Table.Cell>
                                                <Table.Cell textAlign="right">{item[reading[5]?.key]}</Table.Cell>
                                                <Table.Cell textAlign="right">{item[reading[6]?.key]}</Table.Cell>
                                                <Table.Cell textAlign="right">{item[reading[7]?.key]}</Table.Cell>
                                                <Table.Cell textAlign="right">{item[reading[8]?.key]}</Table.Cell>
                                                <Table.Cell textAlign="right">{item[reading[9]?.key]}</Table.Cell>
                                            </Table.Row>
                                        })}
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Grid columns={2} divided style={{margin: 0}}>
                                <Grid.Row style={{padding: '5px 0'}}>
                                    <Grid.Column width={5}>
                                        <Select
                                            style={{minWidth: "55px", fontSize: 11}}
                                            value={readRow}
                                            onChange={onChangeReadRow}
                                            options={[1, 2, 3, 4, 5, 6].map((index, i) => ({key: i, text: i + 1, value: i}))}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>Читать со строки</Grid.Column>
                                </Grid.Row>
                                {tableColumn.map(({ title }, i) => {
                                    return <Grid.Row key={i} style={{padding: '5px 0'}}>
                                        <Grid.Column width={5}>
                                            <Select
                                                id={i}
                                                clearable
                                                value={reading[i]?.name}
                                                onChange={onChangeSelect}
                                                style={{minWidth: "55px", fontSize: 11}}
                                                options={file?.cols?.map(({name, key}) => ({key, text: name, value: name}))}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>{ title }</Grid.Column>
                                    </Grid.Row>
                                })}
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            <Segment padded secondary>
                <Segment secondary style={{ height: '30vh', overflowX: 'hidden', overflowY: 'auto', paddingRight: 10 }}>
                    <Table size='small' celled compact="very" striped selectable singleLine fixed>
                        <Table.Header>
                            <Table.Row>
                                {file?.cols?.map(({name}, i) => <Table.HeaderCell key={i}>{name}</Table.HeaderCell>)}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {file?.rows?.filter(k => k.length).map((item, idx) =>
                                <Table.Row key={idx}>{file?.cols?.map((_, i) => <Table.Cell>{item[i]}</Table.Cell>)}</Table.Row>)}
                        </Table.Body>
                    </Table>
                </Segment>
            </Segment>
        </Modal.Content>
        <Modal.Actions>
            <Button negative onClick={close}>
                Закрыть
            </Button>
        </Modal.Actions>
    </Modal>
}