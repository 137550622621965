import {
    Header, Icon, Message, MessageHeader, Popup, Statistic, StatisticLabel, StatisticValue,
    Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow
} from "semantic-ui-react";

export const PopUpCoefficient = ({ coefficient, isStatic }) => {
    const {
        price: prc = 0,
        clients, word = 0, static: stat = 0, brand = 0, weightItem, weightUnit, parts, markup
    } = coefficient || {}

    const rwp = (weightUnit * weightItem) / prc
    const weight = Number.isFinite(rwp) ? rwp.toFixed(2) : 0
    const heft = Number.isFinite(rwp) && rwp > 0.1 ? (rwp - 0.1).toFixed(2) : 0

    const totalCoefficient = [+clients, +heft, +markup, +parts, +word, +brand].reduce((partialSum, a) => partialSum + a, 0).toFixed(2)

    return <Popup position='left center' flowing hoverable wide='very' style={{ width: '750px' }}
                  trigger={isStatic ? <Icon name='certificate' color='yellow' /> :
                      <Icon fitted link size={'large'} name='line graph' color='purple' />
                    }>
        <Table celled padded fixed selectable size={'small'} compact>
            <TableHeader>
                <TableRow>
                    <TableHeaderCell singleLine textAlign='center'>Сокращение</TableHeaderCell>
                    <TableHeaderCell width={4} textAlign='right'>Коэффициент</TableHeaderCell>
                    <TableHeaderCell textAlign='right'>Расчет</TableHeaderCell>
                    <TableHeaderCell width={5}>Comments</TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <Header as='h4' textAlign='center'>СС</Header>
                    </TableCell>
                    <TableCell textAlign='right'>-</TableCell>
                    <TableCell singleLine textAlign='right'>
                        <Header as='h5'>{prc}</Header>
                    </TableCell>
                    <TableCell negative={!prc} positive={prc}>Себестоимость</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell><Header as='h4' textAlign='center'>КК</Header></TableCell>
                    <TableCell singleLine textAlign='right'>{clients}</TableCell>
                    <TableCell textAlign='right'>{clients}</TableCell>
                    <TableCell negative={!clients} positive={clients}>Коэффициент клиента</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell><Header as='h4' textAlign='center'>НВ</Header></TableCell>
                    <TableCell textAlign='right' negative={!Number.isFinite(rwp)}>{ weightUnit } * {weightItem} кг / {prc} ({weight})</TableCell>
                    <TableCell textAlign='right'>{heft > 0 ? heft : 0}</TableCell>
                    <TableCell negative={!Number.isFinite(rwp)} positive={heft > 0}>Наценка по весу</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell><Header as='h4' textAlign='center'>НС</Header></TableCell>
                    <TableCell singleLine textAlign='right'>{word}</TableCell>
                    <TableCell textAlign='right'>{word}</TableCell>
                    <TableCell positive={word !== 0}>Наценка по слову</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell><Header as='h4' textAlign='center'>КП</Header></TableCell>
                    <TableCell singleLine textAlign='right'>{markup}</TableCell>
                    <TableCell textAlign='right'>{markup}</TableCell>
                    <TableCell>Коэффициент по поставщику</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell><Header as='h4' textAlign='center'>КС</Header></TableCell>
                    <TableCell singleLine textAlign='right'>{parts}</TableCell>
                    <TableCell textAlign='right'>{parts}</TableCell>
                    <TableCell positive={parts !== 0}>Коэффициент по себестоимости</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell><Header as='h4' textAlign='center'>КБ</Header></TableCell>
                    <TableCell singleLine textAlign='right'>{brand}</TableCell>
                    <TableCell textAlign='right'>{brand}</TableCell>
                    <TableCell positive={brand !== 0}>Коэффициент по бренду</TableCell>
                </TableRow>
{/*                <TableRow disabled>
                    <TableCell><Header as='h4' textAlign='center'>&ensp;К</Header></TableCell>
                    <TableCell singleLine textAlign='right'>-</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Компенсатор</TableCell>
                </TableRow>*/}
            </TableBody>
        </Table>
        {stat !== 0 ?
        <Message warning={stat !== 0} style={{ textAlign: 'center'}}>
            <Statistic size={'tiny'}>
                <StatisticLabel>Статическая цена</StatisticLabel>
                <StatisticValue>{new Intl.NumberFormat("ru-RU").format(stat)}</StatisticValue>
            </Statistic>
        </Message> :
        <Message style={{ textAlign: 'center'}}>
            <MessageHeader>Расчет</MessageHeader>
            <Statistic size={'mini'}>
                <StatisticValue>{prc}</StatisticValue>
                <StatisticLabel>CC</StatisticLabel>
            </Statistic>
            <Statistic size={'mini'} style={{ marginLeft: 10}}>
                <StatisticValue>*</StatisticValue>
            </Statistic>
            <Statistic size={'mini'} style={{ marginLeft: 10}}>
                <StatisticValue>(</StatisticValue>
            </Statistic>
            <Statistic size={'mini'} style={{ marginLeft: 5}}>
                <StatisticValue>{clients}</StatisticValue>
                <StatisticLabel>КК</StatisticLabel>
            </Statistic>
            <Statistic size={'mini'} style={{ marginLeft: 5}}>
                <StatisticValue>+</StatisticValue>
            </Statistic>
            <Statistic size={'mini'} style={{ marginLeft: 5}}>
                <StatisticValue>{heft > 0 ? heft : 0}</StatisticValue>
                <StatisticLabel>НВ</StatisticLabel>
            </Statistic>
            <Statistic size={'mini'} style={{ marginLeft: 5}}>
                <StatisticValue>+</StatisticValue>
            </Statistic>
            <Statistic size={'mini'} style={{ marginLeft: 5}}>
                <StatisticValue>{word}</StatisticValue>
                <StatisticLabel>НС</StatisticLabel>
            </Statistic>
            <Statistic size={'mini'} style={{ marginLeft: 5}}>
                <StatisticValue>+</StatisticValue>
            </Statistic>
            <Statistic size={'mini'} style={{ marginLeft: 5}}>
                <StatisticValue>{markup}</StatisticValue>
                <StatisticLabel>КП</StatisticLabel>
            </Statistic>
            <Statistic size={'mini'} style={{ marginLeft: 5}}>
                <StatisticValue>+</StatisticValue>
            </Statistic>
            <Statistic size={'mini'} style={{ marginLeft: 5}}>
                <StatisticValue>{parts}</StatisticValue>
                <StatisticLabel>КС</StatisticLabel>
            </Statistic>
            <Statistic size={'mini'} style={{ marginLeft: 5}}>
                <StatisticValue>+</StatisticValue>
            </Statistic>
            <Statistic size={'mini'} style={{ marginLeft: 5}}>
                <StatisticValue>{brand}</StatisticValue>
                <StatisticLabel>КБ</StatisticLabel>
            </Statistic>
            <Statistic size={'mini'} style={{ marginLeft: 5}}>
                <StatisticValue>)</StatisticValue>
            </Statistic>
            <Statistic size={'mini'} style={{ marginLeft: 5}}>
                <StatisticValue>({totalCoefficient})</StatisticValue>
            </Statistic>
            <Statistic size={'mini'} >
                <StatisticValue>=</StatisticValue>
            </Statistic>
            <Statistic size={'mini'} >
                <StatisticValue>{(prc * totalCoefficient).toFixed(2)}</StatisticValue>
                <StatisticLabel>Итоговая</StatisticLabel>
            </Statistic>
        </Message>}
    </Popup>
}