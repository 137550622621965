import React, {useRef, useState} from "react";
import {useMutation} from "react-query";
import {Api} from "../../../_helpers";
import { SelectClient } from './SelectClient'
import { ExcelRenderer } from "react-excel-renderer"
import {
    Button,
    Form,
    Grid,
    Header, Icon,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader,
    Segment,
    Select,
    Table
} from "semantic-ui-react";


const TITLE_COLUMN = [
    { id: 0, name: 'brand', title: 'Производитель' },
    { id: 1, name: 'article', title: 'Артикул' },
    { id: 2, name: 'description', title: 'Наименование' },
    { id: 4, name: 'quant', title: 'Кол-во' },
    { id: 5, name: 'price', title: 'Цена' }
]

const FormatData = (data) =>
    data.map(item => {
        let arr = {}
        item.map((el, i) => {
            arr[TITLE_COLUMN[i].name] = el
        })
        return arr
    })

export const LoadPayments = ({ reloadDocument }) => {
    const [open, setOpen] = useState(false)
    const [file, setFile] = useState(false)
    const [readRow, setReadRow] = useState(3)
    const [pay, setPay] = useState({ col: 0, row: 0 })

    const [client, setClient] = useState()

    const inputRef = useRef()
    const onChangeReadRow = (e, option) => setReadRow(+option.value)

    const { mutate: create } = useMutation(data => Api().put('/payments/create', data), {
        onSuccess: () => {
            reloadDocument()
            setClient(null)
            setFile(false)
            setOpen(false)
        }
    })

    const onChangePay = (val) => setPay(prev => ({...prev, ...val}))
    const handlerCreate = () => create({
        client,
        label: file?.rows[pay?.col][pay?.row],
        data: FormatData(file?.rows?.slice(readRow))
    })
    const fileHandler = (event) => {
        let fileObj = event.target.files[0];
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) console.log(err);
            else {
                setFile({
                    cols: resp.cols,
                    rows: resp.rows
                });
            }
        });
    }

    const isCheckButton = client && file

    return (
        <>
            <Button content='Загрузить счёт' icon='plus' labelPosition='left' color="green" onClick={() => setOpen(true)} />
            <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} closeOnDimmerClick={false} open={open} style={{ width: '60%', minWidth: 1300 }}>
                <ModalHeader><Header as="h5">Загрузить счёт</Header></ModalHeader>
                <ModalContent scrolling style={{ minHeight: '60vh' }}>
                    <Segment padded secondary>
                        <Form autoComplete="off">
                            <Form.Group>
                                <Form.Field width={4} required>
                                    <label>Клиент</label>
                                    <SelectClient client={client} setClient={(val) => setClient(val)}/>
                                </Form.Field>
                                <Form.Field width={12}>
                                    <label>{'\u00A0'}</label>
                                    <Button content="Выбрать файл" labelPosition="left" icon="file" onClick={() => inputRef.current.click()}/>
                                    <input ref={inputRef} type="file" hidden onChange={fileHandler}/>
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Segment>
                    <Segment padded>
                        {file ? <Grid columns={2} divided style={{margin: 0}}>
                            <Grid.Row style={{padding: '5px 0'}}>
                                <Grid.Column width={12}>
                                    <Segment padded secondary>{file ? file?.rows[pay?.col][pay?.row] : ''}</Segment>
                                    <Table selectable celled compact size='small' fixed>
                                        <Table.Header>
                                            <Table.Row>
                                                {TITLE_COLUMN.map(({ title, id }) => <Table.HeaderCell key={id}>{title}</Table.HeaderCell>)}
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {file?.rows?.slice(readRow).map((el, i) => {
                                                const isValid = true
                                                return <Table.Row key={i} positive={isValid}>{el.map(item => <Table.Cell>{item}</Table.Cell>)}</Table.Row>
                                            })}
                                        </Table.Body>
{/*                                        <Table.Footer fullWidth>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='4' textAlign="right">Итого:</Table.HeaderCell>
                                                <Table.HeaderCell>-</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Footer>*/}
                                    </Table>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Grid columns={2} divided style={{margin: 0, padding: 0}}>
                                        <Grid.Row style={{padding: 0}}>
                                            <Grid.Column width={8}>
                                                <Grid columns={2} style={{margin: 0, padding: 0}}>
                                                    <Grid.Row style={{padding: 0}}>
                                                        <Grid.Column width={8} style={{margin: 0, padding: '0 3px 0 0'}}>
                                                            <Select
                                                                fluid
                                                                style={{ fontSize: 11}}
                                                                value={pay.col}
                                                                onChange={(_, option) => onChangePay({ col: +option.value })}
                                                                options={file?.cols?.map(({ name, key }) => ({key, text: name, value: key}))}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column width={8} style={{margin: 0, padding: '0 0 0 3px'}}>
                                                            <Select
                                                                fluid
                                                                style={{minWidth: "55px", fontSize: 11}}
                                                                value={pay.row}
                                                                onChange={(_, option) => onChangePay({ row: +option.value })}
                                                                options={[1, 2, 3, 4, 5, 6].map((index, i) => ({key: i, text: i + 1, value: i}))}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Grid.Column>
                                            <Grid.Column>Счет</Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={8}>
                                                <Select
                                                    fluid
                                                    style={{minWidth: "55px", fontSize: 11}}
                                                    value={readRow}
                                                    onChange={onChangeReadRow}
                                                    options={[1, 2, 3, 4, 5, 6].map((index, i) => ({key: i, text: i + 1, value: i}))}
                                                />
                                            </Grid.Column>
                                            <Grid.Column>Читать со строки</Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid> : <Segment padded secondary>Выберите клиента и файл для загрузки</Segment>}
                    </Segment>
                </ModalContent>
                <ModalActions>
                    <Button content="Загрузить" onClick={handlerCreate} positive disabled={!isCheckButton} />
                    <Button color='black' onClick={() => setOpen(false)} content="Отмена"/>
                </ModalActions>
            </Modal>
        </>
    )

}
