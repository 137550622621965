import { useQuery } from "react-query";
import { Api } from "../../../_helpers";
import {Button, Checkbox, Label, Modal, Popup, Segment, Table} from "semantic-ui-react"
import dayjs from "dayjs";
import React from "react";

const DIALOG_STYLE = { width: '85vw', minWidth: 1200 }
const DIALOG_STYLE_CONTENT = { minHeight: '70vh' }

export const ModalHistoryItem = ({ open, close, item: { nss, guid }}) => {
    const { data: { data = [] } = {}, isFetching } = useQuery(['answerItem', [open]],
        () => Api().post('/requests/item.history', { guid }),
        { enabled: open && nss !== null }
    )

    return <Modal
        onClose={close}
        dimmer="blurring"
        style={DIALOG_STYLE}
        closeOnEscape
        closeOnDimmerClick={false}
        onOpen={() => console.log('Действие при открытии окна')}
        open={open}>
        <Modal.Header>История изменения детали</Modal.Header>
        <Modal.Content scrolling style={DIALOG_STYLE_CONTENT}>
            <Segment loading={isFetching}>
                <Table celled compact unstackable selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign={'center'} style={{ minWidth: '150px' }}>Дата и время</Table.HeaderCell>
                            <Table.HeaderCell style={{ minWidth: '130px' }}>Производитель</Table.HeaderCell>
                            <Table.HeaderCell style={{ minWidth: '130px' }}>Артикул</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' style={{ minWidth: '75px' }}>Вес</Table.HeaderCell>
                            <Table.HeaderCell textAlign='right' style={{ minWidth: '100px' }}>Цена</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' style={{ minWidth: '150px' }}>Ответ</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' style={{ minWidth: '150px' }}>Постащик</Table.HeaderCell>
                            <Table.HeaderCell textAlign='right' style={{ minWidth: '190px', width: '400px', padding: 0 }} >
                                <Table basic='very' fixed>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell width={5} textAlign="center" style={{ padding: 5 }}>Поставщик</Table.Cell>
                                            <Table.Cell width={4} textAlign="right">Цена</Table.Cell>
                                            <Table.Cell width={4} textAlign="right">%</Table.Cell>
                                            <Table.Cell width={5} textAlign="center" style={{ padding: 5 }}>Дата</Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {data.map(({ id, ctime, brand, article, weight, price, stock, supplier, supplier_guid: suid, stat = [] }) => <Table.Row key={id} verticalAlign="top">
                            <Table.Cell textAlign='center'>
                                    {dayjs(ctime).format('DD.MM.YYYY HH:mm:ss')}
                            </Table.Cell>
                            <Table.Cell>{brand}</Table.Cell>
                            <Table.Cell>{article}</Table.Cell>
                            <Table.Cell textAlign='center'>{weight}</Table.Cell>
                            <Table.Cell textAlign='right'>{price}</Table.Cell>
                            <Table.Cell textAlign='center'>{stock}</Table.Cell>
                            <Table.Cell textAlign='center'>{supplier}</Table.Cell>
                            <Table.Cell style={{ padding: 0, borderBottom: '1px dashed #444' }}>
                                <Table basic='very' fixed selectable compact>
                                    <Table.Body>
                                        {stat.filter(({ show, quant }) => show || quant).map((el, i) => {
                                            return <Table.Row key={i} positive={suid && el.supplier === suid}>
                                                <Table.Cell width={5} textAlign="center" style={{ padding: 5 }}>
                                                    <Popup content={el.name || 'Нет предложений'} position='top center' trigger={<Label size={'mini'} color={el.color} content={el.pkey} /> } />
                                                </Table.Cell>
                                                <Table.Cell width={4} textAlign="right" >{el.price || '-'}</Table.Cell>
                                                <Table.Cell width={4} textAlign="right">{el.rate || '-'}</Table.Cell>
                                                <Table.Cell width={5} textAlign="center" style={{ padding: 5 }}>
                                                    {el.ctime ? <Popup content={el.stock || null } position='top center' trigger={
                                                        <div>{dayjs(el.ctime).format('DD.MM.YYYY')}</div>
                                                    } /> : '-' }
                                                </Table.Cell>
                                            </Table.Row>
                                        })}
                                    </Table.Body>
                                </Table>
                            </Table.Cell>
                        </Table.Row>)}
                    </Table.Body>
                </Table>
            </Segment>
        </Modal.Content>
        <Modal.Actions>
            <Button color='black' onClick={close}>
                Закрыть
            </Button>
        </Modal.Actions>
    </Modal>
}
