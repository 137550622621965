import React, {useState} from "react";
import {useQuery} from "react-query";
import {Api} from "../../../_helpers";
import {
    Accordion,
    Button, Checkbox,
    Grid,
    GridColumn,
    GridRow,
    Icon,
    Label,
    List, Pagination,
    Popup,
    Progress,
    Segment, Table
} from "semantic-ui-react";
import dayjs from "dayjs";
import {useDispatch} from "react-redux";
import {tenderActions} from "../../../_store";

const STYLE_TABLE_HEADER = { padding: 0 }

const processColor = (percent) => {
    if (isNaN(percent) || +percent === 0) return 'red'
    return +percent === 100 ? 'green' : 'yellow'
}

function checkPay2Order(count, total) {
    if (count === total) {
        return ['green', 'Оплачен']
    }
    if (count < total) {
        return ['orange', 'Частично']
    }
    return ['red', 'Не оплачен']
}

export const ListView = () => {

    const [active, setActive] = useState([])
    const [activePage, setActivePage] = useState(0)

    const [filterDate, setFilterDate] = useState(null)

    const [list, setList] = useState({
        list: []
    })

    useQuery(['logistic.list', [activePage, filterDate]], () => Api().get('/logistic', {
        params: {
            page: activePage,
            ...(filterDate && { filterDate })
        }
    }), { onSuccess: ({ data }) => setList(data) })

    const panels = list.list.map(({ id, ctime, name: client, total, course, label, success, amount, payments, ocount }, i) => {
        const payment = payments.filter(Boolean)
        const countPays = payment.length
        const { pcount } = payment.find(Boolean) || {}
        const [color, title] = checkPay2Order(pcount, total)

        const percent = (100 * ocount / total).toFixed(2)
        return ({
            key: 'panel' + i,
            // onTitleClick: (i) => i.onClick(),
            onTitleClick: () => setActive(prev => prev.includes(i) ? prev.filter(el => el !== i) : [...prev, i]),
            title: {
                style: { padding: 0 },
                icon: false,
                // loaded: true,
                content: <List selection>
                    <List.Item>
                        <List.Content>
                            <Grid stackable divided stretched centered>
                                <GridRow verticalAlign='middle' style={{ justifyContent: 'space-between' }}>
                                    <GridColumn style={{ maxWidth: 50 }} />
                                    <GridColumn style={{ minWidth: 130 }}>
                                        {dayjs(ctime).format('DD.MM.YY HH:mm')}
                                    </GridColumn>
                                    <GridColumn width={2}>
                                        <Label content={client} icon='user'/>
                                    </GridColumn>
                                    <GridColumn width={3}>
                                        <Grid columns={3} divided stretched>
                                            <GridRow>
                                                <GridColumn width={7}>
                                                    <Popup content="Статус счета" position='top center' trigger={
                                                        <Label style={{ textAlign: 'center' }} color={color}>{title}</Label>
                                                    } />
                                                </GridColumn>
                                                <GridColumn width={4}>
                                                    <Popup content={countPays === 1 ? payment.map(({ label }) => <p>{label}</p>) : countPays > 1 ? 'Больше одного счёта' : 'Нет счетов'} position='top center' trigger={
                                                        <Label color={countPays === 1 ? 'green' : countPays > 1 ? 'red' : 'orange'} style={{ textAlign: 'center' }}>{countPays}</Label>
                                                    } />
                                                </GridColumn>
                                                <Grid.Column width={5} textAlign="right">
                                                    <Popup content="Количество товаров" position='top center' trigger={<Label >{total || 0} шт</Label> } />
                                                </Grid.Column>
                                            </GridRow>
                                        </Grid>
                                    </GridColumn>
                                    <GridColumn width={1} textAlign="right">
                                        <Popup content="Курс оплаты" position='top center' trigger={<Label color={countPays === 1 ? 'green' : countPays > 1 ? 'red' : 'orange'} style={{ textAlign: 'right' }}>{
                                            (payment.map(({ course }) => course)).join(', ') || '-'
                                        }</Label> } />
                                    </GridColumn>
                                    <GridColumn width={1} textAlign="right">
                                        <Popup content="Сумма по документу" position='top center' trigger={<Label {...(amount > 0 && { color: 'red'})}>
                                            {amount || 0} €
                                        </Label>} />
                                    </GridColumn>
                                    <GridColumn width={1} textAlign="right">
                                        <Popup content="Сумма по счету" position='top center' trigger={<Label>
                                            {(payment.find(Boolean)?.total) || 0} €
                                        </Label>} />
                                    </GridColumn>
                                    <GridColumn width={4}>
                                        <Progress style={{ margin: 0 }} percent={percent} progress color={processColor(percent)} />
                                    </GridColumn>
                                    <GridColumn width={1} textAlign={'center'}>
                                        <div><Button circular icon='edit' onClick={() => {}} /></div>
                                    </GridColumn>
                                    <GridColumn width={1} textAlign={'center'}>
                                        <div><Icon name={"angle" + (active.includes(i) ? " up" : " down")} circular/></div>
                                    </GridColumn>
                                </GridRow>
                            </Grid>
                        </List.Content>
                    </List.Item>
                </List>
            },
            content: { content: active.includes(i) && <TableLogistic id={id} /> }
        })
    })

    return  <Segment>
        <Segment style={{ minHeight: '10vh', padding: '10px 0'}}>
            <Accordion fluid panels={panels} exclusive={false} activeIndex={active} />
        </Segment>
        {list.total > 1 && <Segment textAlign="center">
            <Pagination
                activePage={activePage + 1}
                totalPages={list.total} siblingRange={10} onPageChange={(_, { activePage }) => setActivePage(activePage - 1)}
            />
        </Segment>}
    </Segment>
}


const TableLogistic = ({ id }) => {
    const dispatch = useDispatch()
    const [selected, setSelected] = useState([])
    const [list, setList] = useState([])

    const handlerChecked = (_, { checked, value }) => setSelected(prev => checked ? [...prev, value] : prev.filter(el => el !== value))
    const handlerCheckedAll = ({ checked }) => {
        const ids = list?.filter(({ count }) => +count === 0).map(({ id }) => id )
        setSelected(prev => checked ? [...prev, ...ids] : prev.filter(el => !ids.includes(el)))
    }

    const { isFetching } = useQuery(['logistic.info', [id]],
        () => Api().get('/logistic/info', { params: { id }}), {
            // enabled: !open,
            onSuccess: ({data}) => {
                console.log( data )
                setList(data)
            }
        })

    if (isFetching) return
    if (list.length === 0) return <Segment secondary padded textAlign={'center'}>Не найдены товары для этого заказа</Segment>

    return <Table compact celled>
        <Table.Header>
            <Table.Row className={'tableHeaderSmall'}>
                <Table.HeaderCell textAlign="center" style={{ ...STYLE_TABLE_HEADER }}>
                    <Checkbox onClick={(_, data) => handlerCheckedAll(data)} defaultIndeterminate={false} />
                </Table.HeaderCell>
                <Table.HeaderCell width={9} textAlign='right' style={{ padding: 0 }} >
                    <Table basic='very' fixed size={'small'} compact>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={3} style={{ padding: 10 }}>Производитель</Table.Cell>
                                <Table.Cell width={3}>Артикул</Table.Cell>
                                <Table.Cell width={6}>Наименование</Table.Cell>
                                <Table.Cell width={2} textAlign="right">Количество</Table.Cell>
                                <Table.Cell width={2} textAlign="right">Цена</Table.Cell>
                                <Table.Cell width={2} textAlign="right" style={{ padding: 10 }}>Сумма</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Table.HeaderCell>
                <Table.HeaderCell width={3} textAlign='right' style={{ minWidth: '290px', padding: 0 }} >
                    <Table basic='very' fixed size={'small'} compact>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={4} textAlign="center" style={{ padding: 5 }}>Поставщик</Table.Cell>
                                <Table.Cell width={4} textAlign="right">Цена</Table.Cell>
                                <Table.Cell width={4} textAlign="right">%</Table.Cell>
                                <Table.Cell width={4} textAlign="center" style={{ padding: 5 }}>Дата цены</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>Комментарий</Table.HeaderCell>
                <Table.HeaderCell textAlign={'center'}>Статус</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="center"  >
                    <Popup content="Добавить в тендер" position='top center' trigger={
                        <Button size={'mini'}  icon='shopping cart' color={'violet'}
                                onClick={() => dispatch(tenderActions.add(selected))}
                                disabled={selected.length === 0} />
                    } />
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {list?.map(({ brand, article, description, quant, price, total, comment, supplier = [], id, lstatus, count, docid, hstatus, label, payid }, i) => {
                const data = +count > 0 ? docid.filter(({ status }) => status === 1).at(0).supplier : supplier
                return <React.Fragment key={i}>
                    <Table.Row verticalAlign="top" positive={+count > 0}>
                        <Table.Cell textAlign="center" style={{ borderBottom: '1px solid #444' }}>
                            {+count === 0 && <Checkbox checked={selected.some(el => el === id)} onClick={handlerChecked} value={id} />}
                        </Table.Cell>
                        <Table.Cell style={{ padding: 0, borderBottom: '1px solid #444' }}>
                            <Table basic='very' size={'small'} fixed compact celled selectable>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell width={3} style={{ padding: 10 }}>{brand}</Table.Cell>
                                        <Table.Cell width={3}>{article}</Table.Cell>
                                        <Table.Cell width={6}>{description}</Table.Cell>
                                        <Table.Cell width={2} textAlign="right">{quant}</Table.Cell>
                                        <Table.Cell width={2} textAlign="right">{price}</Table.Cell>
                                        <Table.Cell width={2} textAlign="right" style={{ padding: 10 }}>{total}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        {payid && <Table.Cell style={{ padding: 10 }} colSpan={8}>
                                            <Popup content={hstatus === 1 ? 'Оплачен' : 'Не оплачен'} position='top center' trigger={
                                                <Label color={hstatus === 1 ? 'green' : 'red'}>{label}</Label>
                                            } />
                                        </Table.Cell>}
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Table.Cell>
                        <Table.Cell style={{ padding: 0, borderBottom: '1px solid #444' }}>
                            <Table basic='very' size={'small'} fixed compact celled selectable >
                                <Table.Body>
                                    {data?.map((el, i) => {
                                        return <Table.Row key={i}>
                                            <Table.Cell width={4} textAlign="center" style={{ padding: 5 }}>
                                                <Popup content={el.name || 'Нет предложений'} position='top center' trigger={<Label size={'mini'} color={el.color} content={el.pkey} /> } />
                                            </Table.Cell>
                                            <Table.Cell width={4} textAlign="right" >{el.price || '-'}</Table.Cell>
                                            <Table.Cell width={4} textAlign="right">{el.rate || '-'}</Table.Cell>
                                            <Table.Cell width={4} textAlign="center" style={{ padding: 5 }}>
                                                {el.ctime ? <Popup content={el.stock || null } position='top center' trigger={
                                                    <div>{dayjs(el.ctime).format('DD.MM.YYYY')}</div>
                                                } /> : '-' }
                                            </Table.Cell>
                                        </Table.Row>
                                    })}
                                </Table.Body>
                            </Table>
                        </Table.Cell>
                        <Table.Cell style={{ borderBottom: '1px solid #444' }}>
                            {comment && <Popup
                                trigger={<Icon name={"comment"} size={'big'} color={'red'} />}
                                content={comment}
                                position='left center'
                                inverted
                            />}
                        </Table.Cell>
                        <Table.Cell textAlign={'center'} style={{ borderBottom: '1px solid #444' }}>
                            {Boolean(count > 0) && <Popup content={'Товар заказан'} position='top center' trigger={
                                <Icon color='green' name='checkmark' size='large' circular />
                            } />}
                        </Table.Cell>
                        <Table.Cell style={{ borderBottom: '1px solid #444' }} />
                    </Table.Row>
                </React.Fragment>
            })}
        </Table.Body>
    </Table>

}