import { createSlice } from '@reduxjs/toolkit';

// create slice
const initialState = createInitialState()
const reducers = createReducers();
const slice = createSlice({
    name: 'cart', initialState, reducers
});

// exports
export const cartActions = slice.actions;
export const cartReducer = slice.reducer;

function createInitialState() {
    return {
        modal: false,
        item: [],
        client: null,
        error: null
    }
}

function createReducers() {
    return { open, add, deleted, clear, setClient }
    function open(state, action) {
        state.modal = action.payload;
    }
    function add(state, action) {
        const { guid, quant } = action.payload
        state.item = !state.item.some(el => el.guid === guid)
            ? [...state.item, action.payload]
            : state.item.map(el => el.guid === guid ? { ...el, quant } : el)
    }
    function deleted(state, action) {
        state.item = state.item.filter(el => el.guid !== action.payload)
    }
    function clear(state, action) {
        state.item = []
        if (action.payload) {
            state.modal = false
        }
    }
    function setClient(state, action) {
        state.client = action.payload
    }
}
