import { QueryClient, QueryClientProvider } from 'react-query'
import { history } from '_helpers';
import { PrivateRoute } from '_components';
import { Layout } from 'layout'
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { Login, LogOut } from "./pages"
const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false }}})
export const App = () => {
    history.navigate = useNavigate();
    history.location = useLocation();

    return (
      <QueryClientProvider client={queryClient}>
          <Routes>
              <Route path='*' element={<PrivateRoute><Layout /></PrivateRoute>} />
              <Route path='/login' element={<Login />} />
              <Route path='/logout' element={<LogOut />} />
          </Routes>
      </QueryClientProvider>
    )
}