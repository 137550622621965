import React, {useState} from "react";
import Excel from "exceljs";
import * as FileSaver from  'file-saver'
import {Button, Confirm, Header, Modal, Segment} from "semantic-ui-react";
import { checkStockAvailability } from 'constant'
export const ExportFromClient = ({ open, close, data: { docId, speed, list } = {} }) => {
    const [openConfirm, setOpenConfirm] = useState(false)
    const workbook = new Excel.Workbook()
    const columns = [
        { header: '№', key: 'rowId', width: 15 },
        { header: 'Номер строки запроса предложения', key: 'requestId', width: 4 },
        { header: 'Производитель', key: 'brand', width: null },
        { header: 'Номер запчасти', key: 'article', width: null },
        { header: 'Наименование', key: 'description', width: 25 },
        { header: 'Количество', key: 'quant', width: null },
        { header: 'Единица измерения', key: 'unit', width: 10 },
        { header: 'Цена/ед. (Евро)', key: 'price2', width: null },
        { header: 'Наличие', key: 'stock', width: 18 },
        { header: 'Вес', key: 'weight', width: null }
    ]

    const cells = ({ rowId, requestId, brand, article, description, quant, price2, stock, weight }) => {
        const [intStatus, isInStock] = checkStockAvailability(stock, +price2)
        return ({
            rowId,
            requestId,
            brand,
            article: article.replace(/[^0-9a-zA-Z]/gi, ""),
            description,
            quant,
            unit: 'шт.',
            price2: Number.isFinite(+price2) ? +price2 : 0,
            stock: isInStock, // formatStock(quant, stock),
            weight
        })
    }

    const exportToExcel = async (excelData, fileName) => {

        const emptyRows = [1, 2, 3, 4]

        // creating one worksheet in workbook
        const worksheet = workbook.addWorksheet(docId)

        // add worksheet columns
        // each columns contains header and its mapping key from data
        worksheet.columns = columns

        // updated the font for first row.
        worksheet.getRow(1).font = { bold: true };

        worksheet.columns.forEach(column => {
            column.width = column.width || column.header.length + 2
            // column.alignment = { horizontal: 'center' }
        })

        // loop through data and add each one to worksheet
        let rowIndex = 0
        emptyRows.forEach(row => worksheet.insertRow(row, row === 2 ? [docId] : []));

        excelData?.map((el, i) => {
            const { request, ...attr } = el
            worksheet.addRow(cells({ ...attr, requestId: (i + 1).toFixed(1), rowId: (++rowIndex) }))
            if (request) {
                request.map((el, index) => worksheet.addRow(cells({...el, requestId: `${(i + 1)}.${(index + 1)}`, rowId: (++rowIndex), quant: attr.quant })))
            }
        })

        worksheet.eachRow({ includeEmpty: false }, row => {
            row._cells.map(singleCell => worksheet.getCell(singleCell._address).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                }
            )
        })

        const buffer = await workbook.xlsx.writeBuffer()
        workbook.removeWorksheet(docId)
        FileSaver.saveAs(new Blob([buffer]), `${fileName}.xlsx`)
    }

    const handlerConfirmButton = () => {
        exportToExcel(list, 'request_' + docId).then(_ => {
            setOpenConfirm(false)
        })
    }

    const headerSaveAsFile = (speed) => {
        if (speed) {
            setOpenConfirm(true)
            return
        }
        exportToExcel(list, 'request_' + docId).then(_ => {})
    }

    return <>
        <Modal
            size='large'
            dimmer='blurring'
            closeOnDimmerClick={false}
            open={open}
            onClose={close}>
            <Modal.Header>Экспорт в файл для клиентов</Modal.Header>
            <Modal.Content>
                <Segment padded textAlign="center">
                    <Button content="Сохранить в Excel" onClick={() => headerSaveAsFile(speed) } />
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                {/*<Button positive disabled>Да</Button>*/}
                <Button negative onClick={close}>Закрыть</Button>
            </Modal.Actions>
        </Modal>
        <Confirm
            open={openConfirm}
            content={<Segment secondary padded inverted color="red">
                Этот заказ отмечан как "детальный", необходимо подтвердить цену и наличие у всех поставщиков.
            </Segment>}
            header={<Header as="h5">Внимание! Ручная обработка</Header>}
            cancelButton={'Отмена'}
            confirmButton={'Продолжить'}
            onCancel={() => setOpenConfirm(false)}
            onConfirm={() => handlerConfirmButton()}
        />
    </>
}
