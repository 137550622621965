import {
    Button, ButtonGroup,
    Grid,
    GridColumn,
    GridRow,
    Input,
    Label,
    Popup,
    Segment, Table
} from "semantic-ui-react";
import React, { useState } from "react";
import dayjs from "dayjs";
import {useQuery} from "react-query";
import {Api} from "../../_helpers";
import { TenderComponent } from './component/Tender'
import { ListView } from './component/ListView'
import { TableView } from './component/TableView'



export const Logistic = () => {
    const [filterDate, setFilterDate] = useState(null)
    const handlerChangeButton = (id) => setButton(prev => prev.map((el, i) => ({...el, active: i === id})))

    const [button, setButton] = useState([
        { icon: 'table', active: true, popupText: 'Данные в таблицы', component: <TableView /> },
        { icon: 'list', active: false, popupText: 'Данные в виде списка', component: <ListView /> },
    ])

    return <div>
        <Segment padded secondary>
            <Grid>
                <GridRow style={{ alignItems: 'flex-end' }}>
                    {/*<GridColumn style={{ maxWidth: 10 }}/>*/}
                    <GridColumn width={2}>
                        <label>Дата</label>
                        <Input
                            type="date" fluid
                            value={filterDate} onChange={(_, { value }) => setFilterDate(value)}
                            placeholder='Дата' max={dayjs().format('YYYY-MM-DD')} min="2024-05-01" />
                    </GridColumn>
                    <GridColumn width={11}></GridColumn>
                    <GridColumn width={3}>
                        <Grid columns={2} stackable stretched relaxed>
                            <GridRow>
                                <GridColumn>
                                    <ButtonGroup>
                                        {button.map((prop, i) => <Popup content={prop.popupText}
                                                                    position='top center'
                                                                    trigger={<Button key={i} {...prop} onClick={() => handlerChangeButton(i)} />} />)}
                                    </ButtonGroup>
                                </GridColumn>
                                <GridColumn>
                                    <TenderComponent />
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </GridColumn>
                </GridRow>
            </Grid>
        </Segment>
        {button.find(({ active, component }) => active).component}

    </div>
}

const Filter = () => {
    const [filterDate, setFilterDate] = useState(null)
    return <Segment padded secondary>
        <Grid>
            <GridRow>
                <GridColumn style={{ maxWidth: 10 }}/>
                <GridColumn style={{ minWidth: 165 }}>
                    <label>Дата</label>
                    <Input
                        type="date" fluid
                        value={filterDate} onChange={(_, { value }) => setFilterDate(value)}
                        placeholder='Дата' max={dayjs().format('YYYY-MM-DD')} min="2024-06-01" />
                </GridColumn>
                <GridColumn width={2}>
                    <label>Клиент</label>
                </GridColumn>
                <GridColumn width={2}>
                    <label>Статус счета</label>
                </GridColumn>
                <GridColumn width={3}>
                    <label>Артикул</label>
                </GridColumn>
                <GridColumn width={1} />
                <GridColumn width={5}>
                    <label>&nbsp;</label>
                    <Grid columns={2} divided>
                        <GridRow>
                            <GridColumn />
                            <GridColumn>
                                <TenderComponent />
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </GridColumn>
            </GridRow>
        </Grid>
    </Segment>
}