import React, { useState } from "react"
import {
    Button,
    Header,
    Icon,
    Input,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader,
    Segment,
    Table
} from "semantic-ui-react"
import {useQuery} from "react-query";
import {Api} from "../../../_helpers";
import noticeError from "../../../hooks/useError";
import useDebounced from "../../../hooks/useDebouncedFunction";
import dayjs from "dayjs";

export const Dictionary = ({ ...attr }) => {

    const [open, setOpen] = useState(false)
    const [openInfo, setOpenInfo] = useState(false)

    const [find, setFind] = useState(null)
    const [item, setItem] = useState({})

    const handlerOpenInfo = (val) => {
        setItem(val)
        setOpenInfo(true)
    }

    const close = () => setOpen(false)

    const { isFetching, data: { data = [] } = {} } = useQuery(['catalog.description', [find]],
        () => Api().get('/catalog/description/list', { params: { ...(find && { find }) }}), {
        enabled: open,
        onError: (err) => noticeError({ message: err.response.data.message })
    })

    const inputFindArticle = useDebounced((val) => {
        setFind(val)
    }, 800)

    return <Modal open={open} onClose={close} closeOnDimmerClick={false}
                  trigger={<Button {...attr} onClick={() => setOpen(true)}/>}>
        <ModalHeader><Header as="h5">База наименований</Header></ModalHeader>
        <ModalContent scrolling>
            <Segment padded secondary>
                <Input placeholder='Search...' size={'big'}
                       fluid
                       onChange={(_, { value }) => inputFindArticle(value)}
                />
            </Segment>
            <Segment padded loading={isFetching} style={{ minHeight: '60vh' }}>
                <Table compact='very' celled selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={1} textAlign={'right'}>id</Table.HeaderCell>
                            <Table.HeaderCell>Наименование</Table.HeaderCell>
                            <Table.HeaderCell width={1} />
                            <Table.HeaderCell width={1}/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {data?.map((el) => {
                            const { id, name, count, parent } = el
                            return <Table.Row negative={id !== parent}>
                                <Table.Cell textAlign={'right'}>{id}</Table.Cell>
                                <Table.Cell>{name}</Table.Cell>
                                <Table.Cell textAlign={'right'}>{count}</Table.Cell>
                                <Table.Cell textAlign={'center'}>
                                    <Button icon={'info'} circular size={'tiny'} color={'facebook'} onClick={() => handlerOpenInfo(el)} />
                                </Table.Cell>
                            </Table.Row>
                        })}
                    </Table.Body>
                </Table>
            </Segment>
            <DescriptionInfo open={openInfo} close={() => setOpenInfo(false)} item={item} />
        </ModalContent>
        <ModalActions>
            {/*<Button content="Обновить" positive />*/}
            <Button color='black' content="Закрыть" onClick={close} />
        </ModalActions>
    </Modal>
}

const DescriptionInfo = ({ open = false, close, item: { name, id } = {} }) => {

    const { isFetching, data: { data = [] } = {} } = useQuery(['catalog.description.parts'],
        () => Api().get('/catalog/description/parts', { params: { id } }), {
            enabled: open,
            onError: (err) => noticeError({ message: err.response.data.message })
        })

    return <Modal open={open} onClose={close} size={'small'} closeOnDimmerClick={false}>
        <ModalHeader><Header as="h5">Наименование {name}</Header></ModalHeader>
        <ModalContent scrolling>
            <Segment padded>
                <Table compact='very' celled selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={4}>Производитель</Table.HeaderCell>
                            <Table.HeaderCell>Артикул</Table.HeaderCell>
                            <Table.HeaderCell width={1}/>
                            <Table.HeaderCell width={3}>Добавлено</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {data?.map((el) => {
                            const { article, ctime, brand, confirm } = el
                            return <Table.Row>
                                <Table.Cell>{brand}</Table.Cell>
                                <Table.Cell>{article}</Table.Cell>
                                <Table.Cell textAlign={'center'}>
                                    {confirm && <Icon color='green' name='checkmark' size='large' />}
                                </Table.Cell>
                                <Table.Cell>{dayjs(ctime).format('DD.MM.YYYY HH:mm')}</Table.Cell>
                            </Table.Row>
                        })}
                    </Table.Body>
                </Table>
            </Segment>
        </ModalContent>
        <ModalActions>
            {/*<Button content="Обновить" positive />*/}
            <Button color='black' content="Закрыть" onClick={close} />
        </ModalActions>
    </Modal>
}