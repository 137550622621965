import {useQuery} from "react-query";
import {Api} from "../../../_helpers";
import {Segment, Table} from "semantic-ui-react";
import React from "react";
import dayjs from "dayjs";

export const PartsHistory = ({ id }) => {

    const { isFetching, data: { data } = {}} = useQuery(['catalog.history', [id]],
        () => Api().post('/catalog/prices', { id }))

    return <Segment loading={isFetching}>
        <Table compact='very'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell style={{ width: '200px' }}>Производитель</Table.HeaderCell>
                    <Table.HeaderCell style={{ width: '200px' }}>Артикул</Table.HeaderCell>
                    <Table.HeaderCell>Наименование</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right" style={{ width: '150px' }}>Дата</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right" style={{ width: '150px' }}>Цена</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right" style={{ width: '150px' }}>Наличие</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right" style={{ width: '150px' }}>Срок</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right" style={{ width: '150px' }}>Поставщик</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right" style={{ width: '80px' }}>Вес</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {data?.map(({ nss, brand, article, description, weight, prices }, i) => {
                    return prices.map(el => {
                        return <React.Fragment key={i}>
                            <Table.Row>
                                <Table.Cell>{el.brand}</Table.Cell>
                                <Table.Cell>{el.article}</Table.Cell>
                                <Table.Cell>{el.description}</Table.Cell>
                                <Table.Cell textAlign="right">{dayjs(el.ctime).format('DD.MM.YYYY')}</Table.Cell>
                                <Table.Cell textAlign="right">{el.price}</Table.Cell>
                                <Table.Cell textAlign="right">{el.stock}</Table.Cell>
                                <Table.Cell textAlign="right">{el.validity}</Table.Cell>
                                <Table.Cell textAlign="right">{el.supplier}</Table.Cell>
                                <Table.Cell textAlign="right">{el.weight}</Table.Cell>
                            </Table.Row>
                        </React.Fragment>
                    })
                })}
            </Table.Body>
        </Table>
    </Segment>
}
