import {
    Accordion,
    Button, ButtonGroup, Checkbox, Dropdown,
    Grid,
    GridColumn,
    GridRow,
    Icon,
    Label,
    List,
    Popup,
    Progress,
    Segment, Table
} from "semantic-ui-react";
import React, {useState} from "react";
import {useMutation, useQuery} from "react-query";
import {Api} from "../../_helpers";
import dayjs from "dayjs";
import { STATUS_OPTIONS_SUPPLIER } from "../../constant";
import noticeError from "../../hooks/useError";

const StatusLabel = ({status}) => {
    const { text = 'Не определен', label: { color = 'brown' } = {} } = STATUS_OPTIONS_SUPPLIER.find(({ value }) => value === status) || {}
    return <Popup content={text} position='top center' trigger={<Label circular color={color} empty key={1}  />} />
}

export const Invoice = () => {

    const [button, setButton] = useState([
        { icon: 'list', active: true },
        { icon: 'table', active: false },
    ])

    const checkButton = (id) => setButton(prev => prev.map((el, i) => ({ ...el, active: i === id} )))

    return <div>
        <Filter button={button} checkButton={checkButton}/>
        {button.find(({ active }) => active).icon === 'list' ? <AccordionView /> : <LiteTableView />}
    </div>

}

const AccordionView = () => {
    const [active, setActive] = useState([])
    const { isFetching, data: { data } = {} } = useQuery(['invoice.list'], () => Api().get('/invoice'))

    const panels = data?.list?.map(({ id, ctime, docid, invoice, name, color, code }, i) => {
        return ({
            key: 'panel' + i,
            onTitleClick: () => setActive(prev => prev.includes(i) ? prev.filter(el => el !== i) : [...prev, i]),
            title: {
                style: { padding: 0 },
                icon: false,
                // loaded: true,
                content: <List selection>
                    <List.Item>
                        <List.Content>
                            <Grid>
                                <GridRow verticalAlign='middle' style={{ justifyContent: 'space-around' }}>
                                    <GridColumn style={{ minWidth: 200 }}>
                                        {dayjs(ctime).format('DD.MM.YY HH:mm')}
                                    </GridColumn>
                                    <GridColumn width={1}>
                                        <Popup content="Номер заказа" position='top center' trigger={<Label style={{ textAlign: 'center' }}>№{invoice || '000000'}</Label> } />
                                    </GridColumn>
                                    <Grid.Column width={8} />
                                    <Grid.Column textAlign="center" style={{ maxWidth: '80px' }}>
                                        <Popup content={name} position='top center' trigger={<Label color={color} content={code} />} />
                                    </Grid.Column>
                                    <GridColumn width={1} textAlign={'center'}>
                                        <div><Icon name={"angle" + (active.includes(i) ? " up" : " down")} circular/></div>
                                    </GridColumn>
                                </GridRow>
                            </Grid>
                        </List.Content>
                    </List.Item>
                </List>
            },
            content: { content: active.includes(i) && <div style={{ margin: '0 10px', padding: 0 }}><TableInvoiceList docid={docid} /></div> }
        })
    })

    return <Accordion fluid panels={panels} exclusive={false} activeIndex={active} />
}
const LiteTableView = () => {
    const [data, setData] = useState({ list: [] })

    useQuery(['invoice.table'], () => Api().get('/invoice/table.view'), {
            onSuccess: ({ data }) => setData(data)
        })

    const { mutateAsync: update } = useMutation(data => Api().put('/invoice/update', data), {
        onSuccess: () => console.log('Yes'),
        onError: (err) => noticeError({message: err.response.data.message})
    })

    const handlerStatus = (id, status) => {
        update({ id, status }).then(() => {
            setData(prev => ({ ...prev, list: prev.list.map((el) => el.id === id ? ({ ...el, status }) : el)}))
        })
    }

    return <Table celled compact selectable columns={'eight'}>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Дата</Table.HeaderCell>
                <Table.HeaderCell>Производитель</Table.HeaderCell>
                <Table.HeaderCell>Артикул</Table.HeaderCell>
                <Table.HeaderCell>Наименование</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Кол-во</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Цена</Table.HeaderCell>
                <Table.HeaderCell textAlign={'center'}>Статус</Table.HeaderCell>
                <Table.HeaderCell textAlign={'center'}>Поставщик</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {data?.list.map(({ ctime, brand, article, description, quant, price, id, code, color, status }, i) => {
                return <React.Fragment key={i}>
                    <Table.Row verticalAlign="top">
                        <Table.Cell style={{ padding: 5 }}>{dayjs(ctime).format('DD.MM.YYYY HH:mm')}</Table.Cell>
                        <Table.Cell>{brand}</Table.Cell>
                        <Table.Cell>
                            {article}
                        </Table.Cell>
                        <Table.Cell width={6}>
                            {description}
                        </Table.Cell>
                        <Table.Cell width={1} textAlign="right">{quant}</Table.Cell>
                        <Table.Cell width={1} textAlign="right" style={{ padding: 5 }}>{price}</Table.Cell>
                        <Table.Cell width={1} textAlign="center">
                            <StatusLabel status={status} />
                            <Dropdown
                                className='icon'
                                floating
                                onChange={(_, { value }) => handlerStatus(id, value)}
                                value={status}
                                options={STATUS_OPTIONS_SUPPLIER}
                                trigger={<></>}
                            />
                        </Table.Cell>
                        <Table.Cell width={1} textAlign={'center'}>
                            <Label color={color} content={code} />
                        </Table.Cell>
                    </Table.Row>
                </React.Fragment>})}
        </Table.Body>
    </Table>
}

const TableInvoiceList = ({ docid }) => {

    const [selected, setSelected] = useState([])
    const [list, setList] = useState([])

    const handlerChecked = (_, { checked, value }) => setSelected(prev => checked ? [...prev, value] : prev.filter(el => el !== value))


    const { isFetching } = useQuery(['invoice.info', [docid]],
        () => Api().get('/invoice/info', { params: { docid }}), {
            enabled: Boolean(docid),
            onSuccess: ({data}) => setList(prev => [...prev, ...data])
        })

    return <Table celled compact selectable columns={'eight'}>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Производитель</Table.HeaderCell>
                <Table.HeaderCell>Артикул</Table.HeaderCell>
                <Table.HeaderCell>Наименование</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Кол-во</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Цена</Table.HeaderCell>
                <Table.HeaderCell>Комментарий</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {list?.map(({ brand, article, description, quant, price, comment, supplier = [], id }, i) => {
                return <React.Fragment key={i}>
                    <Table.Row verticalAlign="top">
                        <Table.Cell style={{ padding: 5 }}>
                            {brand}
                        </Table.Cell>
                        <Table.Cell>
                            {article}
                        </Table.Cell>
                        <Table.Cell width={6}>
                            {description}
                        </Table.Cell>
                        <Table.Cell width={2} textAlign="right">{quant}</Table.Cell>
                        <Table.Cell width={2} textAlign="right" style={{ padding: 5 }}>{price}</Table.Cell>
                        <Table.Cell>
                            {comment && <Popup
                                trigger={<Icon name={"comment"} size={'big'} color={'red'} />}
                                content={comment}
                                position='left center'
                                inverted
                            />}
                        </Table.Cell>
                    </Table.Row>
                </React.Fragment>
            })}
        </Table.Body>
    </Table>

}


const Filter = ({ button, checkButton }) => {

    return <Segment padded secondary>
        <Grid>
            <GridRow>
                <GridColumn width={1}>
                    <label>Дата</label>
                </GridColumn>
                <GridColumn width={2}>
                    <label>Клиент</label>
                </GridColumn>
                <GridColumn width={2}>
                    <label>Статус счета</label>
                </GridColumn>
                <GridColumn width={3}>
                    <label>Артикул</label>
                </GridColumn>
                <GridColumn width={3}>
                    <ButtonGroup compact>
                        {button.map((prop, i) => <Button key={i} {...prop} onClick={() => checkButton(i)} />)}
                    </ButtonGroup>
                </GridColumn>
            </GridRow>
        </Grid>
    </Segment>
}