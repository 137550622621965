/**** компонент Ценообразования ****/
import React, {useState, useMemo} from "react";
import {
    Button, Confirm, Grid, GridColumn, GridRow, Header, Input, Label, Modal, ModalActions, ModalContent, ModalHeader,
    Segment, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow
} from "semantic-ui-react";
import { useMutation, useQuery } from "react-query";
import { Api, arraysEqual } from "../../_helpers";
import noticeError from "../../hooks/useError";

const DEFAULT_ITEM = {
    word: { value: '', unit: '' },
    weight: { value: '', unit: '' }
}

export const Pricing = () => {
    const [open, setOpen] = useState(false)
    return <Modal
            open={open}
            size="small" onClose={() => setOpen(false)}
            trigger={<Button fluid content={'Коэффициенты'} onClick={() => setOpen(true)}/>}
            closeOnDimmerClick={false}>
        <DialogContent close={() => setOpen(false)}/>
    </Modal>
}

const DialogContent = ({ close }) => {
    const [data, setData] = useState([])
    const [init, setInit] = useState([])
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState({})
    const [items, setItems] = useState(DEFAULT_ITEM)

    const { isFetching }  = useQuery(['control.prices'], () => Api().get('/control/prices'), {
        onSuccess: ({data}) => {
            setData(data)
            setInit(data)
        }
    })

    const { mutate: remove , isFetching: isFetchRemove } = useMutation(data => Api().put('/control/remove', data), {
        onSuccess: ({ data }) => {
            setData(prev => prev.filter(({ id }) => id !== data.id))
            setInit(prev => prev.filter(({ id }) => id !== data.id))
            setOpen(false)
        },
        onError: (err) => noticeError({ message: err.response.data.message })
    })

    const { mutate: append , isFetching: isFetchAppend } = useMutation(data => Api().post('/control/append', data), {
        onSuccess: ({ data }) => {
            setData(prev => [...prev, data])
            setInit(prev => [...prev, data])
            setItems(prev => ({...prev, [data.key]: DEFAULT_ITEM[data.key] }))
        },
        onError: (err) => noticeError({ message: err.response.data.message })
    })

    const { mutate: update , isFetching: isFetchUpdate } = useMutation(data => Api().post('/control/update', data), {
        onSuccess: () => {
            setInit(data)
        },
        onError: (err) => noticeError({ message: err.response.data.message })
    })

    const handler = (id, key, value) => setData(prev =>
        prev.map((el) => el.id === id && el.key === key ? ({ ...el, unit: value }) : el )
    )

    const handlerItems = (key, value) => setItems(prev => ({...prev, [key]: ({ ...prev[key], ...value }) }))
    const handlerAppend = (key) => {
        const { value, unit } = items[key]
        if (!value || !unit) {
            noticeError({ message: 'Нельзя добавить запись с пустым значением' })
            return
        }
        append({ key, value, unit })
    }

    const handlerUpdate = () => update(data.filter(el =>
        !init.some(elem => elem.id === el.id && elem.unit === el.unit)
    ))

    const handlerRemove = (val) => {
        setValue(val)
        setOpen(true)
    }

    const { word } = items || {}

    const isDisabledButton = useMemo(() => arraysEqual(init, data), [init, data])

    const isLoader = useMemo(() => isFetching || isFetchAppend || isFetchUpdate || isFetchRemove, [
        isFetching, isFetchAppend, isFetchUpdate, isFetchRemove
    ])

    return <>
        <ModalHeader><Header as="h5">Коэффициенты</Header></ModalHeader>
        <ModalContent scrolling>
            <Segment padded loading={isLoader}>
                <Grid columns={2} divided>
                    <GridRow>
                        <GridColumn>
                            <Header as='h5' attached='top' block size={'small'}>
                                Основной (Не используется)
                            </Header>
                            <Segment attached padded>
                                {data.filter(({ key }) => key === 'default').map(({ id, unit, key }) =>
                                    <Input
                                        fluid
                                        disabled
                                        error={unit == null}
                                        value={unit}
                                        min="0" max="10" step="0.01"
                                        onChange={(e) => handler(id, key,
                                            e.target.value !== '' ? Math.max(0, Math.min(100, Number(e.target.value))) : null
                                        )}
                                        type="number"
                                    />)}
                            </Segment>
                        </GridColumn>
                        <GridColumn>
                            <Header as='h5' attached='top' block size={'small'}>
                                Весовой
                            </Header>
                            <Segment attached padded>
                                {data.filter(({ key }) => key === 'weight').map(({ id, unit, key }) =>
                                    <Input
                                        fluid
                                        error={unit == null}
                                        value={unit}
                                        min="0" max="10" step="0.01"
                                        onChange={(e) => handler(id, key,
                                            e.target.value !== '' ? Math.max(0, Math.min(100, Number(e.target.value))) : null
                                        )}
                                        type="number"
                                />)}
                            </Segment>
                        </GridColumn>
                    </GridRow>
                </Grid>
                <Header as='h5' attached='top' block size="small">
                    Вхождение слов
                </Header>
                <Segment attached padded>
                    <Table basic="very" compact>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell width={6}>
                                    <Input
                                        fluid size="mini"
                                        label="слово"
                                        value={word.value}
                                        onChange={(e) => handlerItems('word', { value: e.target.value })}
                                    />
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    <Input
                                        fluid
                                        // error={unit == null}
                                        value={word.unit}
                                        size="mini"
                                        min="0" max="10" step="0.01"
                                        type="number"
                                        onChange={(e) => handlerItems('word', {
                                            unit: e.target.value !== '' ? Math.max(0, Math.min(100, Number(e.target.value))) : null
                                        })}
                                    />
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    <Button size="mini" content="Добавить" onClick={() => handlerAppend('word')} />
                                </TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {data.filter(({ key }) => key === 'word').map(({ id, value, unit, key }) =>
                                <TableRow>
                                    <TableCell textAlign={'center'} width={6}>
                                        <Label content={value} />
                                    </TableCell>
                                    <TableCell width={4}>
                                        <Input
                                            fluid
                                            error={unit == null}
                                            value={unit}
                                            size={"mini"}
                                            min="0" max="10" step="0.01"
                                            onChange={(e) => handler(id, key,
                                                e.target.value !== '' ? Math.max(0, Math.min(100, Number(e.target.value))) : null
                                            )}
                                            type="number"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Button size={'mini'} icon='remove' negative onClick={() => handlerRemove({key, id})}/>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Segment>
                <Header as='h5' attached='top' block size={'small'}>
                    Производителя
                </Header>
                <Segment attached padded disabled>
                    <Table basic={'very'} compact>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell width={6}>
                                    <Input fluid size={'mini'} label={'Производитель'}/>
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    <Input
                                        fluid
                                        // error={unit == null}
                                        // value={unit}
                                        size={"mini"}
                                        min="0" max="10" step="0.01"
                                        type="number"
                                    />
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    <Button size={'mini'} content={'Добавить'} />
                                </TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {data.filter(({ key }) => key === 'brand').map(({ id, value, unit, key }) =>
                                <TableRow>
                                    <TableCell textAlign={'center'} width={6}>
                                        <Label content={value} />
                                    </TableCell>
                                    <TableCell width={4}>
                                        <Input
                                            fluid
                                            error={unit == null}
                                            value={unit}
                                            size={"mini"}
                                            min="0" max="10" step="0.01"
                                            onChange={(e) => handler(id, key,
                                                e.target.value !== '' ? Math.max(0, Math.min(100, Number(e.target.value))) : null
                                            )}
                                            type="number"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Button size={'mini'} icon='remove' negative />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Segment>
                <Header as='h5' attached='top' block size={'small'}>
                    Статическая цена
                </Header>
                <Segment attached padded disabled>
                    <Table basic={'very'}>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell width={4}>
                                    <Input fluid size={'mini'} label={'Артикул'}/>
                                </TableHeaderCell>
                                <TableHeaderCell width={4}>
                                    <Input fluid size={'mini'} label={'Производитель'}/>
                                </TableHeaderCell>
                                <TableHeaderCell width={3}>
                                    <Input
                                        fluid
                                        // error={unit == null}
                                        // value={unit}
                                        size={"mini"}
                                        min="0" max="10" step="0.01"
                                        type="number"
                                    />
                                </TableHeaderCell>
                                <TableHeaderCell width={3}>
                                    <Button size={'mini'} content={'Добавить'} />
                                </TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {data.filter(({ key }) => key === 'static').map(({ id, value, unit, key }) =>
                                <TableRow>
                                    <TableCell textAlign={'center'} width={6}>
                                        <Label content={value} />
                                    </TableCell>
                                    <TableCell width={4} />
                                    <TableCell>
                                        <Input
                                            fluid
                                            error={unit == null}
                                            value={unit}
                                            size={"mini"}
                                            min="0" max="10" step="0.01"
                                            onChange={(e) => handler(id, key,
                                                e.target.value !== '' ? Math.max(0, Math.min(100, Number(e.target.value))) : null
                                            )}
                                            type="number"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Button size={'mini'} icon='remove' negative />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Segment>
            </Segment>
        </ModalContent>
        <ModalActions>
            <Button content="Обновить" onClick={handlerUpdate} positive disabled={isDisabledButton} loading={isLoader} />
            <Button color='black' content="Закрыть" onClick={close} />
        </ModalActions>
        <Confirm
            size="tiny"
            open={open}
            cancelButton="Закрыть"
            confirmButton="Да"
            onCancel={() => setOpen(false)}
            onConfirm={() => remove(value)}
            content={<Segment padded>Ghjdhthrf</Segment>}
            header={<Header as="h5">Подтвердить удаление</Header>}
        />
    </>
}