import {Button, Modal, Segment} from "semantic-ui-react";
import {useMutation} from "react-query";
import {Api} from "../../_helpers";
import noticeError from "../../hooks/useError";


export const ModalLogisticExport = ({ open, close, data }) => {

    const { mutate: create } = useMutation(data => Api().post('/logistic/create', data), {
        onSuccess: () => close(),
        onError: (err) => noticeError({ message: err.response.data.message })
    })

    return <>
        <Modal open={open} onClose={close} closeOnDimmerClick={false}>
            <Modal.Content scrolling >
                <Segment padded>

                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button positive onClick={() => create(data)}>
                    Выгрузить
                </Button>
                <Button negative onClick={close}>
                    Закрыть
                </Button>
            </Modal.Actions>
        </Modal>
    </>
}