import {
    Accordion,
    Button,
    Divider,
    Dropdown, DropdownItem,
    DropdownMenu,
    Form,
    FormField,
    FormGroup,
    Grid,
    GridColumn,
    GridRow, Header,
    Icon,
    Label,
    List,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader,
    Popup,
    Progress,
    Segment,
    Select,
    Table
} from "semantic-ui-react"
import { LoadPayments } from './component/LoadPayments'
import {useMutation, useQuery} from "react-query";
import {Api} from "../../_helpers";
import React, {useEffect, useState} from "react"
import dayjs from "dayjs";
import NumberInput from "semantic-ui-react-numberinput";
import { STATUS_OPTIONS_PAYMENTS } from "../../constant";
import noticeError from "../../hooks/useError";

const StatusLabel = ({status}) => {
    const { text = 'Не определен', label: { color = 'brown' } = {} } = STATUS_OPTIONS_PAYMENTS.find(({ value }) => value === status) || {}
    return <Popup content={text} position='top center' trigger={<Label circular color={color} empty key={1}  />} />
}

const processColor = (percent) => {
    if (isNaN(percent) || +percent === 0) return 'red'
    return +percent === 100 ? 'green' : 'yellow'
}

export const Payments = () => {

    return <Segment padded>
        <DocumentList />
    </Segment>
}

const DocumentList = () => {

    const [data, setData] = useState([])
    const [active, setActive] = useState([])
    const [openEdit, setOpenEdit] = useState(false)
    const [editDocument, setEditDocument] = useState({})

    const { isFetching, refetch } = useQuery(['payments.list'],
        () => Api().get('/payments'), {
            onSuccess: ({ data }) => setData(data)
        }
    )

    const handlerOpenEdit = (val) => {
        setEditDocument(val)
        setOpenEdit(true)
    }

    const closeEdit = () => setOpenEdit(false)

    const updateList = (val) => setData(prev => ({...prev, list: prev.list.map(el => (el.docid === val.docid ? {...el, ...val} : el))}))

    const panels = data?.list?.map((el, i) => {
        const { id, docid, ctime, name: client, count, label, total, status, bracing,  course } = el
        const percent = (100 * bracing / count).toFixed(2)
        return ({
            key: 'panel' + i,
            // onTitleClick: (i) => i.onClick(),
            onTitleClick: () => setActive(prev => prev.includes(i) ? prev.filter(el => el !== i) : [...prev, i]),
            title: {
                style: { padding: 0 },
                icon: false,
                // loaded: true,
                content: <List selection>
                    <List.Item>
                        <List.Content>
                            <Grid stackable divided stretched centered>
                                <GridRow verticalAlign='middle' style={{ justifyContent: 'space-between' }}>
                                    <GridColumn style={{ minWidth: 130 }}>
                                        {dayjs(ctime).format('DD.MM.YY HH:mm')}
                                    </GridColumn>
                                    <GridColumn width={2}>
                                        <Label content={client} icon='user'/>
                                    </GridColumn>
                                    <GridColumn width={3}>
                                        <Popup content="Номер счета" position='top center' trigger={<Label style={{ textAlign: 'center' }}>{label || '№000000'}</Label> } />
                                    </GridColumn>
                                    <Grid.Column width={1} textAlign="right">
                                        <Popup content="Курс валюты" position='top center' trigger={<Label>{course || '-'}</Label> } />
                                    </Grid.Column>
                                    <Grid.Column width={1} textAlign="right">
                                        <Popup content="Количество товаров" position='top center' trigger={<Label >{count || 0} шт.</Label> } />
                                    </Grid.Column>
                                    <GridColumn width={1} textAlign="right">
                                        <Label {...(total > 0 && { color: 'red'})}>
                                            {total || 0} €
                                        </Label>
                                    </GridColumn>
                                    <GridColumn width={3}>
                                        <Progress style={{ margin: 0 }} percent={percent} progress color={processColor(percent)} />
                                    </GridColumn>
                                    <GridColumn width={1} textAlign="center">
                                        <Popup content="Статус счета" position='top center' trigger={
                                        <Label {...(!Boolean(status))} color={STATUS_OPTIONS_PAYMENTS[status].label.color}>
                                            {STATUS_OPTIONS_PAYMENTS[status].text}
                                        </Label>}/>
                                    </GridColumn>
                                    <GridColumn width={1} textAlign="center">
                                        <Popup content="Редактировать счёт" position='top center' trigger={
                                            <div><Button circular icon='edit'
                                                         onClick={() => handlerOpenEdit(el)}/></div>
                                        } />
                                    </GridColumn>
                                    <GridColumn width={1} textAlign={'center'}>
                                        <div><Icon name={"angle" + (active.includes(i) ? " up" : " down")} circular/></div>
                                    </GridColumn>
                                </GridRow>
                            </Grid>
                        </List.Content>
                    </List.Item>
                </List>
            },
            content: { content: active.includes(i) && <TablePayments docid={docid}></TablePayments> }
        })
    })

    return <Segment style={{ minHeight: '10vh', padding: '10px 0'}} loading={isFetching}>
        <LoadPayments reloadDocument={() => refetch()} />
        <Divider />
        <Accordion fluid panels={panels} exclusive={false} activeIndex={active} />
        <DocumentModal document={editDocument} open={openEdit} close={closeEdit} updateList={updateList} />
    </Segment>
}

const TablePayments = ({ docid }) => {

    const [list, setList] = useState([])

    const { isFetching } = useQuery(['payments.info', [docid]],
        () => Api().get('/payments/info', { params: { docid }}), {
            onSuccess: ({data}) => setList(data)
        })

    if (isFetching) return
    if (list.length === 0) return <Segment secondary padded textAlign={'center'}>Не найдены счета</Segment>

    return <Table celled compact selectable>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell width={1} style={{ padding: '0 10' }}>Производитель</Table.HeaderCell>
                <Table.HeaderCell width={3}>Артикул</Table.HeaderCell>
                <Table.HeaderCell width={5}>Товары</Table.HeaderCell>
                <Table.HeaderCell width={2} textAlign="right">Кол-во</Table.HeaderCell>
                <Table.HeaderCell width={2} textAlign="right">Цена</Table.HeaderCell>
                <Table.HeaderCell width={2} textAlign="right">Сумма</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="center" style={{ padding: '0 10' }}>Связь</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {list?.map(({ brand, article, description, quant, unit, price, total, payid }, i) => {
                return <React.Fragment key={i}>
                    <Table.Row verticalAlign="top">
                        <Table.Cell width={1} style={{ padding: '0 10' }}>{brand}</Table.Cell>
                        <Table.Cell width={3}>{article}</Table.Cell>
                        <Table.Cell width={5}>{description}</Table.Cell>
                        <Table.Cell width={2} textAlign="right">{quant}</Table.Cell>
                        <Table.Cell width={2} textAlign="right">{price}</Table.Cell>
                        <Table.Cell width={2} textAlign="right">{(quant * price).toFixed(2)}</Table.Cell>
                        <Table.Cell width={1} textAlign="center">
                            <Popup content={payid ? 'Товар связан' : 'Товар не связан'} position='top center' trigger={
                                payid ? <Icon color='green' name='checkmark' size='large'/> :
                                    <Icon color='red' name='close' size='large'/>
                            } />
                        </Table.Cell>
                    </Table.Row>
                </React.Fragment>
            })}
        </Table.Body>
        <Table.Footer fullWidth>
            <Table.Row>
                <Table.HeaderCell colSpan='5' textAlign="right">Итого:</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                    <Header as='h4'>{list.reduce((partialSum, a) => partialSum + (a.price * a.quant), 0)}</Header>
                </Table.HeaderCell>
                <Table.HeaderCell />
            </Table.Row>
        </Table.Footer>
    </Table>

}

const DocumentModal = ({ document, open, close, updateList }) => {

    const [data, setData] = useState({
        docid: null,
        label: '',
        status: 0,
        course: 0
    })

    const [list, setList] = useState([])

    const { isFetching } = useQuery(['payments.info'],
        () => Api().get('/payments/info', { params: { docid: data.docid }}), {
            onSuccess: ({data}) => setList(data),
            enabled: open && Boolean(data.docid)
        })

    const { mutateAsync: update } = useMutation(data => Api().put('/payments/update', data), {
        onSuccess: ({ data }) => {
            updateList(data)
            close()
        },
        onError: (err) => noticeError({message: err.response.data.message})
    })

    const { mutateAsync: updateItems } = useMutation(data => Api().put('/logistic/update.data', data), {
        onSuccess: ({ data }) => setList(prev => prev.map(el => el.id === data.payid ? { ...el, payid: data.payid, variations: null } : el)),
        onError: (err) => noticeError({message: err.response.data.message})
    })

    useEffect(() => document && setData(document), [document])

    return (
        <Modal onClose={() => close()} open={open} size={'large'}>
            <ModalHeader>{data?.label} - Редактирование</ModalHeader>
            <ModalContent>
                <Segment padded loading={isFetching}>
                    <Segment secondary padded>
                        <Form>
                            <FormGroup unstackable widths={4}>
                                <FormField error={!data.course}>
                                    <label>Курс валюты</label>
                                    <NumberInput
                                        buttonPlacement="right"
                                        allowEmptyValue
                                        placeholder=""
                                        minValue={0}
                                        maxValue={200}
                                        valueType="decimal"
                                        stepAmount={0.01}
                                        value={data.course}
                                        onChange={(val) => setData(prev => ({ ...prev, course: val }))}
                                        className="inputRight"
                                    />
                                </FormField>
                                <FormField />
                                <FormField />
                                <FormField>
                                    <label style={{ textAlign: 'center' }}>Статус счета</label>
                                    <div style={{ paddingTop: 10, textAlign: 'center' }}>
                                        <StatusLabel status={data.status} />
                                        <Dropdown
                                            className='icon'
                                            floating
                                            onChange={(_, { value }) => {
                                                setData(prev => ({ ...prev, status: value }))
                                            }}
                                            value={data.status}
                                            options={STATUS_OPTIONS_PAYMENTS}
                                            trigger={<></>}
                                        />
                                    </div>
                                </FormField>
                            </FormGroup>
                        </Form>
                    </Segment>
                    <Table celled compact size={'small'} selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={2} style={{ padding: '0 10' }}>Производитель</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Артикул</Table.HeaderCell>
                                <Table.HeaderCell width={6}>Наименование</Table.HeaderCell>
                                <Table.HeaderCell width={1} textAlign="right">Кол-во</Table.HeaderCell>
                                <Table.HeaderCell width={2} textAlign="right">Цена</Table.HeaderCell>
                                <Table.HeaderCell width={2} textAlign="right">Сумма</Table.HeaderCell>
                                <Table.HeaderCell width={1} textAlign="center">Связь</Table.HeaderCell>
                                {/*<Table.HeaderCell width={1} />*/}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {list.map(({ id, brand, article, description, quant, price, total, variations = [], payid }) => {
                                const options = variations ? variations.map(el => ({
                                    key: el.id, value: el.id, text: `ID: ${el.id} - ${dayjs(el.ctime).format('DD.MM.YYYY HH:mm')} - Цена: ${el.price} - Кол-во ${el.quant}`
                                })) : []
                                return <Table.Row>
                                    <Table.Cell>{brand}</Table.Cell>
                                    <Table.Cell>{article}</Table.Cell>
                                    <Table.Cell>{description}</Table.Cell>
                                    <Table.Cell textAlign="right">{quant}</Table.Cell>
                                    <Table.Cell textAlign="right">{(price)?.toFixed(2)}</Table.Cell>
                                    <Table.Cell textAlign="right">{(total)?.toFixed(2) || 0}</Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {variations ?
                                            <Dropdown text='[...]'>
                                                <DropdownMenu>
                                                    {options.map(el => <DropdownItem key={el.key} text={el.text} value={el.value} onClick={(_, {value}) => updateItems({ id: value, payid: id })} />)}
                                                </DropdownMenu>
                                            </Dropdown>
                                            :
                                            payid ? <Popup content={payid} position='top center' trigger={
                                                <Icon color='green' name='checkmark' size='large'/>
                                            } /> : ''}
                                    </Table.Cell>
                                </Table.Row>
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            </ModalContent>
            <ModalActions>
                <Button positive onClick={() => update(data)}>
                    Обновить
                </Button>
                <Button content="Отмена" onClick={() => close()} color='black' />
            </ModalActions>
        </Modal>
    )
}