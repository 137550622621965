import { Answers, Requests, Clients, Catalog, Management, Payments, Logistic, Invoice } from 'pages'
import { Route, Routes } from 'react-router-dom'

const AppRoutes = () => (
    <Routes>
        <Route path='/' element={<Clients />} />
        <Route path='/answers' element={<Answers />} />
        <Route path='/requests' element={<Requests />} />
        <Route path='/management' element={<Management />} />
        <Route path='/clients' element={<Clients />} />
        <Route path='/orders' element={<Clients />} />
        <Route path='/catalog' element={<Catalog />} />
        <Route path='/payments' element={<Payments />} />
        <Route path='/logistic' element={<Logistic />} />
        <Route path='/invoice' element={<Invoice />} />
    </Routes>
)

export default AppRoutes
