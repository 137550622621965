import React, {useState} from "react";
import {useQuery} from "react-query";
import {Api} from "../../../_helpers";
import {Dropdown} from "semantic-ui-react";

export const SelectClient = ({client, setClient}) => {
    const [openClient, setOpenClient] = useState(false)

    const [clientList, setClientList] = useState([])
    const { isFetching } = useQuery(['clients.list'], () => Api().get('/clients'), {
        enabled: openClient,
        onSuccess: ({data}) => setClientList(data.map(item => ({
            key: item.id,
            text: item.name,
            value: item.uuid
        })))
    })

    const handlerClient = (_, {value}) => setClient(value)

    return <Dropdown
        options={clientList}
        loading={isFetching}
        selection
        search
        clearable
        fluid
        onOpen={() => setOpenClient(true)}
        // allowAdditions
        value={client}
        onChange={handlerClient}
    />
}