import React, {useState} from "react";
import {useQuery} from "react-query";
import {Api} from "../../../_helpers";
import {Label, Segment, Table} from "semantic-ui-react";
import dayjs from "dayjs";

export const TableView = () => {
    const [list, setList] = useState([])
    useQuery(['logistic.list.view.table'], () => Api().get('/logistic/list.view.table'), { onSuccess: ({ data }) => setList(data) })
    return <Segment padded>
        <Table compact celled size={'small'} selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={1}>Дата</Table.HeaderCell>
                    <Table.HeaderCell>Производитель</Table.HeaderCell>
                    <Table.HeaderCell>Наименование</Table.HeaderCell>
                    <Table.HeaderCell>Артикул</Table.HeaderCell>
                    <Table.HeaderCell>Кол-во</Table.HeaderCell>
                    <Table.HeaderCell>Цена</Table.HeaderCell>
                    <Table.HeaderCell>Комментарий</Table.HeaderCell>
                    <Table.HeaderCell>Клиент</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Счет</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {list.map(({ id, ctime, brand, article, description, quant, price, name: client, comment, label }) => {
                    return <Table.Row key={id}>
                        <Table.Cell>{dayjs(ctime).format('DD.MM.YYYY HH:mm')}</Table.Cell>
                        <Table.Cell>{brand}</Table.Cell>
                        <Table.Cell>{article}</Table.Cell>
                        <Table.Cell>{description}</Table.Cell>
                        <Table.Cell>{quant}</Table.Cell>
                        <Table.Cell>{price}</Table.Cell>
                        <Table.Cell>{comment}</Table.Cell>
                        <Table.Cell><Label style={{ width: '100%' }} content={client} icon='user'/></Table.Cell>
                        <Table.Cell>{label}</Table.Cell>
                    </Table.Row>
                })}
            </Table.Body>
        </Table>
    </Segment>
}