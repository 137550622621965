import React, {useState, useEffect, useMemo} from 'react'
import {
    Table,
    Container,
    Button,
    Form,
    Segment,
    Dimmer,
    Loader,
    Dropdown,
    Divider,
    Input,
    Checkbox,
    Popup
} from 'semantic-ui-react'
import useStore from "hooks/useStore";
import { useQuery, useMutation } from 'react-query'
import {Api} from "../../_helpers";
import { ModalExcelExportClient } from 'pages/Clients/component/ModalExcelExportClient'
import { useNavigate } from "react-router-dom";
import noticeError from 'hooks/useError'
import { HighLightWords } from '../HighlightWords'
const ITEM_DEFAULT = {
    article: '',
    brand: { value: '' },
    description: '',
    quantity: 1
}

const NoticeErrorCyrillic = ({ article }) =>
    <HighLightWords text={article} searchWords={[... new Set(article.replace(/[^А-ЯЁ]/gi, "").split(''))]} />

export const Invoice = ({ close }) => {
    const navigate = useNavigate()
    const { invoiceBuffer: buffer, addBuffer, removeBuffer, removeBufferAll } = useStore(store => store);

    const [item, setItem] = useState(ITEM_DEFAULT)
    const [load, setLoad] = useState(false)
    const [clientList, setClientList] = useState([])
    const [selectClient, setSelectClient] = useState(null)
    const [orderNumber, setOrderNumber] = useState('')
    const [brands, setBrands] = useState([])
    const [search, setSearch] = useState('')
    const [openExport, setOpenExport] = useState(false)
    const [openClient, setOpenClient] = useState(false)

    const [sendProcessing, setSendProcessing] = useState(true)

    const { error, isFetched } = useQuery(
        ['brands', search], () => Api().get('/brands', { params: { search }} ), {
        enabled: !!search && search.length >= 1,
        onSuccess: ({ data }) => setBrands(data.map(({id, name}) => ({ key: id, text: name, value: id })))
    })

    const { isFetching } = useQuery(['clients.list'], () => Api().get('/clients'), {
        enabled: openClient,
        onSuccess: ({data}) => setClientList(data.map(item => ({
            key: item.id,
            text: item.name,
            value: item.uuid
        })))
    })

    const { article = '', brand: { value: brand } = {}, description = '', quantity = 1 } = item || {}

    const handlerAppendBuffer = () => {
        if (!selectClient || !article || !quantity) {
            noticeError({message: "Необходимо заполнить обязательные поля (клиент, артикул, количество)"})
            return
        }
        if (isCyrillic) {
            noticeError({message: "Номер артикула содержит кириллицу"})
            return
        }
        addBuffer({...item, uuid: selectClient, id: Math.random() * 100})
        setItem(ITEM_DEFAULT)
    }

    const handlerAppendItems = ({target: { name, value }}) => setItem(prev => ({...prev, [name]: value }))

    const handlerArticle = ({target: { value }}) => {
        setItem(prev => ({...prev, article: value.toUpperCase().replace(/[^0-9А-ЯЁa-z-./]/gi, "")}))
    }

    const handlerDelete = id => removeBuffer(id)

    const isVisibleRequestButton = !!buffer.length

    const { mutate: createRequest, isSuccess } = useMutation(data => Api().post('/client.create.request', data), {
        onSuccess: ({ data: { sendProcessing } }) => {
            removeBufferAll()
            close(true)
            sendProcessing && navigate('/requests-supplier/')
        },
        onError: (err) => noticeError({ message: err.response.data.message })
    })

    useEffect(() => {
        if (!load) return

        if (buffer.some(({ article }) => /[А-ЯЁ]/.test(article))) {
            noticeError({message: "Номер артикула содержит кириллицу"})
            setLoad(false)
            return
        }

        createRequest({
            sendProcessing,
            items: buffer.map(el => ({...el, label: orderNumber}))
        })
        setTimeout(() => setLoad(false), 1000)
    }, [load])

    const isCyrillic = useMemo(() => /[А-ЯЁ]/.test(article), [article])

    const handlerClient = (_, {value}) => setSelectClient(value)

    const handlerSupplier = (_, { value }) => {
        handlerAppendItems({ target: {
            name: 'brand', value: brands.find(({key}) => key === value)
        }})
    }

    const handleSearchChange = (_, { searchQuery }) => setSearch(searchQuery)

    return <div className="main-header">
        <Container fluid>
            <Dimmer.Dimmable as={Segment} dimmed={load}>
                <Dimmer active={load} inverted>
                    <Loader>Загрузка...</Loader>
                </Dimmer>
                <Segment padded secondary>
                    <Form autoComplete="off">
                        <Form.Group>
                            <Form.Field width={4} required>
                                <label>Клиент</label>
                                <Dropdown
                                    options={clientList}
                                    loading={isFetching}
                                    selection
                                    search
                                    clearable
                                    fluid
                                    onOpen={() => setOpenClient(true)}
                                    // allowAdditions
                                    value={selectClient}
                                    onChange={handlerClient}
                                />
                            </Form.Field>
                            <Form.Field width={4}>
                                <label>Артикул</label>
                                <Popup
                                    wide='very'
                                    trigger={<Input
                                        width={4}
                                        error={!article}
                                        value={article}
                                        onChange={handlerArticle}
                                        maxLength={32}
                                    />}
                                    content={<>Внимание! Артикул <NoticeErrorCyrillic article={article} /> содержит кириллицу</>}
                                    on='click'
                                    open={isCyrillic}
                                    position='top center'
                                />
                            </Form.Field>
                            <Form.Field width={5}>
                                <label>Производитель</label>
                                <Dropdown
                                    isFetching={isFetched}
                                    options={brands}
                                    search
                                    onSearchChange={handleSearchChange}
                                    selection
                                    fluid
                                    // allowAdditions
                                    value={brand || ''}
                                    // onAddItem={() => {}}
                                    clearable
                                    onChange={handlerSupplier}
                                    noResultsMessage={brand === '' ? 'поиск от 3-х символов' : 'Не найдено производителя'}
                                />
                            </Form.Field>
                            <Form.Input
                                width={8}
                                name='description'
                                value={description}
                                label='Наименование'
                                onChange={handlerAppendItems}
                            />
                            <Form.Field error={!quantity} >
                                <label>Кол-во</label>
                                <Input
                                    value={quantity}
                                    min="1" max="9999" step="1"
                                    onChange={(e) =>
                                        setItem(prev => ({...prev,
                                            quantity: e.target.value !== '' ? Math.max(1, Math.min(9999, Number(e.target.value))) : null
                                        }))}
                                    type="number"
                                />
                            </Form.Field>
                            <Form.Button content='Добавить' label={'\u00A0'} onClick={() => handlerAppendBuffer(item)} />
                            <Form.Button color="blue" icon="file excel" label={'\u00A0'} onClick={() => setOpenExport(true)} disabled={!selectClient} />
                        </Form.Group>
                    </Form>
                </Segment>
                <ModalExcelExportClient open={openExport} close={() => setOpenExport(false)} addBuffer={addBuffer} client={selectClient} />
                <Divider />
                {!!buffer.length && <Table singleLine selectable size='small'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Производитель</Table.HeaderCell>
                            <Table.HeaderCell>Артикул</Table.HeaderCell>
                            <Table.HeaderCell>Наименование</Table.HeaderCell>
                            <Table.HeaderCell>Количество</Table.HeaderCell>
                            <Table.HeaderCell textAlign='right'>-</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {buffer.map(({id, article, brand: { text: brand } = {}, description, quantity}) => <Table.Row key={id}>
                            <Table.Cell>{brand}</Table.Cell>
                            <Table.Cell>
                                <><NoticeErrorCyrillic article={article} /></>
                            </Table.Cell>
                            <Table.Cell>{description}</Table.Cell>
                            <Table.Cell>{quantity}</Table.Cell>
                            <Table.Cell textAlign='right'>
                                <Button icon='trash' size='mini' onClick={() => handlerDelete(id)}/>
                            </Table.Cell>
                        </Table.Row>)}
                    </Table.Body>
                </Table>}
                {isVisibleRequestButton && <Segment textAlign='center' secondary padded>
                    <InputRequestNumber client={selectClient} value={orderNumber} handler={setOrderNumber} />
                    <Divider />
                    <Checkbox toggle label='Отправить сразу на обработку' checked={sendProcessing} onChange={(_, { checked }) => setSendProcessing(checked)} />
                    <Divider />
                    <Button primary onClick={() => setLoad(true)} disabled={!orderNumber?.length}>Отправить запрос</Button>
                </Segment>}
                {isSuccess && <Segment textAlign='center'>
                    <Segment.Inline>
                        Запрос отправлен
                    </Segment.Inline>
                </Segment>}
            </Dimmer.Dimmable>
        </Container>
        {/*        <Container textAlign='center'>
            <Pagination     defaultActivePage={1}
                            firstItem={null}
                            lastItem={null}
                            pointing
                            secondary
                            totalPages={20} />
        </Container>*/}
    </div>
}


const InputRequestNumber = ({ client, value, handler }) => {
    const { isFetching } = useQuery(['client.gen.number', [client]],
        () => Api().post('/client.number.request', { client }), {
        onSuccess: ({ data: { invoice } }) => {
            handler(invoice)
        }
    })
    return <Input placeholder='XXXXXXXXXXXX/X' label='№' value={value} error={!value?.length} disabled loading={isFetching} />
}