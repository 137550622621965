import React, {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {Api} from "../../_helpers";
import {Dropdown} from "semantic-ui-react";


export const Brands = ({ value, handler, init = [], disabled = false}) => {
    const [list, setList] = useState(false)
    const [search, setSearch] = useState('')
    const { isFetching } = useQuery(
        ['brands.list', search], () => Api().get('/brands', { params: { search }} ), {
            enabled: !!search && search.length >= 1,
            onSuccess: ({ data }) => setList(data.map(({id, name, parent}) => ({ key: id, text: name, value: parent})))
        })

    const handleSearchChange = (_, { searchQuery }) => setSearch(searchQuery)

    return <Dropdown
        options={list || init}
        loading={isFetching}
        selection
        search
        clearable
        disabled={disabled}
        onSearchChange={handleSearchChange}
        value={value}
        onChange={(_, {value}) => handler(value)}
    />
}