import { createSlice } from '@reduxjs/toolkit'

// create slice
const initialState = createInitialState()
const reducers = createReducers();
const slice = createSlice({
    name: 'tender', initialState, reducers
});

// exports
export const tenderActions = slice.actions;
export const tenderReducer = slice.reducer;

function createInitialState() {
    return {
        open: false,
        items: [],
        error: null
    }
}

function createReducers() {
    function open(state, action) {
        state.open = action.payload
    }
    function add(state, action) {
        state.items = [...state.items, ...action.payload]
    }
    function deleted(state, action) {
        state.items = state.items.filter(el => el !== action.payload)
    }
    function clear(state, action) {

    }
    function close(state) {
        state.open = false
    }
    return { open, add, deleted, clear, close }
}