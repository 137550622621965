import React, { useState } from "react";

import {
    Button,
    Form,
    Grid,
    Table,
    Segment,
    Icon,
    Accordion,
    List,
    Label, Popup, Pagination
} from "semantic-ui-react";
import { useQuery } from 'react-query'
import {Api} from "../../_helpers";
import dayjs from "dayjs";
import { ExcelExport } from './component/ModalExcelExport'
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const fileExtension = '.xlsx'

const createExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData.map(({ ctime, brand, article, description, quant, price, stock, weight, validity, supplier }) => ({
        'Дата': ctime,
        'Производитель': brand,
        'Артикул': article,
        'Наименование': description,
        'Наличие': quant,
        'Наличие2': stock,
        'Цена': price,
        'Вес': weight,
        'Срок поставки': validity,
        'Поставщик': supplier
    })))
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: "array" })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
}

export const Answers = () => {
    const [open, setOpen] = useState(false)
    const [active, setActive] = useState(-1)
    const [activePage, setActivePage] = useState(0)
    const [answerList, setAnswerList] = useState([])

    const [findArticle, setFindArticle] = useState('')

    const [fromDate, setFromDate] = useState(dayjs().format('YYYY-MM-DD'))
    const [beforeDate, setBeforeDate] = useState(dayjs().format('YYYY-MM-DD'))

    const { isFetching: isLoad } = useQuery(['list', [activePage]], () => Api().post('/answers', { page: activePage }), {
        onSuccess: ({ data = [] }) => setAnswerList(data)
    })

    const { refetch, isFetching } = useQuery(['list.export'], () => Api().post('/answers/export', {
        from: fromDate,
        before: beforeDate
    }), {
        enabled: false,
        onSuccess: ({ data = [] }) => createExcel(data, 'export_' + dayjs().format('DD.MM.YYYY'))
    })

    const openModalImport = () => {
        setOpen(true)
        setActive(-1)
    }

    const { list = [], total = 0 } = answerList || {}

    const panels = list.map(({ guid, ctime, count, supplier, color, code }, i) => {
        return ({
            key: 'panel' + i,
            onTitleClick: () => setActive(prev => prev === i ? -1 : i),
            title: {
                style: { padding: 0 },
                icon: false,
                // loaded: true,
                content: <List selection>
                    <List.Item>
                        <List.Content>
                            <Grid verticalAlign='middle' divided stackable columns={'equal'}>
                                <Grid.Row>
                                    <Grid.Column style={{ maxWidth: '160px' }} textAlign="center">
                                        {dayjs(ctime).format('DD.MM.YYYY HH:mm')}
                                    </Grid.Column>
                                    <Grid.Column textAlign="center" style={{ minWidth: '340px' }}>
                                        <Label.Group size="large">
                                            <Label horizontal style={{ width: '310px' }}>{guid || '-'}</Label>
                                        </Label.Group>
                                    </Grid.Column>
                                    <Grid.Column textAlign="right">
                                        <Label size="large">Количество:</Label>
                                        <Label style={{ width: '60px' }} size="large" color={count > 0 ? 'violet' : 'red'}>{count || 0}</Label>
                                    </Grid.Column>
                                    <Grid.Column textAlign="center" style={{ maxWidth: '80px' }}>
                                        <Popup content={supplier} position='top center' trigger={<Label color={color} content={code} />} />
                                    </Grid.Column>
                                    <Grid.Column textAlign="right" style={{ maxWidth: '160px' }}>
                                        <Icon name={"angle" + (active === i ? " up" : " down")} circular/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </List.Content>
                    </List.Item>
                </List>
            },
            content: { content: active === i && <TableRequest guid={guid} /> }
        })
    })

    const closeExportModal = () => {
        setOpen(false)
        window.location.reload()
    }

    return (<Segment>
        <Segment secondary padded loading={isFetching}>
            <Grid verticalAlign='middle' columns='equal'>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Button color='twitter' onClick={openModalImport}>
                            <Icon name='file excel' /> Импорт из Excel
                        </Button>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <Form widths={'equal'}>
                            <Form.Group>
                                <Form.Input
                                    fluid
                                    label='Артикул'
                                    placeholder=''
                                    //icon={filterArts?.length ? { name: 'close', link: true, onClick: () => inputFindParts('') } : false}
                                />
                            </Form.Group>
                        </Form>
                    </Grid.Column>
                    <Grid.Column floated='right'>
                        <Form>
                            <Form.Group>
                                <Form.Input type="date" fluid label='Период с' value={fromDate} onChange={(_, { value }) => setFromDate(value)} placeholder='Дата' width={4} max={dayjs().format('YYYY-MM-DD')} min="2020-09-01" />
                                <Form.Input type="date" fluid label='Период до' value={beforeDate} onChange={(_, { value }) => setBeforeDate(value)} placeholder='Дата' width={4} max={dayjs().format('YYYY-MM-DD')} min="2020-09-01" />
                                <Form.Field width={6}>
                                    <label>&nbsp;</label>
                                    <Button onClick={() => refetch()}>
                                        <Icon name='file excel' /> Экспорт Excel
                                    </Button>
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
        <Segment padded loading={isLoad} style={{ minHeight: '10vh' }}>
            <Accordion fluid panels={panels} activeIndex={active} />
        </Segment>
        <Segment padded textAlign="center">
            {Boolean(total) && <Pagination defaultActivePage={activePage} totalPages={total + 1} siblingRange={8} onPageChange={(_, { activePage }) => setActivePage(activePage - 1)} />}
        </Segment>
        <ExcelExport open={open} close={closeExportModal} />
    </Segment>)
}

const TableRequest = ({ guid }) => {
    const { isFetching, data: { data: list } = [] } = useQuery(['list.info'], () => Api().post('/answers/info', { guid }))
    return <Segment loading={isFetching}>
        <Table celled striped selectable compact>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell style={{ width: 150 }}>Дата</Table.HeaderCell>
                <Table.HeaderCell>Производитель</Table.HeaderCell>
                <Table.HeaderCell>Артикул</Table.HeaderCell>
                <Table.HeaderCell>Наименование</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Количество</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Цена</Table.HeaderCell>
                <Table.HeaderCell style={{ width: 200 }}>Склад</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Годен</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {list && list.length && list.map(({id, ctime, article, brand, description, quant, price, stock, validity }) => {
                return <Table.Row key={id} style={{padding: '5px 0'}}>
                    <Table.Cell>{dayjs(ctime).format('DD.MM.YYYY HH:mm')}</Table.Cell>
                    <Table.Cell>{brand}</Table.Cell>
                    <Table.Cell>{article}</Table.Cell>
                    <Table.Cell>{description}</Table.Cell>
                    <Table.Cell textAlign="right">{quant}</Table.Cell>
                    <Table.Cell textAlign="right">{price}</Table.Cell>
                    <Table.Cell textAlign="right">{stock}</Table.Cell>
                    <Table.Cell textAlign="right">{validity}</Table.Cell>
                </Table.Row>
            })}
        </Table.Body>
    </Table>
    </Segment>
}