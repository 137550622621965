import React, { useEffect, useState} from "react";
import {useMutation, useQuery} from 'react-query'
import { useDebouncedCallback } from 'use-debounce';
import Excel from 'exceljs'
import * as FileSaver from  'file-saver'
import {
    Button,
    Grid,
    Segment,
    Table,
    Input,
    Popup,
    Dropdown,
    Label,
    Modal,
    Dimmer,
    Loader,
    Radio,
    Checkbox,
    Progress,
    Divider,
    Icon,
    Tab,
    Menu,
    List,
    Accordion,
    TableCell,
    Pagination,
    GridColumn,
    GridRow, Confirm, Header
} from "semantic-ui-react";

import dayjs from "dayjs";
import { Api } from "../../_helpers";
import useDebounced from '../../hooks/useDebouncedFunction'
import { HighLightWords } from '../../components/HighlightWords'
import {checkStockAvailability, STATUS_OPTIONS, STATUS_OPTIONS_PROCESS, STATUS_PROCESS_SUCCESS} from "../../constant"
import XLSX from "sheetjs-style";
import DraggableTableRow from "./DraggableTableRow"
import { PopUpCoefficient } from './component/PopUpCoefficient'
import { Suppliers } from './component/Suppliers'
import noticeError from "../../hooks/useError";
import {Pricing} from "./Pricing";
import {ExcelFromSuppliers} from "./component/ExcelFromSuppliers";
import { ModalHistoryItem } from './component/ModalHistoryItem'
import { ExportFromClient } from './component/ExportFromClient'

const StatusLabel = ({status}) => {
    const { text = 'Не определен', label: { color = 'brown' } = {} } = STATUS_OPTIONS.find(({ value }) => value === status) || {}
    return <Popup content={text} position='top center' trigger={<Label circular color={color} empty key={1}  />} />
}

const StatusLabelProcess = ({status}) => {
    const { text = 'Не определен', label: { color = 'brown' } = {} } = STATUS_OPTIONS_PROCESS.find(({ value }) => value === status) || {}
    return <Popup content={text} position='top center' trigger={<Label size={'big'} circular color={color} empty key={1}  />} />
}

const checkSlowSupplier = (speed = Boolean(false), request = [], slowSupplierList = []) => {
    if (!speed) return
    return slowSupplierList.map(uuid => request.some(({ uuid: suid }) => suid === uuid)).some(el => el === false)
}

const FieldClient = ({ value, handler }) => {
    const [open, setOpen] = useState(false)
    const [list, setList] = useState([])
    const { isFetching } = useQuery(['clients.list'], () => Api().get('/clients'), {
        enabled: open,
        onSuccess: ({data}) => setList(data.map(item => ({
            key: item.id,
            text: item.name,
            value: item.uuid
        })))
    })

    return <Dropdown
        options={list}
        loading={isFetching}
        selection
        search
        clearable
        fluid
        onOpen={() => setOpen(true)}
        // allowAdditions
        value={value}
        onChange={handler}
    />
}

export const Management = () => {
    const [open, setOpen] = useState(false)
    const [openHistory, setOpenHistory] = useState(false)

    const [active, setActive] = useState([])
    const [activePage, setActivePage] = useState(0)

    const [select, setSelect] = useState([])
    const [jobOrders, setJobOrders] = useState(false)

    const [openExport, setOpenExport] = useState(false)
    const [openExcel, setOpenExcel] = useState(false)
    const [exportData, setExportData] = useState([])
    const [exportExcel, setExportExcel] = useState([])

    const [filterDate, setFilterDate] = useState(null)
    const [filterDocs, setFilterDocs] = useState(null)
    const [filterClts, setFilterClts] = useState(null)
    const [filterArts, setFilterArts] = useState('')

    const [item, setItem] = useState({})
    const [requestList, setRequestList] = useState({
        total: 0, list: []
    })

    const inputFindOrder = useDebounced((val) => setFilterDocs(val), 800)
    const inputFindParts = useDebounced((val) => setFilterArts(val), 800)

    const { isFetching } = useQuery(['managements.list', [activePage, filterDocs, filterDate, filterClts, filterArts]],
        () => Api().get('/requests/documents', { params: {
            page: activePage,
            ...(filterDocs && { filterDocs }),
            ...(filterDate && { filterDate }),
            ...(filterClts && { filterClts }),
            ...(filterArts && { filterArts })
        }}), {
            onSuccess: ({ data }) => setRequestList(data)
        })

    const handlerItemLinkAnswer = (val) => {
        setItem(val)
        setOpen(true)
    }

    const handlerItemHistory = (val) => {
        setItem(val)
        setOpenHistory(true)
    }

    const exportFromExcel = (data) => {
        setOpenExport(true)
        setExportData(data)
    }

    const tableFromExcel = (data) => {
        setOpenExcel(true)
        setExportExcel(data)
    }

    const isChecked = (id) => select.some(el => el === id)

    const processColor = (percent) => {
        if (isNaN(percent) || +percent === 0) return 'red'
        return +percent === 100 ? 'green' : 'yellow'
    }

    const { mutateAsync: handlerUpdate } = useMutation(data => Api().put('/requests/update.view', data), {
        onSuccess: ({data}) => setRequestList(prev => ({ ...prev, list: prev.list.map(el => el.docid === data.docid ? ({...el, ...data }) : el)})),
        onError: (err) => noticeError({message: err.response.data.message})
    })

    const panels = requestList?.list?.map(({ctime, docid, client, total, label, speed, success, amount, view, status}, i) => {
        const percent = (100 * success / total).toFixed(2)
        return ({
            key: 'panel' + i,
            // onTitleClick: (i) => i.onClick(),
            onTitleClick: () => setActive(prev => prev.includes(i) ? prev.filter(el => el !== i) : [...prev, i]),
            title: {
                style: { padding: 0 },
                icon: false,
                // loaded: true,
                content: <List selection>
                    <List.Item>
                        <List.Content>
                            <Grid stackable divided stretched centered>
                                <GridRow verticalAlign='middle' style={{ justifyContent: 'space-between' }}>
                                    <GridColumn style={{ width: 150 }} textAlign="center">
                                        {dayjs(ctime).format('DD.MM.YY HH:mm')}
                                    </GridColumn>
                                    <GridColumn width={2}>
                                        <Label content={client} icon='user' />
                                    </GridColumn>
                                    <GridColumn width={2}>
                                        <Label style={{ textAlign: 'center' }}>{label || '-'}</Label>
                                    </GridColumn>
                                    <Grid.Column width={2} textAlign="right">
                                        <Label>{total || 0} шт.</Label>
                                    </Grid.Column>
                                    <GridColumn width={1} textAlign="right">
                                        <Label {...(amount > 0 && { color: 'red'})}>
                                            {amount || 0} €
                                        </Label>
                                    </GridColumn>
                                    <Grid.Column width={1}>
                                        <Popup content={speed ? 'Медленная проценка' : 'Быстрая проценка' } position='top center' trigger={
                                            <Label style={{ textAlign: 'center' }} {...(speed && { color: 'green'})}>
                                                {speed ? 'slow' : 'fast' }
                                            </Label>
                                        } />
                                    </Grid.Column>
                                    <Grid.Column textAlign={'center'} style={{ alignItems: 'center' }}>
                                        <Popup content={view ? 'Показ ответа клиенту' : 'Не показывать ответ' } position='top center' trigger={
                                            <Checkbox toggle checked={view} onClick={() => handlerUpdate({docid,  view: !view})}/>
                                        } />
                                    </Grid.Column>
                                    <Grid.Column textAlign={'center'}>
                                        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
                                            <StatusLabelProcess status={status} />
                                            <Dropdown
                                                icon={<Icon name='caret right' />}
                                                floating
                                                onChange={(_, { value }) => handlerUpdate({
                                                    docid, status: value, view: value === STATUS_PROCESS_SUCCESS
                                                })}
                                                value={status}
                                                options={STATUS_OPTIONS_PROCESS}
                                                trigger={<></>}
                                            />
                                        </div>
                                    </Grid.Column>
                                    <GridColumn width={3}>
                                        <Progress style={{ margin: 0 }} percent={percent} progress color={processColor(percent)} />
                                    </GridColumn>
                                    <GridColumn width={1} textAlign="right">
                                        <Icon name={"angle" + (active.includes(i) ? " up" : " down")} circular/>
                                    </GridColumn>
                                </GridRow>
                            </Grid>
                        </List.Content>
                    </List.Item>
                </List>
            },
            content: { content: active.includes(i) && <TableSupplier
                    docId={label || docid}
                    speed={speed}
                    orderUid={docid}
                    isFetching={isFetching}
                    setJobOrders={setJobOrders}
                    select={select}
                    setSelect={setSelect}
                    isChecked={isChecked}
                    // handlerOnBlur={handlerOnBlur}
                    // handlerStatus={handlerStatus}
                    handlerItemLinkAnswer={handlerItemLinkAnswer}
                    handlerItemHistory={handlerItemHistory}
                    exportFromExcel={exportFromExcel}
                    tableFromExcel={tableFromExcel}
                    open={jobOrders}
                /> }
        })
    })

    return <div>
         <Segment padded secondary>
             <Grid>
                 <GridRow>
                     <GridColumn width={2}>
                         <label>Дата</label>
                         <Input
                             type="date" fluid
                             value={filterDate} onChange={(_, { value }) => setFilterDate(value)}
                             placeholder='Дата' max={dayjs().format('YYYY-MM-DD')} min="2023-09-01" />
                     </GridColumn>
                     <GridColumn width={2}>
                         <label>Клиент</label>
                         <FieldClient value={filterClts} handler={(_, {value}) => setFilterClts(value)} />
                     </GridColumn>
                     <GridColumn width={2}>
                         <label>Номер запроса</label>
                         <Input
                             fluid
                             onChange={(_, { value }) => inputFindOrder(value)}
                             placeholder='' width={2} icon='search' />
                     </GridColumn>
                     <GridColumn width={3}>
                         <label>Артикул</label>
                         <Input
                             fluid
                             onChange={(_, { value }) => inputFindParts(value)}
                             placeholder=''
                             icon={filterArts?.length ? { name: 'close', link: true, onClick: () => inputFindParts('') } : false}
                         />
                     </GridColumn>
                     <GridColumn width={7}>
                         <label>&nbsp;</label>
                         <Grid columns={2} divided>
                             <GridRow>
                                 <GridColumn>
                                     <Pricing />
                                 </GridColumn>
                                 <GridColumn>
                                     <Suppliers />
                                 </GridColumn>
                             </GridRow>
                         </Grid>
                     </GridColumn>
                 </GridRow>
             </Grid>
         </Segment>
        <Segment loading={isFetching} style={{ minHeight: '10vh', padding: '10px 0'  }}>
            <Accordion fluid panels={panels} exclusive={false} activeIndex={active} />
        </Segment>
        {requestList.total > 1 && <Segment textAlign="center">
            <Pagination
                activePage={activePage + 1}
                totalPages={requestList.total} siblingRange={10} onPageChange={(_, { activePage }) => setActivePage(activePage - 1)}
            />
        </Segment>}
        <Divider hidden/>
        <ModalRequest2Answer open={open} close={() => setOpen(false)} item={item} />
        <ModalHistoryItem open={openHistory} close={() => setOpenHistory(false)} item={item} />
        <ModalAnswer open={jobOrders} close={() => {setJobOrders(false)}} item={select} />
        <ExportFromClient data={exportData} open={openExport} close={() => setOpenExport(false)} />
        <ExportTableExcel data={exportExcel} open={openExcel} close={() => setOpenExcel(false)} />
    </div>
}

const TableSupplier = ({
    docId, orderUid, setJobOrders, setSelect, select, speed, isChecked,
    handlerItemLinkAnswer, handlerItemHistory, exportFromExcel, tableFromExcel, open }) => {

    const [confirmQuant, setConfirmQuant] = useState(false)
    const [confirmPrice, setConfirmPrice] = useState(false)
    const [reqList, setReqList] = useState([])
    const [cacheQuantity, setCacheQuantity] = useState({})
    const [cachePrice, setCachePrice] = useState({})

    const { isFetching } = useQuery(['request.info', [orderUid, open]],
        () => Api().get('/requests/info', { params: { docid: orderUid }}), {
            enabled: !open || !orderUid,
            onSuccess: ({ data }) => setReqList(data.map(el => ({ ...el,
                inputQuant: el.quant2 || el.quant,
                inputPrice: el.price2
            })))
    })

    const { mutateAsync: update } = useMutation(data => Api().put('/requests/update', data), {
        onSuccess: () => console.log('Yes'),
        onError: (err) => noticeError({message: err.response.data.message})
    })

    const handlerChecked = (_, { checked, value }) => setSelect(prev => checked ? [...prev, value] : prev.filter(el => el !== value))
    const handlerCheckedAll = ({ checked }) => {
        const guids = reqList?.map(({ guid }) => guid)
        setSelect(prev => checked ? [...prev, ...guids] : prev.filter(el => !guids.includes(el)))
    }

    const handlerQuant = (guid, quant) => {
        setReqList(prev => prev.map((item) => item.guid === guid ? ({...item, inputQuant: quant}) : item))
    }

    const handlerPrice = (guid, price) => {
        const value = Math.max(0, Math.min(10000, Number(price)))
        setReqList(prev => prev.map((item) => item.guid === guid ? ({...item, inputPrice: value}) : item))
    }

    const handlerStatus = (guid, status) => {
        update({ guid, status }).then(() => {
            setReqList(prev => prev.map((item) => item.guid === guid ? ({...item, status}) : item))
        })
    }

    const handlerOnBlurQuant = (guid, newVal, prevVal) => {
        if (newVal === prevVal || !newVal) return
        setCacheQuantity({ guid, quant: newVal, prevVal: prevVal })
        setConfirmQuant(true)
    }

    const handlerOnBlurPrice = (guid, newVal, prevVal) => {
        if (newVal === prevVal) return
        setCachePrice({ guid, price2: newVal, prevVal: prevVal })
        setConfirmPrice(true)
    }

    const changeQuantityConfirm = ({ guid, quant }) => {
        update({ guid, quant }).then(() => {
            setReqList(prev => prev.map((item) => item.guid === guid ? ({...item, quant2: quant}) : item))
            setConfirmQuant(false)
        }).catch(err => console.error(err))
    }

    const changeQuantityCancel = ({ guid, prevVal }) => {
        setReqList(prev => prev.map((item) => item.guid === guid ? ({...item, inputQuant: prevVal}) : item))
        setConfirmQuant(false)
    }

    const changePriceConfirm = ({ guid, price2 }) => {
        update({ guid, price2 }).then(() => {
            setReqList(prev => prev.map((item) => item.guid === guid ? ({...item, price2}) : item))
            setConfirmPrice(false)
        }).catch(err => console.error(err))
    }

    const changePriceCancel = ({ guid, prevVal }) => {
        // setReqList(prev => prev.map((item) => item.guid === guid ? ({...item, inputQuant: prevVal}) : item))
        setConfirmPrice(false)
    }

    return <Segment loading={isFetching}>
        <Table celled compact unstackable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell style={{ minWidth: '40px' }} textAlign='center'>
                        <Checkbox toggle onClick={(_, data) => handlerCheckedAll(data)} />
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign='right' style={{ minWidth: '10px' }}>#</Table.HeaderCell>
                    <Table.HeaderCell style={{ width: '20px' }}/>
                    <Table.HeaderCell style={{ minWidth: '130px' }}>Производитель</Table.HeaderCell>
                    <Table.HeaderCell style={{ minWidth: '130px' }}>Артикул</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Наименование</Table.HeaderCell>
                    {/*<Table.HeaderCell textAlign='right' style={{ minWidth: '60px' }}>Вес</Table.HeaderCell>*/}
                    <Table.HeaderCell textAlign='right' style={{ minWidth: '75px' }}>Кол-во</Table.HeaderCell>
                    {/*<Table.HeaderCell textAlign='right' style={{ minWidth: '80px' }}>Наличие</Table.HeaderCell>*/}
                    {/*<Table.HeaderCell textAlign='right' style={{ width: '100px' }}>Цена1</Table.HeaderCell>*/}
                    <Table.HeaderCell textAlign='right' style={{ minWidth: '100px' }}>Цена2</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center' style={{ minWidth: '100px' }}>Наличие</Table.HeaderCell>
                    <Table.HeaderCell textAlign='right' style={{ minWidth: '290px', padding: 0 }} >
                        <Table basic='very' fixed>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell width={5} textAlign="center" style={{ padding: 5 }}>Поставщик</Table.Cell>
                                    <Table.Cell width={4} textAlign="right">Цена</Table.Cell>
                                    <Table.Cell width={4} textAlign="right">%</Table.Cell>
                                    <Table.Cell width={5} textAlign="center" style={{ padding: 5 }}>Дата</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center" style={{ minWidth: '60px' }}>Статус</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center" style={{ minWidth: '40px' }}>
                        <Popup content='Автопроценка' position='top center' trigger={
                            <Button size="mini" icon='heartbeat' color='red' onClick={() => setJobOrders(true)} disabled={Boolean(!select.length)}/>}
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: '50px' }}/>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {reqList?.map(row => <TableRows
                    key={row.guid}
                    {...row }
                    orderUid={orderUid} handlerChecked={handlerChecked} isChecked={isChecked}
                    handlerOnBlurQuant={handlerOnBlurQuant}
                    handlerOnBlurPrice={handlerOnBlurPrice}
                    handlerQuant={handlerQuant}
                    handlerPrice={handlerPrice}
                    handlerStatus={handlerStatus}
                    handlerItemLinkAnswer={handlerItemLinkAnswer}
                    handlerItemHistory={handlerItemHistory}
                />)}
            </Table.Body>
            <Table.Footer fullWidth>
                <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell colSpan='9'>
                        <ExcelFromSuppliers data={reqList} docId={docId} />
                        <Button color='pink' disabled={Boolean(!select.length)}
                                onClick={() => exportFromExcel({
                                    docId, speed, list: reqList.filter(({guid}) => select.includes(guid))
                                })}
                        >
                            <Icon name='file excel' /> Экспорт в Excel для клиентов
                        </Button>

                        <Button color='black' disabled={Boolean(!select.length)}
                                onClick={() => tableFromExcel({
                                    docId, speed, list: reqList.filter(({guid}) => select.includes(guid))
                                })} floated="right">
                            <Icon name='table' /> Table >> Excel
                        </Button>
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan='4' />
                </Table.Row>
            </Table.Footer>
        </Table>
        <Confirm
            open={confirmQuant}
            header='Изменить количество'
            content={<Segment padded>Подтвердите изменение количества</Segment>}
            onCancel={() => changeQuantityCancel(cacheQuantity)}
            onConfirm={() => changeQuantityConfirm(cacheQuantity)}
            cancelButton='Отменить'
            confirmButton="Да"
        />
        <Confirm
            open={confirmPrice}
            header='Изменить цену'
            content={<Segment padded>Подтвердите изменение цены</Segment>}
            onCancel={() => changePriceCancel(cachePrice)}
            onConfirm={() => changePriceConfirm(cachePrice)}
            cancelButton='Отменить'
            confirmButton="Да"
        />
    </Segment>
}

const TableRows = (rest) => {
    const [open, setOpen] = useState(false)

    const {
        rn, pid, guid, article, brand, description, quant, quant2 = null, status = 1, request = [],
        price1, price2, weight, bracode, bracing, stock, supplier, code, color, priority,
        inputQuant, inputPrice, orderUid, handlerChecked, handlerQuant, handlerPrice,
        isChecked, handlerItemLinkAnswer, handlerItemHistory,
        handlerOnBlurQuant, handlerOnBlurPrice,
        handlerStatus, stat, suid
    } = rest || {}

    const [intStatus, isInStock] = checkStockAvailability(stock, +price2)

    useEffect(() => setOpen(request?.length > 0), [request])

    const debouncedQuant = useDebouncedCallback((guid, value) => {
        handlerQuant(guid, value)
    }, 300)

    const debouncedPrice = useDebouncedCallback((guid, value) => {
        handlerPrice(guid, value)
    }, 300)

    return <React.Fragment key={guid}>
        <Table.Row verticalAlign="top" negative={!bracing}>
            <Table.Cell textAlign='center'>
                <div style={{ paddingTop: 8 }}>
                    <Checkbox toggle
                              value={guid}
                              onClick={handlerChecked}
                              checked={isChecked(guid)}
                    />
                </div>
            </Table.Cell>
            <Table.Cell textAlign="right">
                <div style={{ paddingTop: 8 }}>
                    {`${rn}.0`}
                </div>
            </Table.Cell>
            <Table.Cell>
                <div style={{ paddingTop: 8 }}>
                    <Popup content='Запрошенный номер'
                           position='top center' trigger={<Icon name='circle' color="blue" />}
                    />
                    <Popup content={`${pid ? 'Есть' : 'Нет'} в базе`}
                           position='top center' trigger={<Icon name='circle' color={pid ? 'green' : 'red'} />}
                    />
                </div>
            </Table.Cell>
            <Table.Cell>
                <div style={{ paddingTop: 8 }}>
                    {brand}
                </div>
            </Table.Cell>
            <Table.Cell>
                <div style={{ paddingTop: 8 }}>
                    {article}
                </div>
            </Table.Cell>
            <Table.Cell>
                <div style={{ paddingTop: 8 }}>
                    {description}
                </div>
            </Table.Cell>
            {/*<Table.Cell textAlign='right'>{weight}</Table.Cell>*/}
            <Table.Cell textAlign='right'>
                <div style={{ paddingTop: 8 }}>
                    {quant}
                </div>
            </Table.Cell>
{/*            <Table.Cell textAlign='right'>
                <Input
                    fluid
                    min="1" max="99"
                    type="number"
                    className="inputRight"
                    error={!inputQuant}
                    defaultValue={quant2 || quant}
                    // value={inputQuant}
                    onChange={({target: { value }}) => debouncedQuant(guid, value)}
                    onWheelCapture={e => { e.target.blur() }}
                    onBlur={() => handlerOnBlurQuant(guid, inputQuant, quant2 || quant)}
                >
                </Input>
            </Table.Cell>*/}
            {/*<Table.Cell textAlign='right'>{price1}</Table.Cell>*/}
            <Table.Cell>
                <div style={{ paddingTop: 5 }}>
                    <Input
                        fluid
                        min="0" max="99999"
                        step="0.01"
                        type="number"
                        className="inputRight"
                        // error={!inputPrice}
                        value={inputPrice}
                        // defaultValue={price2}
                        onChange={({target: { value }}) => debouncedPrice(guid, value)}
                        onWheelCapture={e => { e.target.blur() }}
                        onBlur={() => handlerOnBlurPrice(guid, inputPrice, price2)}
                    />
                </div>
            </Table.Cell>
            <Table.Cell textAlign="center">
                <div style={{ paddingTop: 8 }}>
                    {isInStock}
                </div>
            </Table.Cell>
            <Table.Cell style={{ padding: 0, borderBottom: '1px dashed #444' }}>
                <Table basic='very' fixed selectable compact>
                    <Table.Body>
                        {stat.filter(({ show, quant }) => show || quant).map((el, i) => {
                            return <Table.Row positive={suid && el.supplier === suid} key={i}>
                                <Table.Cell width={5} textAlign="center" style={{ padding: 5 }}>
                                    <Popup content={el.name || 'Нет предложений'} position='top center' trigger={<Label size={'mini'} color={el.color} content={el.pkey} /> } />
                                </Table.Cell>
                                {/*                            <Table.Cell width={4} textAlign="right">
                                {priority && <Popup content={'Приоритет'} position='top center' trigger={<Icon name='star' color='yellow' />} />}
                                {supplier && <Popup content={supplier} position='top center' trigger={<Label size={'tiny'} color={color} content={code} />} />}
                            </Table.Cell>*/}
                                <Table.Cell width={4} textAlign="right" >{el.price || '-'}</Table.Cell>
                                <Table.Cell width={4} textAlign="right">{el.rate || '-'}</Table.Cell>
                                <Table.Cell width={5} textAlign="center" style={{ padding: 5 }}>
                                    {el.ctime ? <Popup content={el.stock || null } position='top center' trigger={
                                        <div>{dayjs(el.ctime).format('DD.MM.YYYY')}</div>
                                    } /> : '-' }
                                </Table.Cell>
                            </Table.Row>
                        })}
                    </Table.Body>
                </Table>
            </Table.Cell>
            <Table.Cell textAlign="center">
                <div style={{ paddingTop: 10 }}>
                    <StatusLabel status={status} />
                    <Dropdown
                        className='icon'
                        floating
                        onChange={(_, { value }) => handlerStatus(guid, value)}
                        value={status}
                        options={STATUS_OPTIONS}
                        trigger={<></>}
                    />
                </div>
            </Table.Cell>
            <Table.Cell textAlign="center">
                <div style={{ paddingTop: 5 }}>
                    <Popup content='Связи' position='top center'
                           trigger={<Button size="mini" icon='random' {...(bracing && {color: 'orange'})} onClick={() => handlerItemLinkAnswer({
                               nss: [article, bracode].join('.'),
                               guid
                           })} />}
                    />
                </div>
                <div style={{ paddingTop: 5 }}>
                    <Popup content='История' position='top center'
                           trigger={<Button size="mini" icon='history' {...(bracing && {color: 'blue'})} onClick={() => handlerItemHistory({
                               nss: [article, bracode].join('.'),
                               guid
                           })} />}
                    />
                </div>
            </Table.Cell>
            <Table.Cell>
                {request && <Icon name={"angle " + (open ? "up" : "down")} circular onClick={() => setOpen(prev => !prev)} />}
            </Table.Cell>
        </Table.Row>
        {open && request?.map(({ id, pid, brand, article, description, weight, stock, cdate,
                                   priority, supplier, color, code, status, bracing, bracode, guid, price1, price2
                               }, i) => <Table.Row key={guid} active>
            <TableCell />
            <TableCell textAlign="right">{`${rn}.${i + 1}`}</TableCell>
            <Table.Cell>
                <Popup content={'Аналог'} position='top center' trigger={<Icon name='circle' color="orange" />} />
                <Popup content={`${pid ? 'Есть' : 'Нет'} в базе`}
                       position='top center' trigger={<Icon name='circle' color={pid ? 'green' : 'red'} />}
                />
            </Table.Cell>
            <Table.Cell>{brand}</Table.Cell>
            <Table.Cell>{article}</Table.Cell>
            <Table.Cell>{description}</Table.Cell>
            {/*<Table.Cell textAlign='right'>{weight}</Table.Cell>*/}
            <Table.Cell textAlign='right'>{quant}</Table.Cell>
            <Table.Cell>
                <Input
                    fluid
                    min="1" max="99999"
                    step="0.01"
                    type="number"
                    className="inputRight"
                    // error={!inputPrice}
                    value={price2}
                    disabled
                    // defaultValue={price2}
                    // onChange={({target: { value }}) => debouncedPrice(guid, value)}
                    onWheelCapture={e => { e.target.blur() }}
                    // onBlur={() => handlerOnBlurPrice(guid, inputPrice, price2)}
                />
            </Table.Cell>
            <Table.Cell textAlign="center">{stock}</Table.Cell>
            <Table.Cell textAlign="right" style={{ padding: 0 }}>
                <Table basic='very' fixed selectable compact>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell width={5} textAlign="center" style={{ padding: 5 }}>
                                <Popup content={supplier || 'Нет предложений'} position='top center' trigger={<Label size={'mini'} color={color} content={code} /> } />
                            </Table.Cell>
                            {/*                            <Table.Cell width={4} textAlign="right">
                                {priority && <Popup content={'Приоритет'} position='top center' trigger={<Icon name='star' color='yellow' />} />}
                                {supplier && <Popup content={supplier} position='top center' trigger={<Label size={'tiny'} color={color} content={code} />} />}
                            </Table.Cell>*/}
                            <Table.Cell width={4} textAlign="right" >{price1 || '-'}</Table.Cell>
                            <Table.Cell width={4} textAlign="right">-</Table.Cell>
                            <Table.Cell width={5} textAlign="center" style={{ padding: 5 }}>
                                {cdate ? <Popup content={stock || null } position='top center' trigger={
                                    <div>{dayjs(cdate).format('DD.MM.YYYY')}</div>
                                } /> : '-' }
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
{/*                {priority && <Popup content={'Приоритет'} position='top center' trigger={<Icon name='star' color='yellow' />} />}
                {supplier && <Popup content={supplier} position='top center' trigger={<Label color={color} content={code} />} />}*/}
            </Table.Cell>
            <Table.Cell textAlign="center">
                <StatusLabel status={status} />
                <Dropdown
                    className='icon'
                    floating
                    onChange={(_, { value }) => handlerStatus(orderUid, guid, value)}
                    value={status}
                    options={STATUS_OPTIONS}
                    trigger={<></>}
                />
            </Table.Cell>
            <Table.Cell textAlign="center">
                <Popup content='Связи' position='top center'
                       trigger={<Button size="mini" icon='random' {...(bracing && {color: 'orange'})} onClick={() => handlerItemLinkAnswer({
                           nss: [article, bracode].join('.'),
                           guid
                       })} />}
                />
            </Table.Cell>
            <TableCell />
        </Table.Row>)}
    </React.Fragment>
}

const InputQuantityField = ({ blur, guid, prevVal }) => {
    const [value, setValue] = useState(null)

    const debounced = useDebouncedCallback((value) => { setValue(value) }, 1000)

    useEffect(() => setValue(prevVal), [prevVal])
    return <Input
        type="number"
        className="inputRight"
        error={!value}
        value={value}
        onChange={({target: { value }}) => setValue(value)}
        onWheelCapture={e => { e.target.blur() }}
        onBlur={() => blur(guid, value, prevVal)}
        >
        </Input>
}

const ModalRequest2Answer = ({ open, close, item: { nss, guid: rid }}) => {
    const [uid, setUid] = useState(null)
    const bracing = () => create({ uid, rid })
    const { mutate: create } = useMutation(data => Api().put('/requests/bracing', data), {
        onSuccess: () => close()
    })

    const { data: { data = [] } = {}, isFetching } = useQuery(['answerItem', [open]],
        () => Api().post('/requests/answer', { nss }),
        { enabled: open && nss !== null }
    )

    return <Modal
        onClose={close}
        dimmer="blurring"
        style={{ width: '80%' }}
        closeOnEscape
        closeOnDimmerClick={false}
        onOpen={() => console.log('Действие при открытии окна')}
        open={open}>
        <Modal.Header>Установка связи запроса с ответом</Modal.Header>
        <Modal.Content scrolling>
            <Segment padded>
                {isFetching ? <Segment style={{ height: 150 }}>
                    <Dimmer active inverted>
                        <Loader />
                    </Dimmer>
                </Segment> :
               <Table selectable size='small' celled striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Дата</Table.HeaderCell>
                            <Table.HeaderCell>Производитель</Table.HeaderCell>
                            <Table.HeaderCell>Артикул</Table.HeaderCell>
                            <Table.HeaderCell>Наименование</Table.HeaderCell>
                            <Table.HeaderCell textAlign="right">Кол-во</Table.HeaderCell>
                            <Table.HeaderCell textAlign="right">Цена</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Остаток</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Срок</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Вес</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Поставщик</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Связь</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {data.map(({ id, ctime, brand, article, description, quant, price, stock, validity, weight, guid, supplier }) =>
                        <Table.Row key={guid} positive={id === uid} onClick={() => setUid(id)}>
                            <Table.Cell>{dayjs(ctime).format('DD.MM.YYYY HH:mm')}</Table.Cell>
                            <Table.Cell>{brand}</Table.Cell>
                            <Table.Cell>{article}</Table.Cell>
                            <Table.Cell>{description}</Table.Cell>
                            <Table.Cell textAlign="right">{quant}</Table.Cell>
                            <Table.Cell textAlign="right">{price}</Table.Cell>
                            <Table.Cell textAlign="center">{stock}</Table.Cell>
                            <Table.Cell textAlign="center">{validity}</Table.Cell>
                            <Table.Cell textAlign="center">{weight}</Table.Cell>
                            <Table.Cell textAlign="center">{supplier}</Table.Cell>
                            <Table.Cell textAlign="center" style={{ width: '50px' }}>
                                <Radio
                                    name='radioGroup'
                                    value={guid}
                                    checked={id === uid}
                                    onChange={() => setUid(id)}
                                />
                            </Table.Cell>
                        </Table.Row>)}
                    </Table.Body>
                </Table>}
            </Segment>
        </Modal.Content>
        <Modal.Actions>
            <Button
                content="Установить связь"
                onClick={bracing}
                disabled={Boolean(!uid)}
                positive
            />
            <Button color='black' onClick={close}>
                Закрыть
            </Button>
        </Modal.Actions>
    </Modal>
}

const ModalAnswer = ({ open, close, item } ) => {
    const [tabs, setTabs] = useState(0)
    const [selectInStock, setSelectInStock] = useState([])
    const [selectOutStock, setSelectOutStock] = useState([])

    const [article, setArticle] = useState('')
    const [openItem, setOpenItems] = useState(false)

    const setItems = (nss) => {
        setArticle(nss)
        setOpenItems(true)
    }

    const { isFetching, data: { data = {
        calculation: [],
        slowSuppliers: []
    } } = {}, refetch } = useQuery(['stockList', [open]],
        () => Api().post('/requests/stock', { item }),
        { enabled: open }
    )

    const { mutate: update } = useMutation(data => Api().put('/requests/markup', data), {
        onSuccess: ({data}) => closed()
    })

    const formatPrice = (coefficient) => {
        if(!coefficient) {
            return { isStatic: false, price: 0 }
        }
        const { price = 0, clients,
            word = 0, static: stat = 0, brand = 0, weightUnit = 0, weightItem = 0, parts, markup
        } = coefficient || {}
        if (stat) return { isStatic: true, price: stat }

        const rwp = (weightUnit * weightItem / price)
        const heft = rwp > 0.1 ? rwp - 0.1 : 0
        return { isStatic: false, price: (price * (clients + word + brand + +(heft).toFixed(2) + parts + markup)).toFixed(2) }
    }

    const formatValidity = (val) => {
        if (val?.toLowerCase() === 'в течении 14 дней с момента предложения') {
            return '14 дней'
        }
        if (val?.toLowerCase() === 'в течении 14 дней с момента выставления счета') {
            return '14 дней'
        }
        return val
    }

    const handlerCheckedInStock = ({data, id}) => setSelectInStock(prev => data.checked ? [...prev, id] : prev.filter(el => el !== id))
    const handlerCheckedAllInStock = (_, data) => itemInStock.map(({ id }) => handlerCheckedInStock({data, id}))
    const isCheckedInStock = id => selectInStock.includes(id)

    const handlerCheckedOutStock = ({data, id}) => setSelectOutStock(prev => data.checked ? [...prev, id] : prev.filter(el => el !== id))
    const handlerCheckedAllOutStock = (_, data) => itemOutStock.map(({ id }) => handlerCheckedOutStock({data, id}))
    const isCheckedOutStock = id => selectOutStock.includes(id)

    const accept = () => {
        const stock = itemInStock.filter(({ id }) => selectInStock.includes(id))
        update(stock.map(({ guid, coefficient, request, twin, docid, braid, stat }) => {
            const items = request?.find(Boolean)
            const { price } = formatPrice(coefficient)
            return ({ ...items, guid, price, status: 3, bracing: items.id, price1: items.price, twin, docid, braid, stat })
        }))
    }

    const closed = () => {
        setSelectInStock([])
        setSelectOutStock([])
        close()
    }

    const itemInStock = data?.calculation?.filter( ({ availability }) => availability)
    const itemOutStock = data?.calculation?.filter( ({ availability }) => !availability)

    const slowSupplierList = data?.slowSuppliers?.map(({ uuid }) => uuid)

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'

    const exportToExcel = async (excelData, fileName = 'request_supplier') => {
        const ws = XLSX.utils.json_to_sheet(excelData.map(({ brand, article, quant }) => ({
            'Производитель': brand,
            'Артикул': article,
            'Количество': quant
        })))
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: "array" })
        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, fileName + fileExtension)
    }

    const panes = [
        { menuItem: <Menu.Item key={0}>В наличии<Label color='green'>{itemInStock.length}</Label></Menu.Item>,
            render: () => <Tab.Pane loading={isFetching}>
                <Segment padded>
                    <Table celled compact size={'small'} selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{ width: '50px' }}>
                                <Checkbox toggle tabIndex={0} onClick={handlerCheckedAllInStock} name="stock-in" value="in" id="in" />
                            </Table.HeaderCell>
                            <Table.HeaderCell style={{ minWidth: '30px' }} textAlign="right">№</Table.HeaderCell>
                            <Table.HeaderCell style={{ minWidth: '110px' }}>Производитель</Table.HeaderCell>
                            <Table.HeaderCell style={{ minWidth: '110px' }}>Артикул</Table.HeaderCell>
                            <Table.HeaderCell>Описание</Table.HeaderCell>
                            <Table.HeaderCell textAlign="right" style={{ width: '65px', minWidth: '65px' }}>Кол-во</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '75px', minWidth: '75px' }} textAlign="right">Цена</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '75px', minWidth: '75px' }} textAlign="right">Цена X</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '75px', minWidth: '75px' }} textAlign="right">%</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '50px' }}/>
                            <Table.HeaderCell style={{ minWidth: '65px' }} textAlign="right">Поставщик</Table.HeaderCell>
                            <Table.HeaderCell style={{ minWidth: '100px' }} textAlign="right">Остаток</Table.HeaderCell>
                            <Table.HeaderCell style={{ minWidth: '100px' }} textAlign="center">Срок</Table.HeaderCell>
                            <Table.HeaderCell style={{ minWidth: '100px' }} textAlign="center">Клиент</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {itemInStock.map(({ numb, id, brand, article, description, quant, client, request = [], coefficient = {}, nss, speed, twin }, i) => {
                            const items = request?.find(Boolean)
                            const { description: description2, price, stock, validity, supplier, color, code } = items || {}
                            const { isStatic, price: price2 } = formatPrice(coefficient)
                            const isErrorSlow = checkSlowSupplier(speed, request, slowSupplierList)

                            return <Table.Row key={i} negative={!item} positive={Boolean(twin)} {...(isErrorSlow && { style: { background: 'red' } })} >
                                <Table.Cell>
                                    <Checkbox toggle
                                              checked={isCheckedInStock(id)}
                                              onClick={(_, data) => handlerCheckedInStock({ data, id })}
                                    />
                                </Table.Cell>
                                <Table.Cell textAlign="right">
                                    {numb}
                                </Table.Cell>
                                <Table.Cell>{brand}</Table.Cell>
                                <Table.Cell>
                                    <a href="#" onClick={() => setItems(nss)}>{article}</a>
                                </Table.Cell>
                                <Table.Cell>
                                    <HighLightWords text={description || description2} searchWords={data.keywords.map(({value}) => value)} />
                                </Table.Cell>
                                <Table.Cell textAlign="right">{quant}</Table.Cell>
                                <Table.Cell textAlign="right">{price}</Table.Cell>
                                <Table.Cell textAlign="right" positive={Boolean(price)} negative={Boolean(!price)}>
                                    {price2}
                                </Table.Cell>
                                <Table.Cell textAlign="right">
                                    {((price2 * 100) / price - 100).toFixed(0)}%
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    <PopUpCoefficient coefficient={coefficient} isStatic={isStatic} />
                                </Table.Cell>
                                <Table.Cell textAlign="right">
                                    {isErrorSlow && <Popup content={'Нет всех цен поставщиков'} inverted position='top center' trigger={<Icon name='exclamation circle' size={'big'} color='red' />} />}
                                    {supplier && <Popup content={supplier} position='top center' trigger={<Label  as='a' onClick={() => setOpenItems(true)} color={color} content={code} />} />}
                                </Table.Cell>
                                <Table.Cell textAlign="right">{stock}</Table.Cell>
                                <Table.Cell textAlign="center">{formatValidity(validity)}</Table.Cell>
                                <Table.Cell textAlign="center">
                                    <Label >{client}</Label>
                                </Table.Cell>
                            </Table.Row>
                        })}
                    </Table.Body>
                </Table>
                </Segment>
        </Tab.Pane> },
        { menuItem: <Menu.Item key={1}>Не в наличии<Label color="red">{itemOutStock.length}</Label></Menu.Item>,
            render: () => <Tab.Pane style={{ border: 0 }}>
                <Table celled striped selectable>
                    <Table.Header>
                        <Table.Row textAlign={'center'}>
                            <Table.HeaderCell>
                                <Checkbox tabIndex={1} onClick={handlerCheckedAllOutStock} name="stock-out" toggle value="out" id="out" />
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="left">Производитель</Table.HeaderCell>
                            <Table.HeaderCell textAlign="left">Артикул</Table.HeaderCell>
                            <Table.HeaderCell>Описание</Table.HeaderCell>
                            <Table.HeaderCell textAlign="right">Кол-во</Table.HeaderCell>
                            <Table.HeaderCell textAlign="right">Клиент</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {itemOutStock.map(({ id, brand, article, quant, client }) => {
                            return <Table.Row key={id} negative={!item}>
                                <Table.Cell textAlign={'center'}>
                                    <Checkbox toggle
                                              checked={isCheckedOutStock(id)}
                                              onClick={(_, data) => handlerCheckedOutStock({ data, id })}
                                    />
                                </Table.Cell>
                                <Table.Cell>{brand}</Table.Cell>
                                <Table.Cell>{article}</Table.Cell>
                                <Table.Cell>
                                    -
                                </Table.Cell>
                                <Table.Cell textAlign="right">{quant}</Table.Cell>
                                <Table.Cell textAlign="right">{client}</Table.Cell>
                            </Table.Row>
                        })}
                    </Table.Body>
                </Table>
            </Tab.Pane> }
    ]

    return <Modal
        onClose={close}
        style={{ width: '90%' }}
        closeOnEscape
        closeOnDimmerClick={false}
        onOpen={() => console.log('Действие при открытии окна')}
        open={open}>
        <Modal.Header>Обработка заказа</Modal.Header>
        <Modal.Content scrolling style={{ minHeight: '60vh' }}>
            {itemInStock.some(({ speed }) => speed) && <Segment color={'red'} inverted padded>Среди выбранных товаров, есть товары из медленной проценки, обратите внимание.</Segment>}
            <Segment padded>
                <Tab panes={panes} activeIndex={tabs} onTabChange={(_, { activeIndex }) => setTabs(+activeIndex) }  />
            </Segment>
            <ModalItemSpecification open={openItem} close={() => setOpenItems(false)} item={article} update={refetch} />
        </Modal.Content>
        <Modal.Actions>
            {!tabs ? <Button color='red' onClick={accept} disabled={Boolean(!selectInStock.length)}>
                Применить цены
            </Button> : <Button color='blue' onClick={() => exportToExcel(itemOutStock.filter(({ id }) => selectOutStock.includes(id)))} disabled={Boolean(!selectOutStock.length)}>
                Выгрузить
            </Button>}

            <Button color='black' onClick={closed}>
                Закрыть
            </Button>
        </Modal.Actions>
    </Modal>
}

const ModalItemSpecification = ({ open, close, item, update }) => {
    const [list, setList] = useState([])
    const { isFetching } = useQuery(['price.prior', [open]],
        () => Api().post('/requests/priority', { item }), {
            enabled: open, onSuccess: ({data}) => setList(data)
        }
    )

    const { mutateAsync: updateAsync } = useMutation(data => Api().put('/requests/sorted', data), {
        onSuccess: () => update()
    })

    const swap = async (a, b) => {
        let items = list;
        items[a] = items.splice(b, 1, items[a])[0];

        await updateAsync({
            nss: item,
            data: items.map((el, i) => ({ ...el, sort: i + 1}))
        })

        setList([...items])
    }

    return <Modal
        onClose={close}
        dimmer="blurring"
        closeOnEscape
        closeOnDimmerClick={false}
        onOpen={() => console.log('Действие при открытии окна')}
        open={open}>
        <Modal.Header>Сортировка приоритета поставщиков</Modal.Header>
        <Modal.Content scrolling>
            <Segment padded loading={isFetching}>
                <style>{`
                  .draggable {
                    cursor: move; /* fallback if grab cursor is unsupported */
                    cursor: grab;
                    cursor: -moz-grab;
                    cursor: -webkit-grab;
                  }
                `}</style>
                <Table celled compact padded>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Поставщик</Table.HeaderCell>
                            <Table.HeaderCell textAlign="right" width={1}>Наличие</Table.HeaderCell>
                            <Table.HeaderCell textAlign="right" width={1}>Приоритет</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {list.map(({ name, count, nss }, i) => (
                            <DraggableTableRow key={i} i={i} action={swap}>
                                <Table.Cell>{name}</Table.Cell>
                                <Table.Cell textAlign="right">{count}</Table.Cell>
                                <Table.Cell textAlign="center">
                                    {nss && <Icon link name='close' color={'red'} />}
                                </Table.Cell>
                            </DraggableTableRow>
                        ))}
                    </Table.Body>
                </Table>
            </Segment>
        </Modal.Content>
        <Modal.Actions>
            <Button color='black' onClick={close}>
                Закрыть
            </Button>
        </Modal.Actions>
    </Modal>
}


const ExportTableExcel = ({ open, close, data: { docId, speed, list } = {} }) => {
    const [openConfirm, setOpenConfirm] = useState(false)
    const workbook = new Excel.Workbook()
    const columns = [
        { header: '№', key: 'rowId', width: null },
        { header: 'Номер строки запроса предложения', key: 'requestId', width: 4 },
        { header: 'Производитель', key: 'brand', width: null },
        { header: 'Номер запчасти', key: 'article', width: null },
        { header: 'Наименование', key: 'description', width: null },
        { header: 'Количество', key: 'quant', width: null },
        { header: 'Единица измерения', key: 'unit', width: 10 },
        { header: 'Цена/ед. (Евро)', key: 'price2', width: null },
        { header: 'Наличие', key: 'stock', width: 11 },
        { header: 'Вес', key: 'weight', width: null },
        { header: 'Действительно до', key: 'validity', width: null },
        { header: 'ЦенаСклад', key: 'price1', width: null },
        { header: 'Поставщик', key: 'supplier', width: null },
        { header: 'ДатаПрайса', key: 'ptime', width: null },
        { header: 'Приоритет', key: 'priority', width: null }
    ]

    const formatStock = (quant = 0, stock) => {
        if (stock?.replace(/[0-9\s]/g, "")?.toLowerCase() === 'склад-.' || stock?.replace(/[0-9\s]/g, "")?.toLowerCase() === 'stock-.') {
            return `Склад - ${quant || 0}.0`
        }
        return stock;
    }

    const cells = ({ rowId, requestId, brand, article, description, quant, quant2, price2, stock, weight, price1, priority, code, supplier, pref, ptime }) => {
        const [intStatus, isInStock] = checkStockAvailability(stock, +price2)
        return ({
            rowId,
            requestId,
            brand,
            article: article.replace(/[^0-9a-zA-Z]/gi, ""),
            description,
            quant,
            unit: 'шт.',
            price2: Number.isFinite(+price2) ? +price2 : 0,
            stock: isInStock, // formatStock(quant, stock),
            weight,
            validity: stock?.toLowerCase() === 'нет в наличии' ? '' : 'В течении 14 дней с даты предложения',
            price1,
            supplier: pref,
            ptime: ptime ? dayjs(ptime).format('DD.MM.YY') : '',
            priority: ''
        })
    }

    const exportToExcel = async (excelData, fileName) => {

        // creating one worksheet in workbook
        const worksheet = workbook.addWorksheet(docId)

        // add worksheet columns
        // each columns contains header and its mapping key from data
        // worksheet.columns = columns
        worksheet.columns = [...columns, ...excelData.find(Boolean)?.stat.map(({ pkey, pref }) => ({ header: pref, key: pkey }))]

        // updated the font for first row.
        worksheet.getRow(1).font = { bold: true };

        worksheet.columns.forEach(column => {
            column.width = column.width || column.header.length + 2
            // column.alignment = { horizontal: 'center' }
        })

        // loop through data and add each one to worksheet
        let rowIndex = 0

        excelData.map((el, i) => {
            const { request, ...attr } = el
            worksheet.addRow({
                ...cells({ ...attr, requestId: (i + 1).toFixed(1), rowId: (++rowIndex) }),
                ...Object.fromEntries(attr.stat.map(({ pkey, price, stock }) => [pkey, stock === 'нет в наличии' ? 'Н.Д': price]))
            })
            if (request) {
                request.map((el, index) => worksheet.addRow({
                    ...cells({...el, requestId: `${(i + 1)}.${(index + 1)}`, rowId: (++rowIndex), quant: attr.quant }),
                    ...Object.fromEntries(attr.stat.map(({ pkey }) => [pkey, pkey === 'TVH' ? el.price1 : '']))
                }))
            }
        })

        worksheet.eachRow({ includeEmpty: false }, row => {
            row._cells.map(singleCell => worksheet.getCell(singleCell._address).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                }
            )
        })

        const buffer = await workbook.xlsx.writeBuffer()
        workbook.removeWorksheet(docId)
        FileSaver.saveAs(new Blob([buffer]), `XXXXXX-${fileName}.xlsx`)
    }

    const handlerConfirmButton = () => {
        exportToExcel(list, 'request_' + docId).then(_ => {
            setOpenConfirm(false)
        })
    }

    const headerSaveAsFile = (speed) => {

        console.log({ speed })

        if (speed) {
            setOpenConfirm(true)
            return
        }
        exportToExcel(list, 'request_' + docId).then(_ => {})
    }

    return <>
        <Modal
            size='large'
            dimmer='blurring'
            closeOnDimmerClick={false}
            open={open}
            onClose={close}>
            <Modal.Header>Export Excel</Modal.Header>
            <Modal.Content>
                <Segment padded textAlign="center">
                    <Button content="Сохранить в Excel" onClick={() => headerSaveAsFile(speed)} />
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                {/*<Button positive disabled>Да</Button>*/}
                <Button negative onClick={close}>Закрыть</Button>
            </Modal.Actions>
        </Modal>
        <Confirm
            open={openConfirm}
            content={<Segment secondary padded inverted color="red">
                Этот заказ отмечан как "детальный", необходимо подтвердить цену и наличие у всех поставщиков.
            </Segment>}
            header={<Header as="h5">Внимание! Ручная обработка</Header>}
            cancelButton={'Отмена'}
            confirmButton={'Продолжить'}
            onCancel={() => setOpenConfirm(false)}
            onConfirm={() => handlerConfirmButton()}
        />
    </>
}
