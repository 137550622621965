import React, {useEffect, useMemo, useState} from "react";
import {useMutation, useQuery} from "react-query";
import {Api, arraysEqual} from "../../../_helpers";
import noticeError from "../../../hooks/useError";
import {
    Button, Confirm,
    Divider, Dropdown,
    Form, FormCheckbox, FormField, FormGroup,
    Grid,
    GridColumn,
    GridRow,
    Header, Icon,
    Image, Input,
    Modal, ModalActions,
    ModalContent,
    ModalHeader,
    Segment, Tab, Table,
    TabPane
} from "semantic-ui-react";
import {Brands} from "../../../components/Dropdown/Brands";

export const PartsInfo = ({ open, close, parts, updateState }) => {
    const { nss = '' } = parts || {}
    const [data, setData] = useState({})
    const [init, setInit] = useState({})

    const { isFetching } = useQuery(['catalog.parts', [nss]], () => Api().get('/catalog/parts', { params: { nss } }), {
        enabled: open,
        onSuccess: ({data}) => {
            setData(data)
            setInit(data)
        }
    })

    const { mutate: update, isFetching: isFetchUpdate } = useMutation(data => Api().post('/catalog/parts/update', data), {
        onSuccess: () => {
            setInit(data)
            updateState(data)
            close()
        },
        onError: (err) => noticeError({ message: err.response.data.message })
    })

    const { mutate: removeAnalog } = useMutation(data => Api().post('/catalog/parts/analog/remove', data), {
        onSuccess: ({data}) => {
            const analogList = analog.filter((el) => el.id !== data.id)
            handlerForm({ analog: analogList })
            setInit(prev => ({...prev, analog: analogList }))
        },
        onError: (err) => noticeError({ message: err.response.data.message })
    })

    const { name, braid, article, description, desid, analog, weight, confirm } = data || {}

    const handlerForm = (val) => setData(prev => ({...prev, ...val}))

    const panes = [
        { menuItem: 'Аналоги', render: () => <TabPane><CrossList list={analog} remove={removeAnalog}/></TabPane> },/*
        { menuItem: 'Tab 2', render: () => <TabPane>Tab 2 Content</TabPane> },
        { menuItem: 'Tab 3', render: () => <TabPane>Tab 3 Content</TabPane> },*/
    ]

    const handlerUpdate = () => update(data)

    const isDisabledButton = useMemo(() => arraysEqual([init], [data]), [init, data])

    return <Modal open={open} onClose={close}
                  closeOnDimmerClick={false}>
        <ModalHeader><Header as="h5">Редактирование { name }</Header></ModalHeader>
        <ModalContent >
            <Segment padded loading={isFetching || isFetchUpdate}>
                <Grid columns='equal' divided>
                    <GridRow>
                        <GridColumn width={4}>
                            <Image src='/img/image.png' size={'medium'} />
                        </GridColumn>
                        <GridColumn width={12}>
                            <Segment>
                                <Form>
                                    <FormGroup>
                                        <Form.Field width={9}>
                                            <label>Производитель</label>
                                            <Brands value={braid}
                                                    handler={(data) => console.log(data)}
                                                    init={[{ key: braid, text: name, value: braid }]}
                                                    disabled
                                            />
                                        </Form.Field>
                                        <FormField width={7}>
                                            <label>Артикул</label>
                                            <Input
                                                value={article}
                                                disabled
                                                placeholder='923572.0012'
                                            />
                                        </FormField>
                                    </FormGroup>
                                    <FormGroup>
                                        <FormField width={9} error={!desid}>
                                            <label>Наименование</label>
                                            <Description
                                                value={desid}
                                                description={description}
                                                handler={({value, text} = {}) => handlerForm({ description: text, desid: value })}
                                            />
                                        </FormField>
                                        <FormField width={7} error={!weight}>
                                            <label>Вес</label>
                                            <Input
                                                fluid
                                                defaultValue={0}
                                                value={weight}
                                                min="0" max="1000" step="0.01"
                                                onChange={(e) => handlerForm({
                                                    weight: e.target.value !== '' ? Math.max(0, Math.min(1000, Number(e.target.value))) : null
                                                })}
                                                type="number"
                                            />
                                        </FormField>
                                    </FormGroup>
                                    <FormCheckbox
                                        style={{lineHeight: '18px'}}
                                        checked={confirm}
                                        onChange={(_, { checked }) => handlerForm({ confirm: checked })}
                                        toggle label='Проверено менедежером' />
                                </Form>
                            </Segment>
                        </GridColumn>
                    </GridRow>
                </Grid>
                <Divider />
                <Tab panes={panes} />
            </Segment>
        </ModalContent>
        <ModalActions>
            <Button content="Обновить" onClick={handlerUpdate} positive disabled={isDisabledButton} />
            <Button color='black' content="Закрыть" onClick={close} />
        </ModalActions>
    </Modal>
}


const CrossList = ({ list, remove }) => {
    const [open, setOpen] = useState(false)
    const [item, setItem] = useState(null)

    const handler = (val) => {
        setItem(val)
        setOpen(true)
    }

    const handlerConfirm = () => {
        remove(item)
        setOpen(false)
    }

    return <Segment padded>
        <Table singleLine selectable size='small' compact>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={3}>Производитель</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Артикул</Table.HeaderCell>
                    <Table.HeaderCell>Наименование</Table.HeaderCell>
                    <Table.HeaderCell width={1} textAlign='right'>Вес</Table.HeaderCell>
                    <Table.HeaderCell width={1} />
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {list?.map(({id, brand, article, description, weight, pnss, parent }) =>
                    <Table.Row key={id}>
                        <Table.Cell>{brand}</Table.Cell>
                        <Table.Cell>{article}</Table.Cell>
                        <Table.Cell>{description}</Table.Cell>
                        <Table.Cell textAlign={'right'}>{weight}</Table.Cell>
                        <Table.Cell textAlign={'center'}>
                            <Button icon size={'tiny'} negative
                                    disabled={Boolean(parent)}
                                    onClick={() => handler({id, pnss})}>
                                <Icon name='close' />
                            </Button>
                        </Table.Cell>
                    </Table.Row>)}
            </Table.Body>
        </Table>
        <Confirm
            size="tiny"
            open={open}
            cancelButton="Отмена"
            confirmButton="Да"
            onCancel={() => setOpen(false)}
            onConfirm={handlerConfirm}
            content={<Segment padded>Удалить этот артикул из связей аналогов?</Segment>}
            header={<Header as="h5">Подтвердить удаление</Header>}
        />
    </Segment>
}

const Description = ({ value, handler, description }) => {
    const [open, setOpen] = useState(false)
    const [list, setList] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [search, setSearch] = useState('')
    const { isFetching } = useQuery(
        ['description.list', [search, open]], () => Api().get('/catalog/description', { params: { search: searchQuery }} ), {
            enabled: open && searchQuery?.length > 1 ,
            onSuccess: ({ data }) => setList(data.map(({id, name}) => ({ key: id, text: name, value: id})))
        })

    const handleSearchChange = (_, { searchQuery }) => {
        setSearchQuery(searchQuery)
        setSearch(searchQuery)
    }

    useEffect(() => setSearchQuery(description || ''), [description])

    return <Dropdown
        options={list}
        selectOnNavigation={false}
        loading={isFetching}
        selection
        search
        allowAdditions
        onAddItem={(_, val) => setList(prev => [{ key: -1, text: val.value, value: -1 }, ...prev])}
        fluid
        clearable
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onSearchChange={handleSearchChange}
        searchQuery={searchQuery}
        value={value}
        onChange={(_, { options, value }) => {
            const opt = options.find(el => el.value === value)
            if (!value) {
                handler({ key: null, text: value, value: null })
                return
            }
            handler(!opt ? { key: -1, text: value, value: -1 } : opt)
        }}
    />
}