import Excel from "exceljs";
import * as FileSaver from  'file-saver'
import {Button, Modal, Segment} from "semantic-ui-react";

export const OrderExportExcelFile = ({ open, close, data: { docId, list } = {} }) => {
    const workbook = new Excel.Workbook()
    const columns = [
        { header: '№', key: 'rowId', width: null },
        { header: 'Производитель', key: 'brand', width: null },
        { header: 'Номер запчасти', key: 'article', width: null },
        { header: 'Наименование', key: 'description', width: null },
        { header: 'Количество', key: 'quant', width: null }
    ]

    const cells = ({ rowId, brand, article, description, quant }) => ({
        rowId,
        brand,
        article,
        description,
        quant
    })

    const exportToExcel = async (excelData, fileName) => {

        // creating one worksheet in workbook
        const worksheet = workbook.addWorksheet(docId)

        // add worksheet columns
        // each columns contains header and its mapping key from data
        worksheet.columns = columns


        // updated the font for first row.
        worksheet.getRow(1).font = { bold: true };

        worksheet.columns.forEach(column => {
            column.width = column.width || column.header.length + 2
            // column.alignment = { horizontal: 'center' }
        })

        excelData.map((el, i) => {
            const { request, ...attr } = el
            worksheet.addRow({...cells({ ...attr, rowId: (i + 1) })})
        })

        worksheet.eachRow({ includeEmpty: false }, row => {
            row._cells.map(singleCell => worksheet.getCell(singleCell._address).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                }
            )
        })

        const buffer = await workbook.xlsx.writeBuffer()
        workbook.removeWorksheet(docId)
        FileSaver.saveAs(new Blob([buffer]), `${fileName}.xlsx`)
    }

    return <Modal
        size='large'
        // dimmer='blurring'
        closeOnDimmerClick={false}
        open={open}
        onClose={close}>
        <Modal.Header>Export order to Excel</Modal.Header>
        <Modal.Content>
            <Segment padded textAlign="center">
                <Button content="Сохранить в Excel" onClick={() => exportToExcel(list, 'order_' + docId)} />
            </Segment>
        </Modal.Content>
        <Modal.Actions>
            {/*<Button positive disabled>Да</Button>*/}
            <Button negative onClick={close}>Закрыть</Button>
        </Modal.Actions>
    </Modal>
}